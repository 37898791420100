import React from 'react'
import { useFormikContext } from 'formik'
import { styled, s } from '@vega/styled'
import { Prompt as OriginalPrompt } from 'features/broker/applications/components/typography'
import { Properties } from './Properties'
import { TextField, RadioButtonField, RadioButton } from '@vega/components'
import { TEST_IDS } from '@neo/constants'
import { NotesProvider } from 'features/broker/applications/components/NotesProvider'

const Prompt = styled(OriginalPrompt)(s('mt-6'))

function AssetsAndProperties({ applicantName, parentPath, applicantPathId }) {
  const { values } = useFormikContext()

  const applicantHasShares =
    values?.financials?.[applicantPathId]?.assets?.shares?.eligibility

  const applicantHasProperties =
    values?.financials?.[applicantPathId]?.assets?.property?.eligibility

  return (
    <div>
      <NotesProvider name={`${parentPath}.assets.cashSavings.amount`}>
        <Prompt>
          First, Cash in saving. How much cash have does {applicantName} have saved for
          their deposit? This information to determine on how much {applicantName} can
          borrow
        </Prompt>
        <TextField
          name={`${parentPath}.assets.cashSavings.amount`}
          aria-label="Cash"
          prefix="$"
          containerStyle={s('w-1/2')}
          testId={TEST_IDS.CASH_SAVINGS_INPUT}
        />
      </NotesProvider>

      <NotesProvider name={`${parentPath}.assets.shares.eligibility`}>
        <Prompt style={s('mb-4')}>Does {applicantName} own any shares (stocks)?</Prompt>
        <RadioButtonField
          name={`${parentPath}.assets.shares.eligibility`}
          aria-label="Shares value Eligibility"
          containerStyle={s('mb-4')}
          testId={TEST_IDS.HAS_STOCKS_RB}
        >
          <RadioButton value={true}>Yes</RadioButton>
          <RadioButton value={false}>No</RadioButton>
        </RadioButtonField>

        {applicantHasShares && (
          <NotesProvider name={`${parentPath}.assets.shares.amount`}>
            <Prompt>Please enter amount of their current value</Prompt>
            <TextField
              name={`${parentPath}.assets.shares.amount`}
              aria-label="Shares value"
              prefix="$"
              containerStyle={s('w-1/2 mb-4')}
              testId={TEST_IDS.STOCKS_AMOUNT_INPUT}
            />
          </NotesProvider>
        )}
      </NotesProvider>

      <NotesProvider name={`${parentPath}.assets.property.eligibility`}>
        <Prompt style={s('mt-0 mb-4')}>Does {applicantName} own any property?</Prompt>
        <RadioButtonField
          name={`${parentPath}.assets.property.eligibility`}
          aria-label="Is owned any properties"
          containerStyle={s('mb-4')}
          testId={TEST_IDS.HAS_PROPERTY_RB}
        >
          <RadioButton value={true}>Yes</RadioButton>
          <RadioButton value={false}>No</RadioButton>
        </RadioButtonField>

        {applicantHasProperties && (
          <Properties
            applicantName={applicantName}
            parentPath={parentPath}
            applicantPathId={applicantPathId}
          />
        )}
      </NotesProvider>
    </div>
  )
}

export { AssetsAndProperties }
