import React from 'react'
import { useFormikContext } from 'formik'
import { styled, s } from '@vega/styled'
import { TextField, RadioButtonField, RadioButton } from '@vega/components'
import { Prompt as OriginalPrompt } from 'features/broker/applications/components/typography'
import { TEST_IDS } from '@neo/constants'
import { NotesProvider } from 'features/broker/applications/components/NotesProvider'

const IncomeFieldsGroup = styled.div(s('mb-6'))
const Prompt = styled(OriginalPrompt)(s('mt-6 mb-4'))

// eslint-disable-next-line complexity
function Income({ parentPath, applicantPathId, applicantName }) {
  const { values } = useFormikContext()

  const applicantHasSuperannuation =
    values?.financials?.[applicantPathId].income?.superannuation?.eligibility

  const applicantHasDividends =
    values?.financials?.[applicantPathId].income?.dividends?.eligibility

  const applicantHasGovernmentPayment =
    values?.financials?.[applicantPathId].income?.governmentPayment?.eligibility

  const applicantHasCompanyProfit =
    values?.financials?.[applicantPathId].income?.companyProfitBeforeTax?.eligibility

  const applicantHasEarnedInterest =
    values?.financials?.[applicantPathId].income?.interestEarned?.eligibility

  const applicantHasChildSupport =
    values?.financials?.[applicantPathId].income?.childSupport?.eligibility

  return (
    <div>
      <IncomeFieldsGroup>
        <NotesProvider name={`${parentPath}.income.superannuation.eligibility`}>
          <Prompt>
            Does {applicantName} receive superannuation or any other retirement schemes?
          </Prompt>
          <RadioButtonField
            name={`${parentPath}.income.superannuation.eligibility`}
            aria-label="Superannuation Eligibility"
            containerStyle={s('mb-6')}
            testId={TEST_IDS.HAS_RETIREMENT_RB}
          >
            <RadioButton value={true}>Yes</RadioButton>
            <RadioButton value={false}>No</RadioButton>
          </RadioButtonField>
        </NotesProvider>

        {applicantHasSuperannuation && (
          <>
            <NotesProvider name={`${parentPath}.income.superannuation.amount`}>
              <Prompt style={s('m-0')}>Please enter amount</Prompt>
              <TextField
                name={`${parentPath}.income.superannuation.amount`}
                aria-label="superannuation amount"
                prefix="$"
                containerStyle={s('w-1/2 mb-6')}
                testId={TEST_IDS.RETIREMENT_AMOUNT_INPUT}
              />
            </NotesProvider>

            <NotesProvider name={`${parentPath}.income.superannuation.recurrence`}>
              <Prompt>
                How frequently does {applicantName} receive superannuation?
              </Prompt>
              <RadioButtonField
                name={`${parentPath}.income.superannuation.recurrence`}
                aria-label="Superannuation recurrence"
                containerStyle={s('w-full mb-6')}
                testId={TEST_IDS.RETIREMENT_FREQ_RB}
              >
                <RadioButton value="monthly">Monthly</RadioButton>
                <RadioButton value="quarterly">Quarterly</RadioButton>
                <RadioButton value="half_yearly">Semi-annually</RadioButton>
                <RadioButton value="annually">Annually</RadioButton>
              </RadioButtonField>
            </NotesProvider>
          </>
        )}
      </IncomeFieldsGroup>

      <IncomeFieldsGroup>
        <NotesProvider name={`${parentPath}.income.governmentPayment.eligibility`}>
          <Prompt style={s('mt-0')}>
            Does {applicantName} receive any government payments or government
            allowance?
          </Prompt>
          <RadioButtonField
            name={`${parentPath}.income.governmentPayment.eligibility`}
            aria-label="Government Payment Eligibility"
            containerStyle={s('mb-4')}
            testId={TEST_IDS.GOVERNMENT_PAY_RB}
          >
            <RadioButton value={true}>Yes</RadioButton>
            <RadioButton value={false}>No</RadioButton>
          </RadioButtonField>
        </NotesProvider>

        {applicantHasGovernmentPayment && (
          <>
            <NotesProvider name={`${parentPath}.income.governmentPayment.amount`}>
              <Prompt style={s('m-0')}>Please enter amount</Prompt>
              <TextField
                name={`${parentPath}.income.governmentPayment.amount`}
                aria-label="Government Payment amount"
                prefix="$"
                containerStyle={s('w-1/2 mb-6')}
                testId={TEST_IDS.GOVERNMENT_PAY_AMOUT_INPUT}
              />
            </NotesProvider>

            <NotesProvider name={`${parentPath}.income.governmentPayment.recurrence`}>
              <Prompt>
                How frequently does {applicantName} receive government payment?
              </Prompt>
              <RadioButtonField
                name={`${parentPath}.income.governmentPayment.recurrence`}
                aria-label="Government Payment recurrence"
                containerStyle={s('w-full mb-6')}
                testId={TEST_IDS.GOVERNMENT_PAY_FREQ_RB}
              >
                <RadioButton value="monthly">Monthly</RadioButton>
                <RadioButton value="quarterly">Quarterly</RadioButton>
                <RadioButton value="half_yearly">Semi-annually</RadioButton>
                <RadioButton value="annually">Annually</RadioButton>
              </RadioButtonField>
            </NotesProvider>
          </>
        )}
      </IncomeFieldsGroup>

      <IncomeFieldsGroup>
        <NotesProvider name={`${parentPath}.income.dividends.eligibility`}>
          <Prompt style={s('mt-0')}>Does {applicantName} receive any dividends?</Prompt>
          <RadioButtonField
            name={`${parentPath}.income.dividends.eligibility`}
            aria-label="Dividends Eligibility"
            containerStyle={s('mb-4')}
            testId={TEST_IDS.HAS_DIVIDENDS_RB}
          >
            <RadioButton value={true}>Yes</RadioButton>
            <RadioButton value={false}>No</RadioButton>
          </RadioButtonField>
        </NotesProvider>

        {applicantHasDividends && (
          <>
            <NotesProvider name={`${parentPath}.income.dividens.amount`}>
              <Prompt style={s('m-0')}>Please enter amount</Prompt>
              <TextField
                name={`${parentPath}.income.dividends.amount`}
                aria-label="Dividends amount"
                prefix="$"
                containerStyle={s('w-1/2 mb-6')}
                testId={TEST_IDS.DIVIDENDS_AMOUNT_INPUT}
              />
            </NotesProvider>

            <NotesProvider name={`${parentPath}.income.dividens.recurrence`}>
              <Prompt>How frequently does {applicantName} receive dividends?</Prompt>
              <RadioButtonField
                name={`${parentPath}.income.dividends.recurrence`}
                aria-label="Dividends recurrence"
                containerStyle={s('w-full mb-4')}
                testId={TEST_IDS.DIVIDENDS_FREQ_RB}
              >
                <RadioButton value="monthly">Monthly</RadioButton>
                <RadioButton value="quarterly">Quarterly</RadioButton>
                <RadioButton value="half_yearly">Semi-annually</RadioButton>
                <RadioButton value="annually">Annually</RadioButton>
              </RadioButtonField>
            </NotesProvider>
          </>
        )}
      </IncomeFieldsGroup>

      <IncomeFieldsGroup>
        <NotesProvider name={`${parentPath}.income.companyProfitBeforeTax.eligibility`}>
          <Prompt style={s('mt-0 mb-4')}>
            Does {applicantName} receive any company profit before tax?
          </Prompt>
          <RadioButtonField
            name={`${parentPath}.income.companyProfitBeforeTax.eligibility`}
            aria-label="Company Profit Before Tax Eligibility"
            containerStyle={s('mb-4')}
            testId={TEST_IDS.COMPANY_PROFIT_RB}
          >
            <RadioButton value={true}>Yes</RadioButton>
            <RadioButton value={false}>No</RadioButton>
          </RadioButtonField>
        </NotesProvider>

        {applicantHasCompanyProfit && (
          <>
            <NotesProvider name={`${parentPath}.income.companyProfitBeforeTax.amount`}>
              <Prompt style={s('m-0')}>Please enter amount</Prompt>
              <TextField
                name={`${parentPath}.income.companyProfitBeforeTax.amount`}
                aria-label="Company Profit Before Tax amount"
                prefix="$"
                containerStyle={s('w-1/2 mb-6')}
                testId={TEST_IDS.COMPANY_PROFIT_INPUT}
              />
            </NotesProvider>

            <NotesProvider
              name={`${parentPath}.income.companyProfitBeforeTax.recurrence`}
            >
              <Prompt>
                How frequently does {applicantName} receive company profit?
              </Prompt>
              <RadioButtonField
                aria-label="Company Profit Before Tax recurrence"
                containerStyle={s('w-full')}
                name={`${parentPath}.income.companyProfitBeforeTax.recurrence`}
                testId={TEST_IDS.COMPANY_PROFIT_FREQ_RB}
              >
                <RadioButton value="monthly">Monthly</RadioButton>
                <RadioButton value="quarterly">Quarterly</RadioButton>
                <RadioButton value="half_yearly">Semi-annually</RadioButton>
                <RadioButton value="annually">Annually</RadioButton>
              </RadioButtonField>
            </NotesProvider>
          </>
        )}
      </IncomeFieldsGroup>

      <IncomeFieldsGroup>
        <NotesProvider name={`${parentPath}.income.interestEarned.eligibility`}>
          <Prompt style={s('mt-0 mb-4')}>
            Does {applicantName} earn any interest?
          </Prompt>
          <RadioButtonField
            name={`${parentPath}.income.interestEarned.eligibility`}
            aria-label="Interest Earned Eligibility"
            containerStyle={s('mb-4')}
            testId={TEST_IDS.EARN_INTEREST_RB}
          >
            <RadioButton value={true}>Yes</RadioButton>
            <RadioButton value={false}>No</RadioButton>
          </RadioButtonField>
        </NotesProvider>

        {applicantHasEarnedInterest && (
          <>
            <NotesProvider name={`${parentPath}.income.interestEarned.amount`}>
              <Prompt style={s('m-0')}>Please enter amount</Prompt>
              <TextField
                name={`${parentPath}.income.interestEarned.amount`}
                aria-label="Interest Earned amount"
                prefix="$"
                containerStyle={s('w-1/2 mb-6')}
                testId={TEST_IDS.INTEREST_AMOUNT_INPUT}
              />
            </NotesProvider>

            <NotesProvider name={`${parentPath}.income.interestEarned.recurrence`}>
              <Prompt>How frequently does {applicantName} receive interest?</Prompt>
              <RadioButtonField
                aria-label="Interest earned recurrence"
                containerStyle={s('w-full')}
                name={`${parentPath}.income.interestEarned.recurrence`}
                testId={TEST_IDS.INTEREST_FREQ_RB}
              >
                <RadioButton value="monthly">Monthly</RadioButton>
                <RadioButton value="quarterly">Quarterly</RadioButton>
                <RadioButton value="half_yearly">Semi-annually</RadioButton>
                <RadioButton value="annually">Annually</RadioButton>
              </RadioButtonField>
            </NotesProvider>
          </>
        )}
      </IncomeFieldsGroup>

      <IncomeFieldsGroup>
        <NotesProvider name={`${parentPath}.income.childSupport.eligibility`}>
          <Prompt style={s('mt-0 mb-4')}>
            Does {applicantName} receive any child support?
          </Prompt>
          <RadioButtonField
            name={`${parentPath}.income.childSupport.eligibility`}
            aria-label="Child Support eligibility"
            containerStyle={s('mb-4')}
            testId={TEST_IDS.CHILD_SUPPORT_RB}
          >
            <RadioButton value={true}>Yes</RadioButton>
            <RadioButton value={false}>No</RadioButton>
          </RadioButtonField>
        </NotesProvider>

        {applicantHasChildSupport && (
          <>
            <NotesProvider name={`${parentPath}.income.childSupport.amount`}>
              <Prompt style={s('m-0')}>Please enter amount</Prompt>
              <TextField
                name={`${parentPath}.income.childSupport.amount`}
                aria-label="Child Support amount"
                prefix="$"
                containerStyle={s('w-1/2 mb-6')}
                testId={TEST_IDS.CHILD_SUPPORT_AMOUNT_INPUT}
              />
            </NotesProvider>

            <NotesProvider name={`${parentPath}.income.childSupport.recurrence`}>
              <Prompt>
                How frequently does {applicantName} receive child support?
              </Prompt>
              <RadioButtonField
                aria-label="Child Support recurrence"
                containerStyle={s('w-full')}
                name={`${parentPath}.income.childSupport.recurrence`}
                testId={TEST_IDS.CHILD_SUPPORT_FREQ_RB}
              >
                <RadioButton value="monthly">Monthly</RadioButton>
                <RadioButton value="quarterly">Quarterly</RadioButton>
                <RadioButton value="half_yearly">Semi-annually</RadioButton>
                <RadioButton value="annually">Annually</RadioButton>
              </RadioButtonField>
            </NotesProvider>
          </>
        )}
      </IncomeFieldsGroup>
    </div>
  )
}

export { Income }
