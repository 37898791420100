import React from 'react'
import { s, styled } from '@vega/styled'
import { prop } from '@solta/ramda-extra'
import { ReactComponent as DropdownIcon } from '@vega/components/src/assets/images/dottedHorizontalLine.svg'

const Container = styled('div', { shouldForwardProp: (p) => p !== 'containerStyle' })(
  s('flex items-center', {
    height: 38.5,
    cursor: 'pointer',
  }),
  prop('containerStyle'),
  ({ isDisabled }) => isDisabled && { opacity: 0.2, pointerEvents: 'none' }
)

export default function ActionsDropdownToggler({
  containerStyle,
  isDisabled,
  width = 8,
  height = 16,
  onClick,
  ...otherProps
}) {
  return (
    <Container
      onClick={onClick}
      containerStyle={containerStyle}
      isDisabled={isDisabled}
      {...otherProps}
    >
      <DropdownIcon width={width} height={height} />
    </Container>
  )
}
