import React from 'react'
import { s, styled } from '@vega/styled'
import { prop } from '@solta/ramda-extra'

const RateContainer = styled.div(s('flex flex-column my-0'), prop('containerStyle'))
const RateValue = styled.p(s('font-bold my-0 mr-1', { fontSize: 42 }))
const RateLabel = styled.p(s('text-grey-500 text-sm my-0'))
const RateValueContainer = styled.div(s('flex my-0'))
const RateUnit = styled.p(
  s('my-0 text-grey-600 font-medium text-xs w-1 pt-2', {
    wordSpacing: 1000,
  })
)

export const InterestRate = ({ rate, unit = '% p.a', label, containerStyle }) => {
  return (
    <RateContainer containerStyle={containerStyle}>
      <RateValueContainer>
        <RateValue>{rate}</RateValue>
        <RateUnit>{unit}</RateUnit>
      </RateValueContainer>
      <RateLabel>{label}</RateLabel>
    </RateContainer>
  )
}
