import { path, prop, isNilOrEmpty, find, defaultTo } from '@solta/ramda-extra'
import { createSelector } from '@reduxjs/toolkit'

const selectGetApplicationsSummaryReportStatus = path([
  'asyncState',
  'reports/getLoanApplicationsSummary',
  'status',
])
const selectGetInProgressApplicationsReportStatus = path([
  'asyncState',
  'reports/getInProgressApplicationsReport',
  'status',
])
const selectGetApplicationsBreakdownReportStatus = path([
  'asyncState',
  'reports/getApplicationsBreakdownReport',
  'status',
])
const selectGetClientsCountReportStatus = path([
  'asyncState',
  'reports/getClientsCountReport',
  'status',
])
const selectGetTopClientsStatus = path([
  'asyncState',
  'reports/getTopClients',
  'status',
])

const selectReportsState = prop('reports')

const selectLoanApplicationReport = createSelector(
  selectReportsState,
  prop('loanApplication')
)

const selectClientReport = createSelector(selectReportsState, prop('client'))

const selectApplicationsSummary = createSelector(
  selectLoanApplicationReport,
  prop('summary')
)

const selectInProgressApplicationsReport = createSelector(
  selectLoanApplicationReport,
  prop('inProgressReport')
)

const selectApplicationsBreakdownReport = createSelector(
  selectLoanApplicationReport,
  prop('breakdownReport')
)

const selectClientsCountReports = createSelector(
  selectClientReport,
  prop('countReports')
)

const selectTopClients = createSelector(selectClientReport, prop('topClients'))

const selectDataFromInProgressReportByDayRange = (dayStarts, dayEnds) =>
  createSelector(selectInProgressApplicationsReport, (inProgressReport) => {
    const listOfData = inProgressReport?.data

    if (isNilOrEmpty(inProgressReport) || isNilOrEmpty(listOfData)) return undefined

    const foundDataByDayRange = find((d) => {
      const { dayRangeStarts, dayRangeEnds } = d

      return dayRangeStarts === dayStarts && dayRangeEnds === dayEnds
    }, listOfData)

    return foundDataByDayRange
  })

const selectReportFromClientsCountReportsByMonth = (queriedYear, queriedMonth) =>
  createSelector(selectClientsCountReports, (clientsCountReports = []) => {
    if (isNilOrEmpty(clientsCountReports)) return {}

    const ReportFoundByMonth = find((d) => {
      const { year, month } = d

      return year === queriedYear && month === queriedMonth
    }, clientsCountReports)

    return defaultTo({}, ReportFoundByMonth)
  })

export {
  selectGetApplicationsSummaryReportStatus,
  selectGetInProgressApplicationsReportStatus,
  selectGetApplicationsBreakdownReportStatus,
  selectGetClientsCountReportStatus,
  selectGetTopClientsStatus,
  selectApplicationsSummary,
  selectInProgressApplicationsReport,
  selectApplicationsBreakdownReport,
  selectClientsCountReports,
  selectTopClients,
  selectReportFromClientsCountReportsByMonth,
  selectDataFromInProgressReportByDayRange,
}
