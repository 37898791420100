import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { and, isNotNilOrEmpty } from '@solta/ramda-extra'
import { styled, s } from '@vega/styled'
import {
  selectInvitationUrl,
  selectCreateInvitationStatus,
  resetInvitationUrl,
} from 'modules/invitation'

import { ASYNC_STATUSES } from '@neo/constants'
import { Modal as ModalBase } from '@vega/components'
import { Form } from './Form'
import { InvitationSent } from './InvitationSent'

const Container = styled.div(
  s('bg-white py-6 px-8 w-32', {
    borderRadius: '12px 0px 12px 12px',
  })
)

function Modal({ isOpen, setIsOpen }) {
  const dispatch = useDispatch()

  const invitationStatus = useSelector(selectCreateInvitationStatus)
  const invitationUrl = useSelector(selectInvitationUrl)
  const isInvitationFulfilled = and(
    isNotNilOrEmpty(invitationUrl),
    invitationStatus === ASYNC_STATUSES.FULFILLED
  )

  const onClose = () => {
    setIsOpen(false)
    dispatch(resetInvitationUrl())
  }

  return (
    <ModalBase isOpen={isOpen} onClose={onClose}>
      <Container>
        {isInvitationFulfilled ? (
          <InvitationSent invitationUrl={invitationUrl} onClose={onClose} />
        ) : (
          <Form onClose={onClose} />
        )}
      </Container>
    </ModalBase>
  )
}

export { Modal }
