import {
  pipe,
  filter,
  isNotNilOrEmpty,
  trim,
  join,
  map,
  path,
  pick,
  isNil,
  values,
} from '@solta/ramda-extra'
import { STATUSES } from '../constants'

const joinNames = (names = []) =>
  pipe(filter(isNotNilOrEmpty), join(' '), trim)([...names])

const formatApplicantNames = (names = []) => {
  if (names.length > 2) return `${names[0]}, ${names[1]} & others`
  if (names.length === 2) return names.join(', ')
  return names[0]
}

const getApplicantFullNames = (applicants = []) =>
  pipe(
    map(pick(['firstName', 'lastName'])),
    map(values),
    map(joinNames),
    formatApplicantNames
  )(applicants)

const getApplicantCheckStatus = (applicant, creditAssessorCheck, step) => {
  const check = path([step, applicant.id, 'isVerified'], creditAssessorCheck)
  if (isNil(check)) {
    return STATUSES.PENDING
  }

  return check ? STATUSES.APPROVED : STATUSES.REJECTED
}

const getMultiStepApplicantCheckStatus = (
  applicant,
  creditAssessorCheck,
  step1,
  step2
) => {
  const status1 = getApplicantCheckStatus(applicant, creditAssessorCheck, step1)
  const status2 = getApplicantCheckStatus(applicant, creditAssessorCheck, step2)

  if (status1 === STATUSES.PENDING || status2 === STATUSES.PENDING) {
    return STATUSES.PENDING
  }

  if (status1 === STATUSES.REJECTED || status2 === STATUSES.REJECTED) {
    return STATUSES.REJECTED
  }

  return STATUSES.APPROVED
}

export {
  getApplicantFullNames,
  getApplicantCheckStatus,
  getMultiStepApplicantCheckStatus,
}
