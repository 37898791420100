import React, { useEffect, useCallback } from 'react'
import * as Validator from 'yup'
import { styled, s } from '@vega/styled'
import { useFormikContext } from 'formik'
import { useWizard, Wizard } from '@vega/formik-wizard'
import { useDispatch, useSelector } from 'react-redux'
import {
  resetForm,
  selectProgressStepStatus,
  updateFormProgress,
  updateIntent,
} from 'modules/application'
import { useHistory } from 'react-router-dom'
import { routes } from 'routes'
import { FormProgress } from '../../components/FormProgress/FormProgress'
import { InfoSidebar } from './InfoSidebar'
import {
  Title,
  Subtitle,
  Prompt as PromptBase,
} from 'features/broker/applications/components/typography'
import { RadioButtonField, RadioButton, TextField } from '@vega/components'
import { ExpectedRentAmount } from './ExpectedRentAmount'
import { BottomNavigation } from './BottomNavigation'
import { and, isNilOrEmpty, isNotNilOrEmpty, replace } from '@solta/ramda-extra'
import { TEST_IDS } from '@neo/constants'
import * as CONSTANTS from '@vega/constants'
import { markProgressStepAsVisited } from 'features/broker/applications/components/FormProgress/utils'

const {
  FORM_PROGRESS: {
    STEP_STATUS: { COMPLETED },
    STEP_NAMES: { GOALS },
  },
} = CONSTANTS
const { object, mixed, number } = Validator

export const validationSchema = Validator.object({
  intent: object({
    loanPurpose: mixed()
      .oneOf(['purchase', 'refinance'])
      .required('Selection is required')
      .label('Loan Purpose'),
    propertyPurpose: mixed()
      .oneOf(['living', 'investment'])
      .required('Selection is required')
      .label('Property Purpose'),
    estimatedPropertyValue: number()
      .positive()
      .required('Amount is required')
      .label('Estimated Property Value'),
    borrowingAmount: number()
      .positive()
      .required('Amount is required')
      .label('Borrowing Amount'),
    expectedRentAmount: number()
      .when(['propertyPurpose'], {
        is: (propertyPurpose) => propertyPurpose === 'investment',
        then: number().positive().required('Amount is required'),
      })
      .label('Expected Rent Amount'),
  }),
})

const Root = styled.div(s('flex-1 flex items-center w-full h-full relative'))
const ProgressContainer = styled.div(s('bg-white h-full'))

const Core = styled.div(s('flex flex-column w-full h-full'))
const Content = styled.div(
  s('flex-1 flex justify-center w-full pt-6', {
    paddingRight: 200,
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  })
)
const FormContainer = styled.div(s('flex flex-column pt-1'), {
  width: 652,
  '> :last-child': s('pb-12'),
})
const InfoContainer = styled.div(s('relative', { pointerEvents: 'none' }))
const NavigationContainer = styled.div(s('relative zIndex-2'))

const Prompt = styled(PromptBase)(s('mt-6 mb-4'))

const YourGoalsStep = ({ stepId, loanApplicationId }) => {
  const {
    values: { intent },
    errors,
  } = useFormikContext()

  const { loanPurpose } = intent

  const dispatch = useDispatch()
  const history = useHistory()

  const saveAndContinue = ({ intent }) => {
    dispatch(updateIntent(intent))
    dispatch(updateFormProgress({ step: GOALS, status: COMPLETED }))
  }

  const goToOverview = () =>
    history.push(
      replace(':id', loanApplicationId, routes.client.applications.overview.details)
    )

  const saveAndExit = () => {
    dispatch(updateIntent(intent))
    dispatch(resetForm())
    goToOverview()
  }

  const { previous, currentStep } = useWizard()
  const goBack = () => previous()
  const isLoanPurposePicked = isNotNilOrEmpty(loanPurpose)
  const isFormValid = isNilOrEmpty(errors?.intent)
  const canNext = and(isLoanPurposePicked, isFormValid)

  const currentStepStatus = useSelector(selectProgressStepStatus(GOALS))
  useEffect(() => {
    if (currentStep?.id === stepId) markProgressStepAsVisited(GOALS, currentStepStatus)
  }, [currentStep, currentStepStatus, stepId])

  return (
    <Wizard.Step
      id={stepId}
      title="Your Goals"
      validationSchema={validationSchema}
      onSubmit={useCallback(saveAndContinue, [])}
    >
      <Root>
        <ProgressContainer>
          <FormProgress saveAndExit={saveAndExit} />
        </ProgressContainer>

        <Core>
          <Content>
            <FormContainer>
              <Title style={s('mb-4')}>Let&apos;s discuss your goals</Title>

              <Subtitle style={s('mb-8')}>
                We love to say &quot;your wish is our command&quot;, but we&apos;re not
                a genie. Tell us your goals by answering the following questions
              </Subtitle>

              <Prompt style={s('mt-0')}>What are you looking to do?</Prompt>
              <RadioButtonField
                name="intent.loanPurpose"
                aria-label="loanPurpose"
                testId={TEST_IDS.LOAN_PURPOSE_RB}
              >
                <RadioButton value="purchase">I&apos;m purchasing</RadioButton>
                <RadioButton value="refinance">I&apos;m refinancing</RadioButton>
              </RadioButtonField>

              {isLoanPurposePicked && (
                <>
                  <Prompt>Do you live in this property, or is it an investment?</Prompt>
                  <RadioButtonField
                    name="intent.propertyPurpose"
                    aria-label="propertyPurpose"
                    testId={TEST_IDS.PROPERTY_PURPOSE_RB}
                  >
                    <RadioButton value="living">Live-in</RadioButton>
                    <RadioButton value="investment">Investment</RadioButton>
                  </RadioButtonField>

                  <Prompt style={s('mb-0')}>
                    How much do you think this property is worth? (an estimated value is
                    acceptable)
                  </Prompt>
                  <TextField
                    name="intent.estimatedPropertyValue"
                    aria-label="estimatedPropertyValue"
                    containerStyle={s('w-1/2')}
                    prefix="$"
                    testId={TEST_IDS.EST_PROPERTY_VALUE_INPUT}
                  />

                  <Prompt style={s('mb-0')}>
                    How much do you want to borrow against this property? This should
                    cover your current loan, plus any additional funds you&apos;d like
                    to borrow
                  </Prompt>
                  <TextField
                    name="intent.borrowingAmount"
                    aria-label="borrowingAmount"
                    containerStyle={s('w-1/2')}
                    prefix="$"
                    testId={TEST_IDS.BORROWING_AMOUNT_INPUT}
                  />

                  <ExpectedRentAmount />
                </>
              )}
            </FormContainer>

            <InfoContainer>
              <InfoSidebar />
            </InfoContainer>
          </Content>

          <NavigationContainer>
            <BottomNavigation goBack={goBack} canNext={canNext} />
          </NavigationContainer>
        </Core>
      </Root>
    </Wizard.Step>
  )
}

export { YourGoalsStep }
