/* eslint-disable complexity */
import React from 'react'
import { useSelector } from 'react-redux'
import { styled, s } from '@vega/styled'
import { ASYNC_STATUSES } from '@neo/constants'
import { noop, defaultTo } from '@solta/ramda-extra'
import { selectUploadFileStatusByKey } from '@vega/redux.upload'

import { ReactComponent as PaperClip } from '@vega/components/src/assets/images/paper-clip.svg'
import { ReactComponent as Trash } from '@vega/components/src/assets/images/trash.svg'
import { ReactComponent as XCirlce } from '@vega/components/src/assets/images/x-circle.svg'
import { DottedCircleLoader } from '@vega/components'

const Container = styled.div(s('flex items-center mb-4'))
const Prefix = styled.div(s('w-1 h-1'))
const Suffix = styled.div(s('w-1 h-1 ml-a'))
const Text = styled.div(s('p-1 text-sm'))

const { PENDING, FULFILLED, REJECTED } = ASYNC_STATUSES

const UploadFile = ({ fileName, fileKey, onRemove = noop }) => {
  const UPLOADED = 'uploaded'
  const status = defaultTo(UPLOADED, useSelector(selectUploadFileStatusByKey(fileKey)))

  const renderPrefix = (status) => {
    return {
      [PENDING]: <PaperClip style={s('text-grey-400')} />,
      [FULFILLED]: <PaperClip style={s('text-grey-400')} />,
      [REJECTED]: <XCirlce style={s('text-error-600')} />,
      [UPLOADED]: <PaperClip style={s('text-grey-400')} />,
    }[status]
  }

  const renderSuffix = (status) => {
    return {
      [PENDING]: (
        <DottedCircleLoader size="sm" customStyles={s('m-0', { marginTop: 6 })} />
      ),
      [FULFILLED]: (
        <Trash
          style={s('text-grey-400', { cursor: 'pointer' })}
          onClick={() => onRemove()}
        />
      ),
      [REJECTED]: (
        <Trash
          style={s('text-error-600', { cursor: 'pointer' })}
          onClick={() => onRemove()}
        />
      ),
      [UPLOADED]: (
        <Trash
          style={s('text-grey-400', { cursor: 'pointer' })}
          onClick={() => onRemove()}
        />
      ),
    }[status]
  }

  const renderFileName = (status) => {
    return {
      [PENDING]: <Text>{fileName}</Text>,
      [FULFILLED]: <Text style={{ color: '#1890FF' }}> {fileName}</Text>,
      [REJECTED]: <Text style={s('text-error-600')}>{fileName}</Text>,
      [UPLOADED]: <Text style={{ color: '#1890FF' }}> {fileName}</Text>,
    }[status]
  }

  return (
    <Container>
      <Prefix>{renderPrefix(status)}</Prefix>

      {renderFileName(status)}

      <Suffix>{renderSuffix(status)}</Suffix>
    </Container>
  )
}

export { UploadFile }
