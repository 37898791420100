import React, { useCallback, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { isNotNilOrEmpty, replace } from '@solta/ramda-extra'
import * as CONSTANTS from '@vega/constants'
import { useWizard, Wizard } from '@vega/formik-wizard'
import { s, styled } from '@vega/styled'

import { routes } from 'routes'
import {
  resetForm,
  updateApplicant,
  selectProgressStepStatus,
  updateFormProgress,
} from 'modules/application'

import { FormProgress } from '../../components/FormProgress/FormProgress'
import { BottomNavigation } from './BottomNavigation'
import { InfoSidebar } from './InfoSidebar'
import { removePhoneNumberSpaces } from './utils'
import { validationSchema } from './validationSchema'
import { View } from './View'
import { markProgressStepAsVisited } from 'features/broker/applications/components/FormProgress/utils'

const {
  FORM_PROGRESS: {
    STEP_STATUS: { COMPLETED },
    STEP_NAMES: { CLIENT_DETAILS },
  },
} = CONSTANTS

const Root = styled.div(s('flex-1 flex items-center w-full h-full relative'))
const ProgressContainer = styled.div(s('bg-white h-full'))

const Core = styled.div(s('flex flex-column w-full h-full'))
const Content = styled.div(
  s('flex-1 flex justify-center w-full pt-6', {
    paddingRight: 200,
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  })
)
const FormContainer = styled.div(s('flex flex-column pt-1'), {
  width: 652,
  '> :last-child': s('pb-12'),
})
const InfoContainer = styled.div(s('relative', { pointerEvents: 'none' }))
const NavigationContainer = styled.div(s('relative zIndex-2'))

function DetailsForm({ stepId, applicantPathId, applicantId, loanApplicationId }) {
  const history = useHistory()
  const dispatch = useDispatch()

  const { currentStep } = useWizard()
  const goToOverview = () =>
    history.push(
      replace(':id', loanApplicationId, routes.client.applications.overview.details)
    )

  const saveAndExit = () => {
    dispatch(resetForm())
    goToOverview()
  }

  const saveAndContinue = async (values) => {
    const cleanValues = removePhoneNumberSpaces(values, applicantPathId)

    dispatch(updateApplicant(cleanValues?.details?.[applicantPathId]))

    if (isNotNilOrEmpty(applicantId)) {
      dispatch(
        updateFormProgress({ step: CLIENT_DETAILS, status: COMPLETED, applicantId })
      )
    }
  }

  const currentStepStatus = useSelector(
    selectProgressStepStatus(CLIENT_DETAILS, applicantId)
  )

  useEffect(() => {
    if (currentStep?.id === stepId && isNotNilOrEmpty(applicantId))
      markProgressStepAsVisited(CLIENT_DETAILS, currentStepStatus, applicantId)
  }, [applicantId, currentStep, currentStepStatus, stepId])

  return (
    <Wizard.Step
      id={stepId}
      title="Client details"
      validationSchema={useMemo(() => validationSchema(applicantPathId), [
        applicantPathId,
      ])}
      onSubmit={useCallback(saveAndContinue, [])}
    >
      <Root>
        <ProgressContainer>
          <FormProgress saveAndExit={saveAndExit} />
        </ProgressContainer>

        <Core>
          <Content>
            <FormContainer>
              <View applicantPathId={applicantPathId} />
            </FormContainer>

            <InfoContainer>
              <InfoSidebar />
            </InfoContainer>
          </Content>

          <NavigationContainer>
            <BottomNavigation />
          </NavigationContainer>
        </Core>
      </Root>
    </Wizard.Step>
  )
}

export { DetailsForm }
