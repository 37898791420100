/* eslint-disable complexity */
import React from 'react'
import { isNotNil, all, isTrue } from '@solta/ramda-extra'
import { useFormikContext } from 'formik'
import { RadioButton, RadioButtonField, Button as ButtonBase } from '@vega/components'
import { TopNavBar } from 'features/common/property'
import { styled, s } from '@vega/styled'

const Container = styled.div(
  s('flex flex-column items-center w-full px-10', {
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  })
)

const ContentContainer = styled.div(s('flex flex-column items-center pt-16'))
const Title = styled.h2(s('m-0 mb-3 font-semibold text-2xl'))
const Subtitle = styled.span(s('block mb-3 text-grey-600 mb-6'))

const FieldContainer = styled.div(s('mb-6'))
const Prompt = styled.p(
  s('text-grey-700 font-semibold text-base m-0 mb-4', {
    lineHeight: 1.5,
    letterSpacing: '0.015em',
  })
)

const Button = styled(ButtonBase)(s('px-8 text-grey-800 font-semibold'))

function SavedPropertyQuestions({ onClose, setCurrentPage }) {
  const { values: formikValues, setFieldValue, resetForm } = useFormikContext()

  const hasMadeOffer = formikValues?.hasMadeOffer
  const hasWonOffer = formikValues?.hasWonOffer
  const isIntendedToSubmit = formikValues?.isIntendedToSubmit

  const showSearchPropertyBtn = all(isTrue)([
    isNotNil(hasMadeOffer),
    isTrue(hasWonOffer),
    !isIntendedToSubmit,
  ])

  const toPrevPage = () => {
    setCurrentPage('propertyDetails')
    resetForm()
  }
  const toPropertySearchPage = () => setCurrentPage('propertySearch')

  const onSearchPropertyBtnClicked = () => {
    toPropertySearchPage()
    resetForm()
  }
  const submitProperty = () => {
    // submit property
    onClose()
  }

  const onDeclineBtnClick = () => {}

  React.useEffect(() => {
    if (hasMadeOffer === false) {
      setFieldValue('hasWonOffer', undefined)
      setFieldValue('isIntendedToSubmit', undefined)
    }

    if (hasWonOffer === false) {
      setFieldValue('isIntendedToSubmit', undefined)
    }
  }, [hasMadeOffer, hasWonOffer, setFieldValue])

  return (
    <Container>
      <TopNavBar onClose={onClose} toPrevPage={toPrevPage} />

      <ContentContainer>
        <div>
          <Title>Great! This property has been saved</Title>
          <Subtitle>
            Before heading back to the application, please answer a few questions
          </Subtitle>
          <FieldContainer>
            <Prompt>
              Have you attended the auction for this property, or have you made an
              offer?
            </Prompt>
            <RadioButtonField
              name="hasMadeOffer"
              aria-label="Have attended to auction of have offer been made?"
            >
              <RadioButton value={true}>Yes</RadioButton>
              <RadioButton value={false}>No</RadioButton>
            </RadioButtonField>
          </FieldContainer>

          {hasMadeOffer && (
            <FieldContainer>
              <Prompt>Did you win the offer for this property?</Prompt>
              <RadioButtonField
                name="hasWonOffer"
                aria-label="Did you win the offer for this property?"
              >
                <RadioButton value={true}>Yes</RadioButton>
                <RadioButton value={false}>No</RadioButton>
              </RadioButtonField>
            </FieldContainer>
          )}
          {hasMadeOffer === false && (
            <Button onClick={onSearchPropertyBtnClicked}>Search more properties</Button>
          )}

          {hasWonOffer && (
            <FieldContainer>
              <Prompt>Do you want to submit this property now?</Prompt>
              <RadioButtonField
                name="isIntendedToSubmit"
                aria-label="Do you want to submit this property now?"
              >
                <RadioButton value={true}>Yes</RadioButton>
                <RadioButton value={false}>No</RadioButton>
              </RadioButtonField>
            </FieldContainer>
          )}
          {hasWonOffer === false && (
            <>
              <Prompt style={s('mb-8')}>
                We&apos;re sorry. It&apos;s not you, it&apos;s them. Let&apos;s search
                for a new one..
              </Prompt>

              <Button onClick={onDeclineBtnClick}>Submit</Button>
            </>
          )}

          {isIntendedToSubmit && (
            <Button onClick={submitProperty}>Submit property</Button>
          )}

          {showSearchPropertyBtn && (
            <Button onClick={onSearchPropertyBtnClicked}>Search more properties</Button>
          )}
        </div>
      </ContentContainer>
    </Container>
  )
}

export { SavedPropertyQuestions }
