/* eslint-disable consistent-return */
/* eslint-disable complexity */
import React from 'react'
import { useWizard } from './context'
import { Formik, Form } from 'formik'
import { last, pickThenRename, noop } from '@solta/ramda-extra'

function SteppedForm({
  initialValues = {},
  onSubmit = noop,
  children,
  formProps,
  autoNext = true,
  ...props
}) {
  const { steps, currentStep, next, setSubmitContext } = useWizard()

  const {
    validate,
    validationSchema,
    onStepSubmit = noop,
    shouldNext,
  } = pickThenRename(
    ['validate', 'validationSchema', 'onSubmit', 'shouldNext'],
    { onSubmit: 'onStepSubmit' },
    currentStep
  )

  async function handleSubmit(values, form) {
    setSubmitContext({})

    const lastStep = last(steps)
    const isLastStep = lastStep.id === currentStep.id

    try {
      if (isLastStep) {
        await onStepSubmit(values, form)

        return onSubmit(values, form)
      }

      await onStepSubmit(values, form)
    } catch (error) {
      setSubmitContext({
        status: 'error',
        error,
      })

      return Promise.reject(error)
    }

    form.setTouched({})
    form.setSubmitting(false)
    if (autoNext && shouldNext) next()

    return Promise.resolve()
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validate={validate}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      {...props}
    >
      <Form {...formProps}>{children}</Form>
    </Formik>
  )
}

export { SteppedForm }
