import React from 'react'
import { noop } from '@solta/ramda-extra'
import { Modal as ModalBase } from '@vega/components'
import { PropertyInfo, TopNavBar } from 'features/common/property'
import { styled, s } from '@vega/styled'

const Container = styled.div(
  s('bg-green-50 px-10', { height: '100vh', width: '100vw', overflowY: 'auto' })
)

const ContentContainer = styled.div(
  s('flex flex-column items-center w-full py-10 mx-a', { maxWidth: 1200 })
)
const PropertyImage = styled.img(
  s('w-full object-cover', { borderRadius: '20px 0 20px 20px', maxHeight: 500 })
)
const InfoContainer = styled.div(s('pt-8 px-24 w-full'))

function PropertyDetailsModal({ isOpen, onClose = noop, property }) {
  return (
    <ModalBase isOpen={isOpen} onClose={onClose}>
      <Container>
        <TopNavBar onClose={onClose} toPrevPage={onClose} />

        <ContentContainer>
          <PropertyImage src={property?.imageSrc} />

          <InfoContainer>
            <PropertyInfo property={property} />
          </InfoContainer>
        </ContentContainer>
      </Container>
    </ModalBase>
  )
}

export { PropertyDetailsModal }
