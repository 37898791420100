import { defaultTo } from '@solta/ramda-extra'
import { calculatePrevMonth } from '../../utils/calculatePrevMonth'

const addLeadingZeroForMonth = (month) => {
  if (month >= 10) return month

  return `0${month}`
}

const getSummaryReportId = (year, month) => `${year}-${addLeadingZeroForMonth(month)}`

export const extractSummaryReportsForComparison = (summaryReports, queriedDate) => {
  const { year, month } = queriedDate

  const { prevYear, prevMonth } = calculatePrevMonth(year, month)

  const reportIdOfQueriedDate = getSummaryReportId(year, month)
  const reportIdOfPrevMonth = getSummaryReportId(defaultTo(year, prevYear), prevMonth)

  return {
    summaryReportOfQueriedDate: defaultTo({}, summaryReports[reportIdOfQueriedDate]),
    summaryReportOfPrevMonth: defaultTo({}, summaryReports[reportIdOfPrevMonth]),
  }
}
