import React, { useState } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import { s, styled } from '@vega/styled'
import { TopTabNavigator, PageHeader, InviteButton } from '@vega/components'

import AllUsers from './allUsers'
import PendingInvitation from './pendingInvitation'
import { InvitationModal } from 'features/common/invitationModal'

import { TEST_IDS } from '@neo/constants'

const Root = styled.div(
  s('flex flex-column w-full h-full min-h-32 bg-white', { overflow: 'hidden' })
)

function UserManagementSubRouter({ parentRoute }) {
  const [isModalOpened, setIsModalOpened] = useState(false)

  const userManagementPath = `${parentRoute}/user-management`

  const allUserPath = `${userManagementPath}/all-users`
  const activityLogPath = `${userManagementPath}/activity-log`
  const pendingInvitationPath = `${userManagementPath}/pending-invitation`

  const routes = [
    {
      name: 'All Users',
      path: allUserPath,
    },
    {
      name: 'Activity Log',
      path: activityLogPath,
    },
    {
      name: 'Pending Invitation',
      path: pendingInvitationPath,
    },
  ]

  return (
    <Root>
      <PageHeader title="User Management">
        <InviteButton invitee="user" onClick={() => setIsModalOpened(true)} />
      </PageHeader>

      <TopTabNavigator
        routes={routes}
        testId={TEST_IDS.ADMIN_USR_MANAGEMENT_TOP_NAVIGATION_BAR}
      />

      <Switch>
        <Route exact path={allUserPath} component={AllUsers} />
        <Route exact path={activityLogPath}>
          <div>Activity Log</div>
        </Route>
        <Route exact path={pendingInvitationPath} component={PendingInvitation} />
        <Redirect to={allUserPath} />
      </Switch>

      <InvitationModal isOpen={isModalOpened} setIsOpen={setIsModalOpened} />
    </Root>
  )
}

export default UserManagementSubRouter
