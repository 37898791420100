/* eslint-disable complexity */
import React, { memo } from 'react'
import { Field as SlowField, FastField } from 'formik'
import { prop, mergeDeepRight } from '@solta/ramda-extra'
import { useTextField } from 'react-aria'

import { styled, s } from '@vega/styled'
import fmt from '../formatters'

const Root = styled.div(
  s('border-0 w-full flex flex-column'),
  s('text-grey-800 text-base', {
    lineHeight: 1.5,
  }),
  prop('containerStyle')
)
const LabelContainer = styled.div(s('flex justify-between'))
const Label = styled.label(
  s('text-grey-500 font-medium text-base', {
    pointerEvents: 'none',
  })
)
const InputContainer = styled.div(
  s('rounded-b-lg rounded-tl-lg border-1 border-solid border-grey-200 bg-transparent'),
  s('flex items-center justify-between p-0 h-full w-full relative'),
  ({ isReadOnly }) => isReadOnly && s('bg-white border-0', { pointerEvents: 'none' }),

  prop('inputContainerStyle')
)
const Prefix = styled.span(s('text-xl flex items-center'))
const Input = styled.input(
  s('h-full w-full border-0 bg-transparent'),
  s('text-grey-800 text-xl font-medium'),
  {
    outline: 'none',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  ({ isReadOnly }) => isReadOnly && s('text-grey-800'),
  prop('inputStyle')
)

const TextField = ({
  children,
  prefix,
  fast = true,
  type = 'text',
  containerStyle,
  inputStyle,
  inputContainerStyle,
  formatValue = fmt.formatNonNumeric,
  ...props
}) => {
  const { label, name, id, isReadOnly, onClick } = props
  const ref = React.useRef()
  const { labelProps, inputProps } = useTextField(props, ref)

  const Field = fast ? FastField : SlowField

  return (
    <Field name={name} id={id} type={type}>
      {({ field }) => {
        const { value: formValue, onChange } = field

        const value = formatValue(formValue)
        return (
          <Root containerStyle={containerStyle} {...props}>
            <LabelContainer>
              <Label {...labelProps}>{label}</Label>
            </LabelContainer>
            <InputContainer
              isFilled={Boolean(value)}
              isReadOnly={isReadOnly}
              inputContainerStyle={inputContainerStyle}
            >
              {prefix && <Prefix>{prefix}</Prefix>}
              <Input
                ref={ref}
                {...mergeDeepRight(inputProps, {
                  value,
                  onChange,
                  onClick,
                })}
                inputStyle={inputStyle}
                isReadOnly={isReadOnly}
              />
            </InputContainer>
            {children}
          </Root>
        )
      }}
    </Field>
  )
}

export default memo(TextField)
