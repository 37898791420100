import { defaultWhen, isNilOrEmpty } from '@solta/ramda-extra'

export const defaultOnErr = (func, fallback = {}, logger = console) => (arg) => {
  if (isNilOrEmpty(arg)) return fallback
  try {
    return defaultWhen(isNilOrEmpty, fallback, func(arg))
  } catch (error) {
    const isDev = process.env.NODE_ENV === 'development'
    if (isDev) logger.error(error)
  }
  return fallback
}
