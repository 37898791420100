import React from 'react'
import { noop } from '@solta/ramda-extra'
import { ReactComponent as X } from '@vega/components/src/assets/images/x.svg'
import { Modal as ModalBase } from '@vega/components'
import { styled, s } from '@vega/styled'

const Container = styled.div(
  s('flex flex-column bg-white p-4 w-32', { borderRadius: 12 })
)
const Header = styled.div(s('w-full flex justify-end mb-4'))
const Body = styled.div(s('pb-8 pl-6 pr-10'))

const Title = styled.span(s('block text-grey-800 font-semibold text-2xl mb-6'))
const Text = styled.p(s('m-0 text-grey-600'))

const NextStepList = styled.ul(s('p-0 pl-4 m-0'))

function PropertyApprovedModal({ isOpen, onClose = noop }) {
  return (
    <ModalBase isOpen={isOpen} onClose={onClose}>
      <Container>
        <Header>
          <X onClick={onClose} style={{ cursor: 'pointer' }} />
        </Header>

        <Body>
          <Title>Property approved!</Title>
          <Text style={s('mb-6')}>We&apos;ll notify your clients about this.</Text>

          <Text style={s('mb-2')}>Next step:</Text>
          <NextStepList>
            <li style={s('')}>
              <Text style={{ lineHeight: 1.5 }}>
                Credit accessor will review this application, then we will email you and
                your client the result
              </Text>
            </li>
          </NextStepList>
        </Body>
      </Container>
    </ModalBase>
  )
}

export { PropertyApprovedModal }
