import { useLayoutEffect } from 'react'
import { useWizard } from './context'
import { prop, noop } from '@solta/ramda-extra'

function Step({
  id,
  title,
  validate,
  validationSchema,
  onSubmit = noop,
  shouldNext = true,
  children,
}) {
  const { currentStep, register } = useWizard()

  useLayoutEffect(() => {
    register({ id, title, validate, validationSchema, onSubmit, shouldNext })
  }, [register, id, title, validate, validationSchema, onSubmit, shouldNext])

  if (prop('id', currentStep) !== id) {
    return null
  }

  return children
}

export { Step }
