import { defaultTo, pipe, pluck, replaceAll, test } from '@solta/ramda-extra'
import * as Validator from 'yup'
import CONSTANTS from './constants/selectFieldOptions'

const { object, string, boolean, number } = Validator

const addressSchema = object({
  propertyId: string().label('Property Id'),
  propertyAddress: string()
    .required('Please search and select a valid address')
    .label('Address'),
})

const getValues = pluck('value')
const requiredString = (msg) => string().required(msg).label('This')
const optionalString = () => string().optional().label('This')
const requiredNumber = (msg) => number().required(msg).label('This')
const requiredEmail = (msg) => string().email().required(msg).label('This')
const requiredBoolean = (msg) => boolean().required(msg).label('This')

export const validationSchema = (applicantPathId) =>
  object({
    details: object({
      [applicantPathId]: object({
        firstName: requiredString(),
        lastName: requiredString(),
        middleName: optionalString(),
        preferredName: optionalString(),
        dob: requiredString().typeError(`Please enter a valid date`),
        gender: requiredString().oneOf(getValues(CONSTANTS.GENDER_OPTIONS)),
        marital: requiredString().oneOf(getValues(CONSTANTS.MARITAL_OPTIONS)),
        numOfDependants: requiredNumber().min(0),
        livingArrangement: requiredString().oneOf(
          getValues(CONSTANTS.LIVING_ARRANGEMENT_OPTIONS)
        ),
        currentAddress: addressSchema,
        mailingAddress: addressSchema,
        email: requiredEmail('Email is required'),
        phoneNumber: requiredString('Please enter a valid phone number').test({
          test: pipe(defaultTo(''), replaceAll(' ', ''), test(/^[28]\d{7,9}$/)),
          message: 'Not a valid phone number',
        }),
        isNZPR: requiredBoolean(),
        citizenCountry: string().when('isNZPR', {
          is: false,
          then: requiredString('Please select country'),
        }),
        residentCountry: string().when('isNZPR', {
          is: false,
          then: requiredString('Please select country'),
        }),
        residencyStatus: string().when('isNZPR', {
          is: false,
          then: requiredString('Please select residency status').oneOf(
            getValues(CONSTANTS.RESIDENCY_STATUS_OPTIONS)
          ),
        }),
      }),
    }),
  })
