import {
  always,
  clone,
  defaultTo,
  drop,
  filter,
  find,
  ifElse,
  isNil,
  isNotNilOrEmpty,
  join,
  map,
  mergeDeepRight,
  omit,
  pick,
  pipe,
  trim,
  values,
} from '@solta/ramda-extra'
import * as CONSTANTS from '@vega/constants'
import { detailsViewModel } from '../initialFormValues'
import { convertISODate } from './helper'

const {
  COUNTRY_ISO,
  APPLICANT: { RESIDENCY_STATUS },
} = CONSTANTS

const removeMongoId = omit(['_id'])

const mapToDocumentsViewModel = map(({ expiryDate, files, ...otherProps }) => ({
  ...otherProps,
  expiryDate: convertISODate(expiryDate),
  files: map(removeMongoId)(files),
}))

const joinAddress = ([streetNumber = '', streetName = '', suburb = '', city = '']) => {
  const [number, ...extraInfo] = streetNumber.split(' ')
  const street = trim(`${number} ${streetName} ${join(' ', extraInfo)}`)
  return `${street}, ${suburb}, ${city}`
}

const formatAddress = pipe(
  defaultTo({}),
  pick(['streetNumber', 'streetName', 'suburb', 'city']),
  values,
  filter(isNotNilOrEmpty),
  ifElse(isNotNilOrEmpty, joinAddress, () => undefined)
)

const mapToAddressViewModel = ({ propertyId, ...propertyAddress } = {}) => ({
  propertyId: defaultTo(undefined, propertyId),
  propertyAddress: formatAddress(propertyAddress),
})

export const mapToDetailsViewModel = ({
  id,
  citizenCountry,
  residencyStatus,
  firstName,
  lastName,
  middleName,
  preferredName,
  dob,
  gender,
  marital,
  addresses = [],
  email,
  phoneNumber,
  residentCountry,
  documents,
  numOfDependants,
  livingArrangement,
}) => {
  const currentAddress = find(
    ({ addressType }) => addressType === 'currentAddress',
    addresses
  )
  const mailingAddress = find(
    ({ addressType }) => addressType === 'mailingAddress',
    addresses
  )

  const getIsNZPR = (residentCountry, residencyStatus) => {
    if (isNil(residentCountry) || isNil(residencyStatus)) return undefined
    return (
      residentCountry === COUNTRY_ISO.NZ &&
      residencyStatus === RESIDENCY_STATUS.PERMANENT_RESIDENT
    )
  }

  const removeCountryCode = ifElse(isNil, always(undefined), drop(3))
  const defaultDocuments = clone(detailsViewModel.documents)

  return {
    id,
    firstName: defaultTo(undefined, firstName),
    lastName: defaultTo(undefined, lastName),
    middleName: defaultTo(undefined, middleName),
    preferredName: defaultTo(undefined, preferredName),
    dob: defaultTo(undefined, convertISODate(dob)),
    gender: defaultTo(undefined, gender),
    marital: defaultTo(undefined, marital),
    currentAddress: mapToAddressViewModel(currentAddress),
    mailingAddress: mapToAddressViewModel(mailingAddress),
    email: defaultTo(undefined, email),
    phoneNumber: defaultTo(undefined, removeCountryCode(phoneNumber)),
    isNZPR: getIsNZPR(residentCountry, residencyStatus),
    citizenCountry: defaultTo(undefined, citizenCountry),
    residentCountry: defaultTo(undefined, residentCountry),
    residencyStatus: defaultTo(undefined, residencyStatus),
    documents: pipe(
      mergeDeepRight(defaultDocuments),
      mapToDocumentsViewModel
    )(documents),
    numOfDependants: defaultTo(0, numOfDependants),
    livingArrangement: defaultTo(undefined, livingArrangement),
  }
}
