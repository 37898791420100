/* eslint-disable no-console */
import { prop, noop, assocPath, pipe, mergeDeepRight } from '@solta/ramda-extra'
import { selectProfileId } from '@vega/redux.profile'
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { selectApplicationId } from 'modules/application'
import { selectInspectedPropertyId } from './selectors'

import { propertyService } from 'apiService'
import { normalizeError } from '@vega/services'

export const getPropertyDetails = createAsyncThunk(
  'property/getPropertyDetails',
  async (propertyId, { rejectWithValue }) => {
    try {
      const propertyDetails = await propertyService.getPropertyDetails(propertyId)

      return propertyDetails
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const addPropertyToWatchlist = createAsyncThunk(
  'property/addPropertyToWatchlist',
  async (
    { loanApplicationId, propertyId, callback = noop },
    { rejectWithValue, getState }
  ) => {
    const attachProfileIdToPropertyDetails = (loggedInUserProfileId) => (
      propertyDetails
    ) => assocPath(['createdBy', 'id'], loggedInUserProfileId, propertyDetails)

    try {
      const propertyReferenceInfo = await propertyService.addPropertyToWatchlist(
        loanApplicationId,
        propertyId
      )

      const watchlistedPropertyDetails = await propertyService.getPropertyDetails(
        prop('externalPropertyId', propertyReferenceInfo)
      )
      const loggedInUserProfileId = selectProfileId(getState())

      const propertyEntity = pipe(
        attachProfileIdToPropertyDetails(loggedInUserProfileId),
        mergeDeepRight(propertyReferenceInfo)
      )(watchlistedPropertyDetails)

      // NOTE: callback is used here since we need to go next page in the savePropertyModal after this thunk completed (since dispatch is sync)
      callback()

      return propertyEntity
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const getWatchlistedProperties = createAsyncThunk(
  'property/getWatchlistedProperties',
  async (
    { pageIndex, limit, filters: { loanApplicationId, status } },
    { rejectWithValue }
  ) => {
    try {
      const filters = {}
      if (status === 'pending') filters.isDeleted = false
      if (status === 'declined') filters.isDeleted = true

      const start = limit * pageIndex

      const { items, total } = await propertyService.getPropertyWatchlist(
        loanApplicationId,
        {
          filters,
          start,
          limit,
        }
      )

      const isFirstTimeFetching = start === 0
      return { items, total, isFirstTimeFetching }
    } catch (err) {
      const error = await err.response.json()
      return rejectWithValue(error)
    }
  }
)

export const submitProperty = createAsyncThunk(
  'property/submitProperty',
  async ({ loanApplicationId, propertyId, callback = noop }, { rejectWithValue }) => {
    try {
      const submittedProperty = await propertyService.submitProperty(
        loanApplicationId,
        propertyId
      )

      callback()

      return submittedProperty
    } catch (err) {
      const error = await err.response.json()
      return rejectWithValue(error)
    }
  }
)

export const approveProperty = createAsyncThunk(
  'property/approveProperty',
  async ({ callback = noop }, { rejectWithValue, getState }) => {
    try {
      const loanApplicationId = selectApplicationId(getState())
      const propertyId = selectInspectedPropertyId(getState())

      const result = await propertyService.approveProperty(
        loanApplicationId,
        propertyId
      )

      callback()

      return result
    } catch (err) {
      const error = await err.response.json()
      return rejectWithValue(error)
    }
  }
)

export const rejectProperty = createAsyncThunk(
  'property/rejectProperty',
  async ({ rejectReason, callback = noop }, { rejectWithValue, getState }) => {
    try {
      const loanApplicationId = selectApplicationId(getState())
      const propertyId = selectInspectedPropertyId(getState())

      const result = await propertyService.rejectProperty(
        loanApplicationId,
        propertyId,
        rejectReason
      )

      callback()

      return result
    } catch (err) {
      const error = await err.response.json()
      return rejectWithValue(error)
    }
  }
)

export const propertiesAdapter = createEntityAdapter()

const {
  selectAll: selectProperties,
  selectById: selectPropertyById,
} = propertiesAdapter.getSelectors(prop('properties'))

const initialState = propertiesAdapter.getInitialState({
  entities: {},
  ids: [],
  total: undefined,
  searchedProperty: undefined,
  inspectedPropertyId: undefined,
})

const propertiesSlice = createSlice({
  name: 'properties',
  initialState,
  reducers: {
    setInspectedPropertyId: (state, action) => {
      state.inspectedPropertyId = action.payload
    },

    clearProperties: (state) => {
      propertiesAdapter.removeAll(state)
    },
  },
  extraReducers: {
    [getPropertyDetails.fulfilled]: (state, action) => {
      const propertyDetails = action.payload
      state.searchedProperty = propertyDetails
    },

    [addPropertyToWatchlist.fulfilled]: (state, action) => {
      const propertyEntity = action.payload

      propertiesAdapter.addOne(state, propertyEntity)
      state.inspectedPropertyId = propertyEntity.id
      state.total += 1
    },

    [getWatchlistedProperties.fulfilled]: (state, action) => {
      const {
        items: watchlistedProperties,
        total,
        isFirstTimeFetching,
      } = action.payload

      if (isFirstTimeFetching) propertiesAdapter.setAll(state, watchlistedProperties)
      if (!isFirstTimeFetching) propertiesAdapter.addMany(state, watchlistedProperties)

      state.total = total
    },
  },
})

export const { clearProperties, setInspectedPropertyId } = propertiesSlice.actions

const { reducer: propertiesReducer } = propertiesSlice
export { propertiesReducer, selectProperties, selectPropertyById }
