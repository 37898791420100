import { COUNTRY_OPTIONS } from '@neo/constants'
import { pipe, find, prop, filter, isNotNilOrEmpty, join } from '@solta/ramda-extra'

const joinAddress = (address) => {
  const { streetNumber, streetName, city, countryISO } = address

  const formatStreet = (streetNumber, streetName) => {
    if (!streetName) return null
    if (!streetNumber) return streetName

    return `${streetNumber} ${streetName}`
  }

  const getMatchingCountry = find((option) => option.value === countryISO)
  const getCountry = prop('label')

  const country = pipe(getMatchingCountry, getCountry)(COUNTRY_OPTIONS)

  const street = formatStreet(streetNumber, streetName)

  const formattedAddress = pipe(
    filter(isNotNilOrEmpty),
    join(', ')
  )([street, city, country])

  return formattedAddress
}

export { joinAddress }
