import React from 'react'
import { styled, s } from '@vega/styled'
import { noop } from '@solta/ramda-extra'
import { ReactComponent as BedroomIcon } from '@vega/components/src/assets/images/bedroom.svg'
import { ReactComponent as BathroomIcon } from '@vega/components/src/assets/images/bathroom.svg'
import { ReactComponent as GarageIcon } from '@vega/components/src/assets/images/car.svg'
import { ReactComponent as BuildingSizeIcon } from '@vega/components/src/assets/images/buildingSize.svg'
import { ReactComponent as LandSizeIcon } from '@vega/components/src/assets/images/landSize.svg'
import { SquareMeterValue, Button as ButtonBase } from '@vega/components'
import formatters from 'utils/formatters'

const { formatMonetaryAmount, withEmptyPlaceholder } = formatters

const Container = styled.div(
  s('flex flex-column bg-white shadow-sm relative'),
  ({ isDeleted }) => ({
    borderRadius: '0px 0px 6px 6px',
    opacity: isDeleted ? 0.5 : 1,
    pointerEvents: isDeleted && 'none',
  })
)

const ImageContainer = styled.div(s('h-12'))
const PropertyImage = styled.img(s('w-full h-full'))

const SummaryContainer = styled.div(s('p-3'))
const Column = styled.div(s('flex flex-column'))
const Row = styled.div(s('flex items-center'))

const Address = styled.h2(s('m-0 font-normal text-xl text-grey-800'))
const Value = styled.span(s('font-medium text-grey-800'))

const Price = styled.span(s('font-semibold text-xl text-grey-800'))
const EstimatedValue = styled(Price)(s('text-xl my-1'))

const SummaryBlock = styled.div(s('flex items-center mr-6'))
const ReviewBtn = styled(ButtonBase)(s('py-2'))
const iconStyle = { marginRight: 3, width: 20, height: 20, marginTop: 3 }

function PropertyDetailsCard({
  estimatedValue,
  address,
  numOfBedrooms,
  numOfBathrooms,
  numOfCarparks,
  floorArea,
  landArea,
  imageSrc,
  isDeleted = false,
  onCardClicked = noop,
  onReviewBtnClicked = noop,
}) {
  return (
    <Container onClick={onCardClicked} isDeleted={isDeleted}>
      <ImageContainer>
        <PropertyImage src={imageSrc} />
      </ImageContainer>

      <SummaryContainer>
        <Column style={s('mb-6')}>
          <EstimatedValue>{formatMonetaryAmount(estimatedValue)}</EstimatedValue>
          <Address>{address}</Address>
        </Column>

        <Column style={s('mb-6')}>
          <Row>
            <SummaryBlock>
              <BedroomIcon style={iconStyle} />
              <Value>{withEmptyPlaceholder(numOfBedrooms)}</Value>
            </SummaryBlock>

            <SummaryBlock>
              <BathroomIcon style={iconStyle} />
              <Value>{withEmptyPlaceholder(numOfBathrooms)}</Value>
            </SummaryBlock>

            <SummaryBlock>
              <GarageIcon style={iconStyle} />
              <Value>{withEmptyPlaceholder(numOfCarparks)}</Value>
            </SummaryBlock>

            <SummaryBlock>
              <BuildingSizeIcon style={iconStyle} />
              <Value>
                <SquareMeterValue value={withEmptyPlaceholder(floorArea)} />
              </Value>
            </SummaryBlock>

            <SummaryBlock>
              <LandSizeIcon style={iconStyle} />
              <Value>
                <SquareMeterValue value={withEmptyPlaceholder(landArea)} />
              </Value>
            </SummaryBlock>
          </Row>
        </Column>

        <ReviewBtn onClick={onReviewBtnClicked}>Review</ReviewBtn>
      </SummaryContainer>
    </Container>
  )
}

export { PropertyDetailsCard }
