/* eslint-disable complexity */
import React, { useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import * as constants from '@vega/constants'

import { selectUserCurrentRole, selectProfileRoles } from '@vega/redux.profile'

import { ErrorPage } from '@vega/components'
import { generateRoutesForAuthenticatedUser } from '@neo/app-access-controller'
import { AdminRouter } from 'features/admin'
import { BrokerRouter } from 'features/broker'
import { ClientRouter } from 'features/client'
import { CreditAssessorRouter } from 'features/creditAssessor'
import { routes } from 'routes'

const { USER_ROLES } = constants
const { CLIENT, CREDIT_ASSESSOR, BROKER, SUPER_ADMIN, LENDING_ADMIN } = USER_ROLES
const { broker, client, creditAssessor, admin } = routes

const roleToRoutesControl = {
  [SUPER_ADMIN]: {
    routes: [
      { path: admin.root, Component: AdminRouter },
      { path: broker.root, Component: BrokerRouter },
      { path: client.root, Component: ClientRouter },
      { path: creditAssessor.root, Component: CreditAssessorRouter },
    ],
    rootRoute: { path: admin.root, Component: AdminRouter },
  },
  [LENDING_ADMIN]: {
    routes: [{ path: admin.root, Component: AdminRouter }],
    rootRoute: { path: admin.root, Component: AdminRouter },
  },
  [BROKER]: {
    routes: [{ path: broker.root, Component: BrokerRouter }],
    rootRoute: { path: broker.root, Component: BrokerRouter },
  },
  [CLIENT]: {
    routes: [{ path: client.root, Component: ClientRouter }],
    rootRoute: { path: client.root, Component: ClientRouter },
  },
  [CREDIT_ASSESSOR]: {
    routes: [{ path: creditAssessor.root, Component: CreditAssessorRouter }],
    rootRoute: { path: creditAssessor.root, Component: CreditAssessorRouter },
  },
}

const Router = () => {
  const roles = useSelector(selectProfileRoles) ?? []
  const userCurrentRole = useSelector(selectUserCurrentRole)
  const permittedRoutes = generateRoutesForAuthenticatedUser(roles, roleToRoutesControl)

  useEffect(() => {
    const onMessage = (event) => {
      const { data } = event
      return data
    }

    window.addEventListener('message', onMessage)

    return () => window.removeEventListener('message', onMessage)
  }, [])

  const RootUrlComponent =
    roleToRoutesControl[userCurrentRole]?.rootRoute?.Component || ErrorPage

  return (
    <Switch>
      {permittedRoutes.map((r) => (
        <Route key={r.path} path={r.path} component={r.Component} />
      ))}

      <Route exact path="/" component={RootUrlComponent} />
      <Route path="/" component={ErrorPage} />
    </Switch>
  )
}

export default Router
