import { isNotNilOrEmpty, and, isFalse, values, or, isTrue } from '@solta/ramda-extra'
import moment from 'moment'
import * as Validator from 'yup'
import { EMPLOYMENT, INCOME } from './constants'
import PROPERTY_CONSTANTS from './AssetsAndProperties/constants'
import * as CONSTANTS from '@vega/constants'

const {
  LOAN_APPLICATION: {
    INTENT: { PROPERTY_PURPOSE },
  },
} = CONSTANTS

const { object, array, string, number, boolean, mixed } = Validator

const requiredString = string().required()
const requiredNumber = number().required()

const { ROLES, NATURE, BASIS } = EMPLOYMENT

const propertySchema = object({
  propertyPurpose: requiredString
    .label('Property Purpose')
    .oneOf(values(PROPERTY_PURPOSE), 'Please select property purpose'),
  rentalIncome: mixed().when('propertyPurpose', {
    is: (propertyPurpose) => propertyPurpose === PROPERTY_PURPOSE.INVESTMENT,
    then: requiredNumber.min(0).label('Rent revenue'),
    otherwise: number().notRequired().nullable(),
  }),
  propertyAddress: object({
    propertyId: string().label('Property Id'),
    propertyAddress: string()
      .required('Please search and select a valid address')
      .label('Address'),
  }).required(),
  estimatedPropertyValue: requiredNumber.positive().label('Estimated Value'),
  ownershipStructure: requiredString
    .oneOf(
      values(PROPERTY_CONSTANTS.OWNERSHIP_TYPE),
      'Please select ownership structure'
    )
    .label('Ownership'),
  equityPercentage: mixed().when('ownershipStructure', {
    is: (ownershipStructure) =>
      or(
        ownershipStructure === PROPERTY_CONSTANTS.OWNERSHIP_TYPE.CLIENT_AND_OTHER,
        ownershipStructure === PROPERTY_CONSTANTS.OWNERSHIP_TYPE.CLIENT_AND_PARTNER
      ),
    then: requiredNumber
      .positive()
      .max(100)
      .typeError('Please enter a valid percentage')
      .label('Equity Percentage'),
    otherwise: number().notRequired().nullable(),
  }),
  isPaidOff: boolean().required().label('Is Paid Off'),
  totalLoan: mixed().when('isPaidOff', {
    is: (isPaidOff) => isFalse(isPaidOff),
    then: requiredNumber.positive().label('Total Loan'),
    otherwise: number().notRequired().nullable(),
  }),
  loanRepayment: mixed().when('isPaidOff', {
    is: (isPaidOff) => isFalse(isPaidOff),
    then: requiredNumber.positive().label('Loan Repayment'),
    otherwise: number().notRequired().nullable(),
  }),
  loanRepaymentType: mixed().when('isPaidOff', {
    is: (isPaidOff) => isFalse(isPaidOff),
    then: requiredString
      .label('Type of loan')
      .oneOf(
        values(PROPERTY_CONSTANTS.REPAYMENT_TYPE),
        'Please select loan repayment type'
      ),
    otherwise: string().notRequired().nullable(),
  }),
  intendedAsSecurity: boolean().required().label('Is Collateral of other loans'),
})

const incomeAndAssetsSchema = object().shape({
  employment: array().of(
    object({
      employmentNature: string()
        .oneOf(NATURE, 'Please select employment nature')
        .required('Please select employment nature')
        .label('Employment nature'),
      employmentBasis: string()
        .oneOf(BASIS, 'Please select employment basis')
        .required('Please select employment basis')
        .label('Employment basis'),
      employerName: requiredString.label('Employer Name'),

      inRole: boolean().required().label('Still In Role'),
      startDate: requiredString
        .when(['endDate', 'inRole'], {
          is: (endDate, inRole) => and(isNotNilOrEmpty(endDate), isFalse(inRole)),
          then: string().test(
            'is-after-endDat',
            'Start date must be before the end date',
            (startDate, context) => {
              if (isNotNilOrEmpty(startDate)) {
                const startDateISO = moment(
                  startDate,
                  'DD MM YYYY hh:mm:ss'
                ).toISOString()
                const endDateISO = moment(
                  context.parent.endDate,
                  'DD MM YYYY hh:mm:ss'
                ).toISOString()
                return moment(startDateISO).isBefore(endDateISO)
              }
              return true
            }
          ),
        })
        .label('Start date'),
      endDate: mixed()
        .when('inRole', {
          is: (inRole) => inRole,
          then: string().optional().nullable(),
          otherwise: requiredString,
        })
        .label('End date'),
      roleType: requiredString.label('Role').oneOf(ROLES, 'Please select role'),
      remunerationAmount: requiredNumber
        .positive('Please enter a valid annual salary')
        .label('Annual Salary'),
      payRecurrence: requiredString
        .oneOf(EMPLOYMENT.RECURRENCE, 'Please select payment recurrence')
        .label('Payment Frequency'),

      overtimeEligible: boolean().required().label('Overtime eligibility'),
      overtimeAmount: mixed().when('overtimeEligible', {
        is: (overtimeEligible) => overtimeEligible,
        then: requiredNumber.positive().label('Overtime amount'),
        otherwise: number().notRequired(),
      }),
      overtimePaymentRecurrence: mixed().when('overtimeEligible', {
        is: (overtimeEligible) => overtimeEligible,
        then: requiredString
          .oneOf(
            EMPLOYMENT.RECURRENCE,
            'Please select payment recurrence for overtime payment'
          )
          .label('Overtime Payment Frequency'),
        otherwise: string().notRequired(),
      }),

      typeOfAdditionalPayment: string()
        .required()
        .oneOf(
          ['commission', 'bonus', 'no'],
          'Please select type of additional payment'
        )
        .label('Commission/Bonus'),
      bonusAmount: mixed().when('typeOfAdditionalPayment', {
        is: (typeOfAdditionalPayment) => typeOfAdditionalPayment === 'bonus',
        then: requiredNumber.positive().label('Bonus Amount'),
        otherwise: number().notRequired(),
      }),
      bonusRecurrence: mixed().when('typeOfAdditionalPayment', {
        is: (typeOfAdditionalPayment) => typeOfAdditionalPayment === 'bonus',
        then: string()
          .oneOf(EMPLOYMENT.RECURRENCE, 'Please select bonus recurrence')
          .required()
          .label('Bonus Frequency'),
        otherwise: string().notRequired(),
      }),
      commissionAmount: mixed().when('typeOfAdditionalPayment', {
        is: (typeOfAdditionalPayment) => typeOfAdditionalPayment === 'commission',
        then: requiredNumber.positive().label('Commission Amount'),
        otherwise: number().notRequired(),
      }),
      commissionRecurrence: mixed().when('typeOfAdditionalPayment', {
        is: (typeOfAdditionalPayment) => typeOfAdditionalPayment === 'commission',
        then: string()
          .oneOf(EMPLOYMENT.RECURRENCE, 'Please select commission recurrence')
          .required()
          .label('Commission Frequency'),
        otherwise: string().notRequired(),
      }),
    })
  ),
  income: object({
    superannuation: object({
      eligibility: boolean().required().label('Superannuation eligibility'),
      amount: mixed().when('eligibility', {
        is: isTrue,
        then: requiredNumber
          .typeError('Please enter number')
          .positive()
          .label('Superannuation amount'),
        otherwise: number().notRequired(),
      }),
      recurrence: mixed().when('eligibility', {
        is: isTrue,
        then: requiredString
          .oneOf(INCOME.RECURRENCE, 'Please select superannuation recurrence')
          .label('Dividends recurrence'),
        otherwise: string().notRequired(),
      }),
    }),
    dividends: object({
      eligibility: boolean().required().label('Dividends eligibility'),
      amount: mixed().when('eligibility', {
        is: isTrue,
        then: requiredNumber
          .typeError('Please enter a valid dividends amount')
          .positive()
          .label('Dividends amount'),
        otherwise: number().notRequired(),
      }),
      recurrence: mixed().when('eligibility', {
        is: isTrue,
        then: requiredString
          .typeError('Please enter a valid dividends')
          .oneOf(INCOME.RECURRENCE, 'Please select dividends recurrence')
          .label('Dividends recurrence'),
        otherwise: string().notRequired(),
      }),
    }),
    governmentPayment: object({
      eligibility: boolean().required().label('Government Payment eligibility'),
      amount: mixed().when('eligibility', {
        is: isTrue,
        then: requiredNumber
          .typeError('Please enter a valid government payment amount')
          .positive('Please enter a valid government payment amount')
          .label('Government Payment amount'),
        otherwise: number().notRequired(),
      }),
      recurrence: mixed().when('eligibility', {
        is: isTrue,
        then: requiredString
          .oneOf(INCOME.RECURRENCE, 'Please select government payment recurrence')
          .label('GovernmentPayment recurrence'),
        otherwise: string().notRequired(),
      }),
    }),
    companyProfitBeforeTax: object({
      eligibility: boolean().required().label('Company Profit Before eligibility'),
      amount: mixed().when('eligibility', {
        is: isTrue,
        then: requiredNumber
          .typeError('Please enter number')
          .positive()
          .label('Company Profit Before Tax amount'),
        otherwise: number().notRequired(),
      }),
      recurrence: mixed().when('eligibility', {
        is: isTrue,
        then: requiredString
          .oneOf(
            INCOME.RECURRENCE,
            'Please select payment recurrence for company profit before tax'
          )
          .label('CompanyProfitBeforeTax recurrence'),
        otherwise: string().notRequired(),
      }),
    }),
    childSupport: object({
      eligibility: boolean().required().label('Child Support eligibility'),
      amount: mixed().when('eligibility', {
        is: isTrue,
        then: requiredNumber
          .typeError('Please enter a valid payment amount for child support')
          .positive()
          .label('Child Support amount'),
        otherwise: number().notRequired(),
      }),
      recurrence: mixed().when('eligibility', {
        is: isTrue,
        then: requiredString
          .oneOf(
            INCOME.RECURRENCE,
            'Please select payment recurrence for child support'
          )
          .label('Child Support recurrence'),
        otherwise: string().notRequired(),
      }),
    }),
    interestEarned: object({
      eligibility: boolean().required().label('Interest Earned eligibility'),
      amount: mixed().when('eligibility', {
        is: isTrue,
        then: requiredNumber
          .typeError('Please enter a valid amount of interest earned')
          .positive('Please enter a valid amount of interest earned')
          .label('Interest Earned amount'),
        otherwise: number().notRequired(),
      }),
      recurrence: mixed().when('eligibility', {
        is: isTrue,
        then: requiredString
          .oneOf(INCOME.RECURRENCE, 'Please select interest payment recurrence')
          .label('Interest Earned recurrence'),
        otherwise: string().notRequired(),
      }),
    }),
  }),
  assets: object({
    cashSavings: object({ amount: requiredNumber.positive().label('Cash') }),
    shares: object({
      eligibility: boolean().required().label('Shares eligibility'),
      amount: mixed().when('eligibility', {
        is: isTrue,
        then: requiredNumber
          .typeError('Please enter a valid shares amount')
          .positive('Please enter a valid shares amount')
          .label('Shares amount'),
        otherwise: number().notRequired(),
      }),
    }),
    property: object({
      eligibility: boolean().required().label('Properties eligibility'),
      properties: mixed().when('eligibility', {
        is: isTrue,
        then: array()
          .of(propertySchema)
          .min(1)
          .typeError('Please fill in at least one property details')
          .required(),
        otherwise: array().notRequired().nullable(),
      }),
    }),
  }),
})

export const validationSchema = (applicantPathId) =>
  object().shape({
    financials: object({
      [applicantPathId]: incomeAndAssetsSchema,
    }),
  })
