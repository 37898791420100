import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { routes } from 'routes'
import { styled, s } from '@vega/styled'
import { replace } from '@solta/ramda-extra'
import {
  getLoanApplication,
  selectGetApplicationStatus,
  selectFormProgress,
  selectApplicants,
  selectIntent,
} from 'modules/application'
import { getListOfApplicantStatuses, getOverallApplicantsStatus } from './utils'
import { selectLoanStructure } from 'modules/application/selectors'
import { ReactComponent as VegaLogo } from '@vega/components/src/assets/images/vega-logo.svg'
import {
  Title,
  Subtitle as SubtitleBase,
} from 'features/client/applications/components/typography'
import { useHideNavDrawer } from 'hooks/useHideNavDrawer'
import { LoanOptionsSummary } from './LoanOptionsSummary'
import { Button as ButtonBase, ErrorPage, Loading } from '@vega/components'
import { ASYNC_STATUSES } from '@neo/constants'

const { PENDING, REJECTED } = ASYNC_STATUSES

const Root = styled.div(
  s('w-full', {
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  })
)
const Header = styled.div(
  s('flex items-center w-full', { height: 64, paddingLeft: 64 })
)
const Container = styled.div(s('flex justify-center'))
const Content = styled.div(s('', { width: 652 }))
const Subtitle = styled(SubtitleBase)(s('text-grey-800'))
const PreviewContainer = styled.div(s('mb-6'))
const BoldText = styled.p(
  s('m-0 text-grey-800 text-xl font-semibold', { lineHeight: 1.5 })
)
const Text = styled.p(s('m-0 text-grey-800 text-base font-normal', { lineHeight: 1.5 }))
const List = styled.ul(
  s('m-0', { listStylePosition: 'outside', padding: '0px 0px 0px 20px' })
)
const ListItem = styled.li(
  s('m-0 text-grey-800 text-base font-normal', { lineHeight: 1.5 })
)
const ButtonContainer = styled.div(s('flex mt-5'))
const Button = styled(ButtonBase)(s('', { width: 148 }))
const PaddingBox = styled.div(s('pb-16'))

const StatusPreview = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()

  const getApplicationStatus = useSelector(selectGetApplicationStatus)
  const loanStructure = useSelector(selectLoanStructure)
  const intent = useSelector(selectIntent)
  const applicants = useSelector(selectApplicants)
  const formProgress = useSelector(selectFormProgress)

  // TODO: Update when primary applicant info is updated in model
  const isPrimaryApplicant = true

  const listOfApplicantStatuses = getListOfApplicantStatuses(applicants, formProgress)
  const applicationStatus = getOverallApplicantsStatus(listOfApplicantStatuses)

  useHideNavDrawer()

  const goToPropertySearch = () =>
    history.replace(
      replace(':id', id, routes.client.applications.overview.propertySearch)
    )

  const goToApplicationsList = () => history.replace(routes.client.applications.list)

  useEffect(() => {
    dispatch(getLoanApplication(id))
  }, [dispatch, id])

  if (getApplicationStatus === PENDING)
    return (
      <div style={s('w-full')}>
        <Loading style={s('m-a')} />
      </div>
    )

  if (getApplicationStatus === REJECTED) return <ErrorPage />

  return (
    <Root>
      <Header>
        <VegaLogo />
      </Header>

      <Container>
        <Content>
          <Title style={s('mb-4')}>Application status: {applicationStatus}</Title>
          <Subtitle style={s('mb-4')}>
            You have successfully submitted your details. Please see the overview of the
            application, and other applicant(s)
          </Subtitle>

          {listOfApplicantStatuses.map((applicant, index) => (
            <Subtitle key={`${applicant.name}-${index}`}>
              {index + 1}. {applicant.name}:{' '}
              <span style={s('font-semibold')}>{applicant.status}</span>
            </Subtitle>
          ))}

          <PreviewContainer>
            <LoanOptionsSummary
              loanRateType={loanStructure?.loanRateType}
              loanTerm={loanStructure?.loanTerm}
              offsetAccount={loanStructure?.offsetAccount}
              repaymentFrequency={loanStructure?.repaymentFrequency}
              borrowingAmount={intent?.borrowingAmount}
            />
          </PreviewContainer>

          {isPrimaryApplicant ? (
            <>
              <BoldText>Next step</BoldText>
              <Text>Property search:</Text>
              <List>
                <ListItem>
                  You are one step closer to your dream. You can add, and modify any
                  property you have your eyes on
                </ListItem>
              </List>

              <ButtonContainer>
                <Button style={s('mr-5')} onClick={goToPropertySearch}>
                  Property search
                </Button>
                <Button
                  variant="outlined"
                  containerStyle={s('text-grey-600 border-grey-300')}
                  onClick={goToApplicationsList}
                >
                  Do it later
                </Button>
              </ButtonContainer>
            </>
          ) : (
            <>
              <Subtitle style={s('mb-5')}>
                We will send you an email to inform you of this application&apos;s
                status, and the next step
              </Subtitle>

              <Button
                variant="outlined"
                style={{ width: 175 }}
                containerStyle={s('text-grey-600 font-semibold border-grey-300')}
                onClick={goToApplicationsList}
              >
                Back to dashboard
              </Button>
            </>
          )}
        </Content>
      </Container>
      <PaddingBox />
    </Root>
  )
}

export { StatusPreview }
