import { pipe, map, mapIndexed, reject, isNil } from '@solta/ramda-extra'

const getCircumstanceChangesModel = (allFuturePlans, applicantIds) =>
  pipe(
    removeUnspecifiedFuturePlans,
    addApplicantIdToFuturePlans(applicantIds),
    mapToCircumstanceChangesModel
  )(allFuturePlans)

function removeUnspecifiedFuturePlans(futurePlans) {
  return reject((plan) => isNil(plan?.hasAnticipatedChanges), futurePlans)
}

function addApplicantIdToFuturePlans(applicantIds) {
  return (futurePlans) =>
    mapIndexed((plan, idx) => {
      const futurePlanOwnerId = applicantIds[idx]
      return {
        ...plan,
        applicantId: futurePlanOwnerId,
      }
    }, futurePlans)
}

function mapToCircumstanceChangesModel(futurePlans) {
  return map(
    ({ hasAnticipatedChanges, anticipatedEvent, adaptationPlan, applicantId }) => {
      const circumstanceChange = {
        applicantId,
        affordabilityChanges: { event: anticipatedEvent, adaptationPlan },
      }

      const emptyCircumstanceChange = {
        applicantId,
        affordabilityChanges: { event: 'none', adaptationPlan: 'none' },
      }

      return hasAnticipatedChanges ? circumstanceChange : emptyCircumstanceChange
    },
    futurePlans
  )
}

export default getCircumstanceChangesModel
