import * as Validator from 'yup'
import { isNotNilOrEmpty, values } from '@solta/ramda-extra'
import { FILL_METHOD } from './constants'

const { METHOD, AUTO_FILL_METHOD } = FILL_METHOD

const { object, string, mixed } = Validator

const requiredString = string().required()

const financialsFillMethodSchema = (prefillDataHasBeenRetrieved) => {
  if (prefillDataHasBeenRetrieved) return undefined

  return object().shape({
    fillMethod: object({
      method: string()
        .test(function (value) {
          const { autoFillMethod } = this.parent
          if (isNotNilOrEmpty(autoFillMethod) || value === METHOD.MANUAL_FILL)
            return true
          return false
        })
        .label('Fill Method'),
      autoFillMethod: mixed()
        .oneOf(values(AUTO_FILL_METHOD))
        .when('method', {
          is: (method) => method === METHOD.AUTO_FILL,
          then: requiredString.label('Auto Fill Method'),
          otherwise: string().notRequired(),
        }),
    }),
  })
}

export const validationSchema = (applicantPathId, prefillDataHasBeenRetrieved) =>
  object().shape({
    financials: object({
      [applicantPathId]: financialsFillMethodSchema(prefillDataHasBeenRetrieved),
    }),
  })
