import React from 'react'
import { styled, s } from '@vega/styled'

import { ReactComponent as CloseIcon } from '@vega/components/src/assets/images/x.svg'
import { LinkContainer } from '@vega/components'
import { TEST_IDS } from '@neo/constants'

const Container = styled.div(s('w-full'))

const Title = styled.span(s('text-xl'))

const Header = styled.div(s('flex items-center justify-between mb-6'))

const Description = styled.p(s('text-grey-600 mb-1'))

function InvitationComplete({ invitationUrl, onClose }) {
  return (
    <Container>
      <Header>
        <Title>Your invitation has been sent!</Title>
        <CloseIcon
          data-test-id={TEST_IDS.BROKER_INVITE_CLOSE_BUTTON}
          width={20}
          onClick={onClose}
          style={{ cursor: 'pointer' }}
        />
      </Header>

      <Description>
        A link will be sent via email but you may also share this private link.
      </Description>
      <LinkContainer
        testId={TEST_IDS.BROKER_INVITE_LINK_TEXT}
        link={invitationUrl}
        style={s('mb-8')}
      />
    </Container>
  )
}

export { InvitationComplete }
