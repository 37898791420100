import React, { useEffect, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { routes } from 'routes'
import { styled, s } from '@vega/styled'
import * as CONSTANTS from '@vega/constants'
import { validationSchema } from './validationSchema'
import {
  resetForm,
  selectProgressStepStatus,
  approveApplicant,
  rejectApplicant,
  updateFormProgress,
} from 'modules/application'
import { useFormikContext } from 'formik'
import { useWizard, Wizard } from '@vega/formik-wizard'
import {
  Title,
  Subtitle,
  Prompt as PromptBase,
} from 'features/creditAssessor/applications/components/typography'
import { FormProgress } from '../../components/FormProgress/FormProgress'
import { RadioButtonField, RadioButton, FormTextarea } from '@vega/components'
import { InfoSidebar } from './InfoSidebar'
import { markProgressStepAsVisited } from 'features/creditAssessor/applications/components/FormProgress/utils'
import { BottomNavigation } from './BottomNavigation'
import { TEST_IDS } from '@neo/constants'

const {
  LOAN_APPLICATION: {
    OUTCOMES: { APPROVED, REJECTED },
  },
  FORM_PROGRESS: {
    STEP_STATUS: { COMPLETED },
    STEP_NAMES: { APPLICANT_APPROVAL },
  },
} = CONSTANTS

const Root = styled.div(s('flex-1 flex items-center w-full h-full relative'))
const ProgressContainer = styled.div(s('bg-white h-full'))

const Core = styled.div(s('flex flex-column w-full h-full'))
const Content = styled.div(
  s('flex-1 flex justify-center w-full pt-6', {
    paddingRight: 200,
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  })
)
const FormContainer = styled.div(s('flex flex-column pt-1'), {
  width: 652,
  '> :last-child': s('pb-12'),
})
const InfoContainer = styled.div(s('relative', { pointerEvents: 'none' }))
const NavigationContainer = styled.div(s('relative zIndex-2'))

const Heading = styled.p(
  s('m-0 text-xl text-grey-700 font-semibold mb-4', { lineHeight: 1.5 })
)
const Prompt = styled(PromptBase)(s('mt-6 mb-4'))
const textareaStyle = s('bg-transparent text-base', {
  borderRadius: '6px 0px 6px 6px',
  '&::placeholder': s('text-grey-400'),
})

const ApplicantApprovalStep = ({
  stepId,
  applicantId,
  applicantName,
  applicantPathId,
  index,
}) => {
  const {
    values: { applicantApproval },
  } = useFormikContext()
  const { previous, currentStep } = useWizard()

  const goBack = () => previous()

  const dispatch = useDispatch()
  const history = useHistory()

  const currentStepStatus = useSelector(
    selectProgressStepStatus(APPLICANT_APPROVAL, applicantId)
  )

  const goToApplicationList = () =>
    history.replace(routes.creditAssessor.applications.list)

  const saveAndContinue = async ({ applicantApproval }) => {
    const {
      decision,
      idVerified,
      creditCheckVerified,
      financialsVerified,
      rejectionReason,
    } = applicantApproval[applicantPathId]

    if (decision === APPROVED)
      dispatch(
        approveApplicant(
          { applicantId, applicantPathId },
          applicantApproval[applicantPathId]
        )
      )

    if (decision === REJECTED) {
      dispatch(
        rejectApplicant(
          {
            applicantId,
            idVerified,
            creditCheckVerified,
            financialsVerified,
            rejectionReason,
            applicantPathId,
          },
          applicantApproval[applicantPathId]
        )
      )
    }

    dispatch(
      updateFormProgress({
        step: APPLICANT_APPROVAL,
        status: COMPLETED,
        applicantId,
      })
    )
  }

  const saveAndExit = () => {
    dispatch(resetForm())
    goToApplicationList()
  }

  useEffect(() => {
    if (currentStep?.id === stepId)
      markProgressStepAsVisited(APPLICANT_APPROVAL, currentStepStatus, applicantId)
  }, [applicantId, currentStep, currentStepStatus, stepId])

  return (
    <Wizard.Step
      id={stepId}
      title="Applicant Approval"
      validationSchema={useMemo(() => validationSchema(applicantPathId), [
        applicantPathId,
      ])}
      onSubmit={useCallback(saveAndContinue, [])}
    >
      <Root>
        <ProgressContainer>
          <FormProgress saveAndExit={saveAndExit} />
        </ProgressContainer>

        <Core>
          <Content>
            <FormContainer>
              <Title style={s('mb-4')}>Applicant Approval</Title>

              <Subtitle style={s('mb-8')}>
                After reviewing their personal and financial status, please let us know
                your decision on this applicant&apos;s ability to apply for this loan
              </Subtitle>

              <Heading>Applicant {index + 1}</Heading>

              <Prompt style={s('mt-0')}>
                What is your decision on {applicantName}?
              </Prompt>
              <RadioButtonField
                name={`applicantApproval.${applicantPathId}.decision`}
                aria-label="decision"
                testId={TEST_IDS.ASSESSOR_APP_DECISION_RB}
              >
                <RadioButton value={APPROVED}>Approve</RadioButton>
                <RadioButton value={REJECTED}>Reject</RadioButton>
              </RadioButtonField>

              {applicantApproval[applicantPathId].decision === REJECTED && (
                <>
                  <Prompt>Did they fail because of their identity verification?</Prompt>
                  <RadioButtonField
                    name={`applicantApproval.${applicantPathId}.idVerified`}
                    aria-label="idVerified"
                  >
                    <RadioButton value={true}>Yes</RadioButton>
                    <RadioButton value={false}>No</RadioButton>
                  </RadioButtonField>

                  <Prompt>Did they fail because of their credit check?</Prompt>
                  <RadioButtonField
                    name={`applicantApproval.${applicantPathId}.creditCheckVerified`}
                    aria-label="creditCheckVerified"
                  >
                    <RadioButton value={true}>Yes</RadioButton>
                    <RadioButton value={false}>No</RadioButton>
                  </RadioButtonField>

                  <Prompt>
                    Did they fail because of their financials (income & expenses)?
                  </Prompt>
                  <RadioButtonField
                    name={`applicantApproval.${applicantPathId}.financialsVerified`}
                    aria-label="financialsVerified"
                  >
                    <RadioButton value={true}>Yes</RadioButton>
                    <RadioButton value={false}>No</RadioButton>
                  </RadioButtonField>

                  <Prompt>
                    Please let us know why you have rejected this applicant
                  </Prompt>
                  <FormTextarea
                    name={`applicantApproval.${applicantPathId}.rejectionReason`}
                    placeholder="Type your notes"
                    textareaStyle={textareaStyle}
                  />
                </>
              )}
            </FormContainer>

            <InfoContainer>
              <InfoSidebar />
            </InfoContainer>
          </Content>

          <NavigationContainer>
            <BottomNavigation goBack={goBack} canNext />
          </NavigationContainer>
        </Core>
      </Root>
    </Wizard.Step>
  )
}

export { ApplicantApprovalStep }
