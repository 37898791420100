import React, { useEffect, useMemo, useCallback } from 'react'
import {
  Title,
  SectionTitle as SecTitle,
  Subtitle,
} from 'features/broker/applications/components/typography'
import { useWizard, Wizard } from '@vega/formik-wizard'
import { useFormikContext } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import {
  createIncomeAndAssets,
  updateIncomeAndAssets,
  setHasUnexpectedError,
  resetForm,
  updateFormProgress,
  selectFormProgress,
  selectProgressStepStatus,
  selectHasUnexpectedError,
} from 'modules/application'
import { isNilOrEmpty } from '@solta/ramda-extra'
import { useHistory } from 'react-router-dom'
import { unwrapResult } from '@reduxjs/toolkit'
import { FormProgress } from '../../../components/FormProgress/FormProgress'
import { InfoSidebar } from './InfoSidebar'
import { BottomNavigation } from './BottomNavigation'
import { validationSchema } from './validationSchema'
import { routes } from 'routes'
import { Employment } from './Employment/index'
import { Income } from './Income'
import { AssetsAndProperties } from './AssetsAndProperties'
import { styled, s } from '@vega/styled'
import * as CONSTANTS from '@vega/constants'
import {
  checkStepStatus,
  markProgressStepAsVisited,
} from 'features/broker/applications/components/FormProgress/utils'
import notesParentStyle from 'features/broker/applications/CreateApplicationWizard/notesParentStyle'
import { ErrorSummary, UnexpectedError } from 'features/common/applications'
import { formSubmitErrorHandler } from './formSubmitErrorHandler'

const {
  FORM_PROGRESS: {
    STEP_STATUS: { COMPLETED },
    STEP_NAMES: { INCOME_AND_ASSETS },
  },
} = CONSTANTS

const Root = styled.div(s('flex-1 flex items-center w-full h-full'))
const ProgressContainer = styled.div(s('bg-white h-full'))

const Core = styled.div(s('flex flex-column w-full h-full'))
const Content = styled.div(
  s('flex-1 flex justify-center w-full pt-6', {
    paddingRight: 200,
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  })
)
const FormContainer = styled.div(
  s('flex flex-column pt-1'),
  {
    width: 1052,
    '> :last-child': s('pb-12'),
  },
  notesParentStyle
)
const InfoContainer = styled.div(s('relative', { pointerEvents: 'none' }))
const NavigationContainer = styled.div(s('relative zIndex-2'))

const SectionTitle = styled(SecTitle)(s('mb-5'))

function YourIncomeAndPropertiesStep({
  stepId,
  applicantName,
  applicantId,
  applicantPathId,
}) {
  const parentPath = `financials.${applicantPathId}`
  const history = useHistory()
  const dispatch = useDispatch()
  const { values, errors, submitCount, isValid, isSubmitting } = useFormikContext()
  const { previous, currentStep, currentStepIndex, next } = useWizard()

  const formProgress = useSelector(selectFormProgress)
  const hasUnexpectedError = useSelector(selectHasUnexpectedError)

  const isCurrentStepCompleted = checkStepStatus(
    formProgress,
    INCOME_AND_ASSETS,
    COMPLETED,
    applicantId
  )
  const saveAndContinue = (applicantId) => async (values) => {
    try {
      const formValues = values?.financials[applicantPathId]

      if (isCurrentStepCompleted) {
        const updateResult = await dispatch(
          updateIncomeAndAssets({ formValues, applicantId })
        )
        unwrapResult(updateResult)
      } else {
        const createResult = await dispatch(
          createIncomeAndAssets({ formValues, applicantId })
        )
        unwrapResult(createResult)
      }

      dispatch(
        updateFormProgress({ step: INCOME_AND_ASSETS, status: COMPLETED, applicantId })
      )

      next()
    } catch (error) {
      formSubmitErrorHandler(error)
    }
  }

  const goToApplicationList = () => history.replace(routes.broker.applications.list)

  const saveAndExit = (values, applicantId) => {
    const formValues = values?.financials[applicantPathId]
    dispatch(createIncomeAndAssets({ formValues, applicantId }))
    dispatch(resetForm())
    goToApplicationList()
  }

  const goBack = () => previous()

  const currentStepStatus = useSelector(
    selectProgressStepStatus(INCOME_AND_ASSETS, applicantId)
  )
  useEffect(() => {
    if (currentStep?.id === stepId)
      markProgressStepAsVisited(INCOME_AND_ASSETS, currentStepStatus, applicantId)
  }, [applicantId, currentStep, currentStepStatus, stepId])

  return (
    <Wizard.Step
      id={stepId}
      title="Your financials"
      validationSchema={useMemo(() => validationSchema(applicantPathId), [
        applicantPathId,
      ])}
      onSubmit={useCallback(saveAndContinue(applicantId), [
        applicantId,
        formProgress,
        currentStepIndex,
      ])}
    >
      <Root>
        <ProgressContainer>
          <FormProgress saveAndExit={() => saveAndExit(values, applicantId)} />
        </ProgressContainer>

        <Core>
          <Content>
            <FormContainer>
              <Title style={s('mb-4')}>Let&apos;s talk financials</Title>
              <Subtitle style={s('mb-6')}>
                We will now ask some questions about {applicantName}&apos;s financial
                position. Please prepare any banking documents, payslips, asset
                certificates, loan papers, and anything else that will help
              </Subtitle>

              <SectionTitle style={s('mb-5')}>
                First, {applicantName}&apos;s income
              </SectionTitle>
              <Employment
                applicantName={applicantName}
                parentPath={parentPath}
                applicantPathId={applicantPathId}
              />

              <SectionTitle style={s('mt-10')}>Other sources of income</SectionTitle>
              <Income
                parentPath={parentPath}
                applicantPathId={applicantPathId}
                applicantName={applicantName}
              />

              <SectionTitle style={s('mt-10')}>Assets &#38; Properties</SectionTitle>
              <Subtitle style={s('mb-4')}>
                So we can properly access {applicantName} home loan, please add the
                details of cash, assets and properties that they own.
              </Subtitle>
              <AssetsAndProperties
                applicantName={applicantName}
                parentPath={parentPath}
                applicantPathId={applicantPathId}
              />

              <ErrorSummary
                visible={submitCount > 0 && !isValid}
                errors={errors}
                style={s('mt-10 pb-0')}
              />
              <UnexpectedError
                visible={isNilOrEmpty(errors) && hasUnexpectedError}
                style={s('pb-0')}
                hideError={() => dispatch(setHasUnexpectedError(false))}
              />
              <div style={s('pb-32')} />
            </FormContainer>

            <InfoContainer>
              <InfoSidebar />
            </InfoContainer>
          </Content>

          <NavigationContainer>
            <BottomNavigation goBack={goBack} isLoading={isSubmitting} />
          </NavigationContainer>
        </Core>
      </Root>
    </Wizard.Step>
  )
}

export { YourIncomeAndPropertiesStep }
