import {
  pick,
  mapObjIndexed,
  mergeDeepRight,
  map,
  pipe,
  filter,
  isNilOrEmpty,
  defaultWhen,
  ifElse,
  isTrue,
  isNotNilOrEmpty,
  mergeAll,
  find,
} from '@solta/ramda-extra'
import { financialInfo } from 'modules/application/initialFormValues'
import { defaultOnErr } from './defaultOnErr'
import { belongTo, addMissingFields } from './helper'
import { mapToLiabilitiesViewModel } from './mapToLiabilitiesViewModel'
import { mapToIncomeViewModel } from './mapToIncomeViewModel'

// mappers
const toExpensesAccountViewModel = ({
  id,
  accountName,
  accountAmount,
  recurrence,
}) => ({
  [accountName]: {
    id,
    value: accountAmount,
    recurrence,
  },
})

const toPropertiesViewModel = ({
  id,
  propertyPurpose,
  rentalIncome,
  propertyId,
  propertyAddress,
  estimatedPropertyValue,
  ownershipStructure,
  equityPercentage,
  isPaidOff,
  totalLoan,
  loanRepayment,
  loanRepaymentType,
  intendedAsSecurity,
}) => ({
  id,
  propertyPurpose,
  rentalIncome,
  propertyId,
  propertyAddress: { propertyId, propertyAddress },
  estimatedPropertyValue,
  ownershipStructure,
  equityPercentage,
  isPaidOff,
  totalLoan,
  loanRepayment,
  loanRepaymentType,
  intendedAsSecurity,
})

const mapToPossessedAsset = (name, id, accountAmount) => () => ({
  [name]: { id, eligibility: true, amount: accountAmount },
})
const mapToUnpossessedAsset = (name, id) => () => ({
  [name]: { id, eligibility: false, amount: undefined },
})

const toOtherAssetsViewModel = ({ id, accountName, accountAmount }) => {
  const isShares = accountName === 'sharesValue'

  if (isShares) {
    return ifElse(
      isTrue,
      mapToUnpossessedAsset('shares', id),
      mapToPossessedAsset('shares', id, accountAmount)
    )(accountAmount === 0)
  }

  return { [accountName]: { id, amount: accountAmount } }
}

// other transformers

// final Income pipeline
export const groupExpensesByCategory = (applicantExpenses) => {
  const expensesType = {
    housing: ['rent', 'food', 'insurance', 'telecommunication', 'propertyMaintenance'],
    personal: ['health', 'clothing', 'entertainment', 'childcare', 'childEducation'],
    other: ['selfEducation', 'transportation'],
  }
  return mapObjIndexed((subCategories) => pick(subCategories, applicantExpenses))(
    expensesType
  )
}

// final Expenses pipeline
export const mapToExpensesViewModel = (applicantId) => (expenses) =>
  pipe(
    filter(belongTo(applicantId)),
    map(toExpensesAccountViewModel),
    mergeAll,
    groupExpensesByCategory,
    addMissingFields(financialInfo.expenses)
  )(expenses)

export const mapToPropertyViewModel = (applicantId) => (properties) => {
  const mappedProperties = pipe(
    filter(belongTo(applicantId)),
    map(toPropertiesViewModel)
  )(properties)

  const defaultProperties = financialInfo.assets.property.properties
  const hasProperties = isNotNilOrEmpty(mappedProperties)

  return {
    eligibility: hasProperties,
    properties: isNilOrEmpty(mappedProperties) ? defaultProperties : mappedProperties,
  }
}

export const mapToOtherAssetsViewModel = (applicantId) => (otherAssets) =>
  pipe(
    filter(belongTo(applicantId)),
    map(toOtherAssetsViewModel),
    mergeAll,
    defaultWhen(isNilOrEmpty, pick(['cashSavings', 'shares'], financialInfo?.assets))
  )(otherAssets)

export const mapToAssetsViewModel = (applicantId) => ({ properties, otherAssets }) => ({
  ...mapToOtherAssetsViewModel(applicantId)(otherAssets),
  property: mapToPropertyViewModel(applicantId)(properties),
})

const selectSalaryIncomeAccount = (accounts) =>
  find((income) => income?.accountName === 'salary', accounts)

const toSalaryViewModel = ({ id, accountAmount }) => ({
  id,
  amount: accountAmount,
})

const mapToSalaryViewModel = (applicantId) => (incomeAccounts) =>
  pipe(
    filter(belongTo(applicantId)),
    selectSalaryIncomeAccount,
    toSalaryViewModel
  )(incomeAccounts)

// add error handling
const makeFinancialsMappers = (applicantId, defaultValues, debug = false) => ({
  mapIncome: defaultOnErr(
    mapToIncomeViewModel(applicantId),
    defaultValues.income,
    debug
  ),
  mapExpenses: defaultOnErr(
    mapToExpensesViewModel(applicantId),
    defaultValues.expenses,
    debug
  ),
  mapAssets: defaultOnErr(
    mapToAssetsViewModel(applicantId),
    defaultValues.assets,
    debug
  ),
  mapLiabilities: mapToLiabilitiesViewModel(applicantId),
  mapSalary: defaultOnErr(
    mapToSalaryViewModel(applicantId),
    defaultValues.salary,
    debug
  ),
})

// final mapper for financials
export const mapToFinancialsViewModel = (applicantId) => (
  financials = financialInfo
) => {
  if (isNilOrEmpty(applicantId)) {
    return financialInfo
  }
  const mappers = makeFinancialsMappers(applicantId, financialInfo)

  const { income, assets, expenses, liabilities } = financials

  return mergeDeepRight(financialInfo, {
    income: mappers.mapIncome(income),
    assets: mappers.mapAssets(assets),
    expenses: mappers.mapExpenses(expenses),
    liabilities: mappers.mapLiabilities(liabilities),
    salary: mappers.mapSalary(income),
  })
}
