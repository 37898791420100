import React, { useEffect } from 'react'
import { styled, s } from '@vega/styled'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateFormProgress,
  resetForm,
  selectProgressStepStatus,
} from 'modules/application'
import { routes } from 'routes'
import { useHistory } from 'react-router-dom'
import { FormProgress } from 'features/broker/applications/components/FormProgress'
import { Wizard, useWizard } from '@vega/formik-wizard'
import { BottomNavigation } from 'features/broker/applications/components/BottomNavigation'
import { View } from './View'
import { InfoSidebar } from './InfoSidebar'
import { markProgressStepAsVisited } from 'features/broker/applications/components/FormProgress/utils'
import * as CONSTANTS from '@vega/constants'

const {
  FORM_PROGRESS: {
    STEP_STATUS: { COMPLETED },
    STEP_NAMES: { CREDIT_REPORT },
  },
} = CONSTANTS

const Root = styled.div(s('flex-1 flex items-center w-full h-full relative'))
const ProgressContainer = styled.div(s('bg-white h-full'))

const Core = styled.div(s('flex flex-column w-full h-full'))
const Content = styled.div(
  s('flex-1 flex justify-center w-full pt-6', {
    paddingRight: 200,
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  })
)
const FormContainer = styled.div(s('flex flex-column pt-1'), {
  width: 652,
  '> :last-child': s('pb-12'),
})
const InfoContainer = styled.div(s('relative', { pointerEvents: 'none' }))
const NavigationContainer = styled.div(s('relative zIndex-2'))

function CreditReportStep({ stepId, applicantId, applicantPathId }) {
  const history = useHistory()
  const dispatch = useDispatch()

  const { previous, currentStep, next } = useWizard()
  const goToApplicationList = () => history.replace(routes.broker.applications.list)

  const onNext = () => {
    dispatch(
      updateFormProgress({
        step: CREDIT_REPORT,
        status: COMPLETED,
        applicantId,
      })
    )
    next()
  }

  const saveAndExit = () => {
    dispatch(resetForm())
    goToApplicationList()
  }

  const goBack = () => previous()

  const currentStepStatus = useSelector(
    selectProgressStepStatus(CREDIT_REPORT, applicantId)
  )
  useEffect(() => {
    if (currentStep?.id === stepId)
      markProgressStepAsVisited(CREDIT_REPORT, currentStepStatus, applicantId)
  }, [applicantId, currentStep, currentStepStatus, stepId])

  return (
    <Wizard.Step id={stepId} title="Credit Report">
      <Root>
        <ProgressContainer>
          <FormProgress saveAndExit={saveAndExit} />
        </ProgressContainer>

        <Core>
          <Content>
            <FormContainer>
              <View applicantPathId={applicantPathId} applicantId={applicantId} />
            </FormContainer>

            <InfoContainer>
              <InfoSidebar />
            </InfoContainer>
          </Content>

          <NavigationContainer>
            <BottomNavigation goBack={goBack} canNext={true} onNext={onNext} />
          </NavigationContainer>
        </Core>
      </Root>
    </Wizard.Step>
  )
}

export { CreditReportStep }
