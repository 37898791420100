import * as Validator from 'yup'
import { isTrue, isNotNilOrEmpty, any } from '@solta/ramda-extra'

const { object, string, boolean, array } = Validator

export const passportRequired = object()
  .shape({
    passportNumber: string()
      .min(8)
      .required('Passport Number is required')
      .label('Passport Number'),
    countryOfOrigin: string()
      .required('Passport Country of Origin is required')
      .label('Passport Country of Origin'),
    expiryDate: string()
      .required('Passport Expiry date is required')
      .label('Passport Expiry Date'),
    files: array()
      .min(1)
      .required('Passport files is required')
      .label('Passport files'),
    isVerified: boolean()
      .test({ test: isTrue })
      .required("Please verify that your client's information is correct")
      .label('Passport Info Verification'),
  })
  .required()

export const driverLicenceRequired = object()
  .shape({
    licenceNumber: string()
      .length(8)
      .required('Driver Licence Number is required')
      .label('Driver Licence Number'),
    class: string()
      .length(1)
      .required('Driver Licence Class is required')
      .label('Driver Licence Class'),
    expiryDate: string()
      .required('Driver Licence Expiry date is required')
      .label('Driver Licence Expiry Date'),
    files: array()
      .min(1)
      .required('Driver Licence files is required')
      .label('Driver Licence files'),
    isVerified: boolean()
      .test({ test: isTrue })
      .required("Please verify that your client's information is correct")
      .label('Driver Licence Info Verification'),
  })
  .required()

const isAnyNotNilEmptyOrFalse = (...args) =>
  any((v) => isNotNilOrEmpty(v) && v !== false, args)

const passportRequiredIfPartiallyFilled = object().shape(
  {
    passportNumber: string().when(
      ['countryOfOrigin', 'expiryDate', 'files', 'isVerified'],
      {
        is: isAnyNotNilEmptyOrFalse,
        then: string()
          .min(8)
          .label('Passport Number')
          .required('Passport Number is required'),
        otherwise: string().min(8).label('Passport Number'),
      }
    ),
    countryOfOrigin: string().when(
      ['passportNumber', 'expiryDate', 'files', 'isVerified'],
      {
        is: isAnyNotNilEmptyOrFalse,
        then: string()
          .label('Passport Country of Origin')
          .required('Passport Country of Origin is required'),
        otherwise: string().label('Passport Country of Origin'),
      }
    ),
    expiryDate: string().when(
      ['passportNumber', 'countryOfOrigin', 'files', 'isVerified'],
      {
        is: isAnyNotNilEmptyOrFalse,
        then: string()
          .label('Passport Expiry Date')
          .required('Passport Expiry date is required'),
        otherwise: string().label('Passport Expiry Date'),
      }
    ),
    files: array().when(
      ['passportNumber', 'countryOfOrigin', 'expiryDate', 'isVerified'],
      {
        is: isAnyNotNilEmptyOrFalse,
        then: array()
          .min(1)
          .label('Passport files')
          .required('Passport files is required'),
        otherwise: array().label('Passport files'),
      }
    ),
    isVerified: boolean().when(
      ['passportNumber', 'countryOfOrigin', 'expiryDate', 'files'],
      {
        is: isAnyNotNilEmptyOrFalse,
        then: boolean().test({ test: isTrue }).label('Passport Info Verification'),
        otherwise: boolean().label('Passport Info Verification'),
      }
    ),
  },
  [
    ['countryOfOrigin', 'expiryDate', 'isVerified', 'files'],
    ['passportNumber', 'expiryDate', 'isVerified', 'files'],
    ['passportNumber', 'countryOfOrigin', 'isVerified', 'files'],
    ['passportNumber', 'countryOfOrigin', 'expiryDate', 'isVerified'],
    ['isVerified', 'passportNumber'],
    ['isVerified', 'countryOfOrigin'],
    ['isVerified', 'expiryDate'],
    ['isVerified', 'files'],
    ['files', 'passportNumber'],
    ['files', 'countryOfOrigin'],
    ['files', 'expiryDate'],
    ['files', 'isVerified'],
  ]
)

const driverLicenceRequiredIfPartiallyFilled = object().shape(
  {
    licenceNumber: string().when(['class', 'expiryDate', 'files', 'isVerified'], {
      is: isAnyNotNilEmptyOrFalse,
      then: string()
        .length(8)
        .required('Driver Licence Number is required')
        .label('Driver Licence Number'),
      otherwise: string().min(8).label('Driver Licence Number'),
    }),
    class: string().when(['licenceNumber', 'expiryDate', 'files', 'isVerified'], {
      is: isAnyNotNilEmptyOrFalse,
      then: string()
        .length(1)
        .required('Driver Licence Class is required')
        .label('Driver Licence Class'),
      otherwise: string().length(1).label('Driver Licence Class'),
    }),
    expiryDate: string().when(['licenceNumber', 'class', 'files', 'isVerified'], {
      is: isAnyNotNilEmptyOrFalse,
      then: string()
        .required('Driver Licence Expiry date is required')
        .label('Driver Licence Expiry Date'),
      otherwise: string().label('Driver Licence Expiry Date'),
    }),
    files: array().when(['licenceNumber', 'class', 'expiryDate', 'isVerified'], {
      is: isAnyNotNilEmptyOrFalse,
      then: array()
        .min(1)
        .label('Driver Licence files')
        .required('Driver Licence files is required'),
      otherwise: array().label('Driver Licence files'),
    }),
    isVerified: boolean().when(['licenceNumber', 'class', 'expiryDate', 'files'], {
      is: isAnyNotNilEmptyOrFalse,
      then: boolean().test({ test: isTrue }).label('Driver Licence Info Verification'),
      otherwise: boolean().label('Driver Licence Info Verification'),
    }),
  },
  [
    ['class', 'expiryDate', 'isVerified', 'files'],
    ['licenceNumber', 'expiryDate', 'isVerified', 'files'],
    ['licenceNumber', 'class', 'isVerified', 'files'],
    ['licenceNumber', 'class', 'expiryDate', 'isVerified'],
    ['isVerified', 'class'],
    ['isVerified', 'licenceNumber'],
    ['isVerified', 'expiryDate'],
    ['isVerified', 'files'],
    ['files', 'class'],
    ['files', 'licenceNumber'],
    ['files', 'expiryDate'],
    ['files', 'isVerified'],
  ]
)

export const validationSchema = (applicantType) =>
  object().shape({
    details: object().shape({
      [applicantType]: object().shape({
        documents: object().shape(
          {
            passport: object().when('driverLicence', {
              is: (v) => driverLicenceRequired.isValidSync(v),
              then: passportRequiredIfPartiallyFilled,
              otherwise: passportRequired,
            }),
            driverLicence: object().when('passport', {
              is: (v) => passportRequired.isValidSync(v),
              then: driverLicenceRequiredIfPartiallyFilled,
              otherwise: driverLicenceRequired,
            }),
          },
          [['passport', 'driverLicence']]
        ),
      }),
    }),
  })
