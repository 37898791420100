import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import ReactDOM from 'react-dom'
import './index.css'
import store from './app/store'
import { Provider } from 'react-redux'
import Router from './router'
import { AccessController } from '@neo/app-access-controller'
import { ThemeProvider, breakpoints } from '@vega/styled'
import { USER_ROLES } from '@vega/constants'
import { APPS } from '@neo/constants'

const { CLIENT, CREDIT_ASSESSOR, BROKER, SUPER_ADMIN, LENDING_ADMIN } = USER_ROLES

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={{ breakpoints }}>
        <BrowserRouter>
          <AccessController
            targetAppName={APPS.LENDING}
            permittedRoles={[
              SUPER_ADMIN,
              CLIENT,
              CREDIT_ASSESSOR,
              BROKER,
              LENDING_ADMIN,
            ]}
          >
            <Router />
          </AccessController>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
