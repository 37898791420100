import { find, pipe, prop, propEq, values, replace } from '@solta/ramda-extra'
import * as CONSTANTS from '@vega/constants'
import { useHistory } from 'react-router-dom'
import { PageHeader, Table } from '@vega/components'
import { ASYNC_STATUSES, TEST_IDS } from '@neo/constants'
import { s, styled } from '@vega/styled'
import { FormikSearchBar, TopTabFilter, Total } from 'features/common/applications'
import { usePagedSearch } from 'hooks'
import { routes } from 'routes'
import {
  getLoanApplications,
  selectGetApplicationsStatus,
  selectLoanApplications,
  selectTotal,
} from 'modules/application'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Columns, Rows } from './components'

const {
  LOAN_APPLICATION: { STAGES },
} = CONSTANTS

const Root = styled.div(
  s('flex flex-column w-full min-h-32 bg-green-50', {
    overflow: 'hidden',
  })
)

const ViewContainer = styled.div({
  height: 'calc(100% - 90px)',
})

const Container = styled.div(s('flex flex-column bg-white h-full'))

const TextSearch = styled(FormikSearchBar)(s('mr-5', { flex: 2 }))

const UpperContainer = styled.div(s('flex mb-4 mt-6 ml-6'))

const EmptyViewContainer = styled.div(s('flex flex-column items-center mt-16'))

const EmptyApplicationsLabel = styled.div(s('text-3xl text-green-700 mt-4'))

const { PENDING } = ASYNC_STATUSES

function ApplicationsList() {
  const dispatch = useDispatch()
  const history = useHistory()

  const total = useSelector(selectTotal)
  const applications = useSelector(selectLoanApplications)
  const isFetchingApps = useSelector(selectGetApplicationsStatus) === PENDING

  const tabulatedApplications = Rows(applications)
  const columns = Columns()

  const LIST_VIEW_SEARCH_LIMIT = 20

  const fetchData = (searchParams) => dispatch(getLoanApplications(searchParams))
  const {
    setters: { updateParams },
    paginationProps,
  } = usePagedSearch(fetchData, {
    total,
    pageIndex: 0,
    searchTerm: '',
    limit: LIST_VIEW_SEARCH_LIMIT,
    filters: {
      stage: [STAGES.SUBMITTED],
    },
  })

  const onTextSearch = ({ searchTerm }) => {
    updateParams({
      searchTerm,
      pageIndex: 0,
    })
  }

  const STATUSES = {
    ALL: 'all',
    IN_PROGRESS: 'in_progress',
    APPROVED: 'approved',
    FAILED: 'failed',
  }
  const statusFilters = [
    {
      value: STATUSES.ALL,
      label: 'All',
      stages: values(STAGES),
    },
  ]

  const [statusFilter, setStatusFilter] = useState(STATUSES.ALL)
  const onStageFilterSelected = (status) => {
    setStatusFilter(status)

    const stage = pipe(find(propEq('value', status)), prop('stages'))(statusFilters)

    updateParams({
      pageIndex: 0,
      filters: {
        stage,
      },
    })
  }

  const goToApplicationDetails = (id) => {
    history.push(
      replace(':id', id, routes.creditAssessor.applications.overview.personalAndFinance)
    )
  }

  return (
    <Root>
      <PageHeader title="Applications" style={{ paddingLeft: 28 }} />
      <TopTabFilter
        filters={statusFilters}
        onFilterSelected={onStageFilterSelected}
        currentFilter={statusFilter}
        style={s('pl-3')}
      />
      <ViewContainer>
        <Total total={total} style={{ right: 24, top: 66 }} />
        <Container>
          <UpperContainer style={s('mb-4')}>
            <TextSearch
              onSubmit={onTextSearch}
              placeholder={'Search broker name or client name'}
            />
          </UpperContainer>
          {tabulatedApplications.length === 0 ? (
            <EmptyViewContainer>
              <EmptyApplicationsLabel>No Applications Found</EmptyApplicationsLabel>
            </EmptyViewContainer>
          ) : (
            <Table
              data={tabulatedApplications}
              columns={columns}
              paginationProps={paginationProps}
              onClick={goToApplicationDetails}
              tBodyStyle={s('text-left')}
              style={s('ml-6 mt-0')}
              loading={isFetchingApps}
              testId={TEST_IDS.ASSESSOR_APP_TABLE}
            />
          )}
        </Container>
      </ViewContainer>
    </Root>
  )
}

export { ApplicationsList }
