import React, { useEffect, useCallback } from 'react'
import {
  Title,
  SectionTitle as SecTitle,
  Subtitle,
} from 'features/creditAssessor/applications/components/typography'
import { useWizard, Wizard } from '@vega/formik-wizard'
import { useFormikContext } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import {
  resetForm,
  updateCreditAssessorCheck,
  selectProgressStepStatus,
} from 'modules/application'
import { useHistory } from 'react-router-dom'
import { FormProgress } from '../../../components/FormProgress'
import { CreditAssessorCheckbox } from '../../../components/CreditAssessorCheckbox'
import { InfoSidebar } from './InfoSidebar'
import { BottomNavigation } from './BottomNavigation'
import { routes } from 'routes'
import { Employment } from './Employment/index'
import { Income } from './Income'
import { AssetsAndProperties } from './AssetsAndProperties'
import { styled, s } from '@vega/styled'
import { hasNoErrors } from '../utils'
import * as CONSTANTS from '@vega/constants'
import { markProgressStepAsVisited } from 'features/creditAssessor/applications/components/FormProgress/utils'
import { TEST_IDS } from '@neo/constants'

const {
  FORM_PROGRESS: {
    STEP_NAMES: { INCOME_AND_ASSETS },
  },
} = CONSTANTS

const Root = styled.div(s('flex-1 flex items-center w-full h-full relative'))
const ProgressContainer = styled.div(s('bg-white h-full'))

const Core = styled.div(s('flex flex-column w-full h-full'))
const Content = styled.div(
  s('flex-1 flex justify-center w-full pt-6', {
    paddingRight: 200,
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  })
)
const FormContainer = styled.div(s('flex flex-column pt-1'), {
  width: 652,
  '> :last-child': s('pb-12'),
})
const InfoContainer = styled.div(s('relative', { pointerEvents: 'none' }))
const NavigationContainer = styled.div(s('relative zIndex-2'))

const SectionTitle = styled(SecTitle)(s('mb-5'))

function YourIncomeAndPropertiesStep({
  stepId,
  applicantName,
  applicantId,
  applicantPathId,
}) {
  const parentPath = `financials.${applicantPathId}`
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    values: { creditAssessorCheck },
    errors,
  } = useFormikContext()

  const saveAndContinue = (values) => {
    dispatch(updateCreditAssessorCheck(values.creditAssessorCheck))
  }

  const goToApplicationList = () =>
    history.replace(routes.creditAssessor.applications.list)

  const saveAndExit = () => {
    dispatch(updateCreditAssessorCheck(creditAssessorCheck))
    dispatch(resetForm())
    goToApplicationList()
  }

  const { previous, currentStep } = useWizard()
  const goBack = () => previous()

  const currentStepProps = ['employment', 'assets', 'income']
  const canNext = hasNoErrors(currentStepProps)(errors?.financials?.[applicantPathId])

  const currentStepStatus = useSelector(
    selectProgressStepStatus(INCOME_AND_ASSETS, applicantId)
  )
  useEffect(() => {
    if (currentStep?.id === stepId)
      markProgressStepAsVisited(INCOME_AND_ASSETS, currentStepStatus, applicantId)
  }, [applicantId, currentStep, currentStepStatus, stepId])

  return (
    <Wizard.Step
      id={stepId}
      title="Your financials"
      onSubmit={useCallback(saveAndContinue, [])}
    >
      <Root>
        <ProgressContainer>
          <FormProgress saveAndExit={saveAndExit} />
        </ProgressContainer>

        <Core>
          <Content>
            <FormContainer>
              <Title style={s('mb-4')}>Let&apos;s talk financials</Title>
              <Subtitle style={s('mb-6')}>
                We will now ask some questions about {applicantName}&apos;s financial
                position. Please prepare any banking documents, payslips, asset
                certificates, loan papers, and anything else that will help
              </Subtitle>

              <SectionTitle style={s('mb-5')}>
                First, {applicantName}&apos;s income
              </SectionTitle>
              <Employment
                applicantName={applicantName}
                parentPath={parentPath}
                applicantPathId={applicantPathId}
              />

              <SectionTitle style={s('mt-10')}>Other sources of income</SectionTitle>
              <Income
                parentPath={parentPath}
                applicantPathId={applicantPathId}
                applicantName={applicantName}
              />

              <SectionTitle style={s('mt-10')}>Assets &#38; Properties</SectionTitle>
              <Subtitle style={s('mb-4')}>
                So we can properly access {applicantName} home loan, please add the
                details of cash, assets and properties that they own.
              </Subtitle>
              <AssetsAndProperties
                applicantName={applicantName}
                parentPath={parentPath}
                applicantPathId={applicantPathId}
              />

              <CreditAssessorCheckbox
                name={`creditAssessorCheck.incomeAndAssets.${applicantId}.isVerified`}
                ariaLabel="Confirm information"
                testId={TEST_IDS.ASSESSOR_APP_REVIEW_CHECKBOX}
              />
            </FormContainer>

            <InfoContainer>
              <InfoSidebar />
            </InfoContainer>
          </Content>

          <NavigationContainer>
            <BottomNavigation goBack={goBack} canNext={canNext} />
          </NavigationContainer>
        </Core>
      </Root>
    </Wizard.Step>
  )
}

export { YourIncomeAndPropertiesStep }
