import React, { useCallback } from 'react'

import { styled, s } from '@vega/styled'
import { useHistory } from 'react-router'

import { useDispatch } from 'react-redux'
import { useWizard, Wizard } from '@vega/formik-wizard'

import { InfoSidebar } from './InfoSidebar'
import { BottomNavigation } from './BottomNavigation'
import { Title, Subtitle } from 'features/broker/applications/components/typography'

import { LoanOptionsSummary } from './LoanOptionsSummary'
import { resetForm, updateFormProgress } from 'modules/application'
import { routes } from 'routes'
import { FormProgress } from '../../components/FormProgress/FormProgress'
import * as CONSTANTS from '@vega/constants'

const {
  FORM_PROGRESS: {
    STEP_STATUS: { COMPLETED },
    STEP_NAMES,
  },
} = CONSTANTS

const Root = styled.div(s('flex-1 flex items-center w-full h-full relative'))
const ProgressContainer = styled.div(s('bg-white h-full'))

const Core = styled.div(s('flex flex-column w-full h-full'))
const Content = styled.div(
  s('flex-1 flex justify-center w-full pt-6', {
    paddingRight: 200,
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  })
)
const FormContainer = styled.div(s('flex flex-column pt-1'), {
  width: 652,
  '> :last-child': s('pb-12'),
})
const InfoContainer = styled.div(s('relative', { pointerEvents: 'none' }))
const NavigationContainer = styled.div(s('relative zIndex-2'))

const PreviewContainer = styled.div(s(''), {
  '& > div': s('mb-8'),
  '& > div:last-child': s('mb-24'),
})

const LoanOptionsStep = ({ stepId }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { previous, next, currentStepIndex } = useWizard()

  const saveAndContinue = () => {
    // TODO: dispatch(updateLoanOptions(loanOptions))
    dispatch(updateFormProgress({ step: STEP_NAMES.OPTIONS, status: COMPLETED }))

    next()
  }

  const goToApplicationList = () => history.replace(routes.broker.applications.list)

  const saveAndExit = () => {
    // TODO: dispatch(updateLoanOptions(loanOptions))
    dispatch(resetForm())
    goToApplicationList()
  }

  const goBack = () => previous()

  return (
    <Wizard.Step
      id={stepId}
      title="Loan Options"
      onSubmit={useCallback(saveAndContinue, [currentStepIndex])}
    >
      <Root>
        <ProgressContainer>
          <FormProgress saveAndExit={saveAndExit} />
        </ProgressContainer>

        <Core>
          <Content>
            <FormContainer>
              <Title style={s('mb-4')}>Loan Options</Title>

              <Subtitle style={s('p-0 mb-8')}>
                Based on what you&apos;ve told us, here is a loan package we&apos;d
                recommend for you
              </Subtitle>
              <PreviewContainer>
                <LoanOptionsSummary />
              </PreviewContainer>
            </FormContainer>

            <InfoContainer>
              <InfoSidebar />
            </InfoContainer>
          </Content>

          <NavigationContainer>
            <BottomNavigation goBack={goBack} label="Next" />
          </NavigationContainer>
        </Core>
      </Root>
    </Wizard.Step>
  )
}

export { LoanOptionsStep }
