import React, { useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Formik as FormProvider, Form } from 'formik'

import { useTableFetching } from 'hooks'
import { fetchUsers, selectUserList, selectTotal } from 'modules/user'

import {
  Table,
  SearchBar,
  UserManagementFilters,
  DottedCircleLoader,
} from '@vega/components'
import { Rows, Columns } from './TableFormat'

import { styled, s } from '@vega/styled'
import { append, filter, uniq } from '@solta/ramda-extra'

import { TEST_IDS } from '@neo/constants'

const Container = styled.div(s('flex bg-white h-full', { overflow: 'hidden' }))
const FilterContainer = styled.div(s('pt-5 pl-8', { width: 230 }))
const TableContainer = styled.div(s('pt-1 pl-2 pr-4 pb-6 w-full'))

const AllUsers = () => {
  const dispatch = useDispatch()
  const [hasFirstFetchFulfilled, setHasFirstFetchFulfilled] = useState(false)

  const total = useSelector(selectTotal)
  const userList = useSelector(selectUserList)

  const columns = Columns()
  const data = Rows({ userList })

  const fetchData = useCallback(
    ({ searchParams = {}, pageIndex = 0 }) =>
      dispatch(fetchUsers({ searchParams, pageIndex })),
    [dispatch]
  )

  const {
    searchProps: { searchParams },
    updateSearchProps,
    paginationProps,
    isFetchingData,
  } = useTableFetching(total, fetchData)

  React.useEffect(() => {
    async function fetchLoanApplicationsOnMount() {
      await fetchData({
        searchParams: {
          limit: searchParams.limit,
        },
        pageIndex: 0,
      })
      setHasFirstFetchFulfilled(true)
    }

    fetchLoanApplicationsOnMount()
  }, [dispatch, fetchData, searchParams.limit])

  const addFilter = (value, currentArr) => uniq(append(value, currentArr))
  const dropFilter = (value, currentArr) => filter((v) => v !== value, currentArr)
  const pickMutator = (checked) => (checked ? addFilter : dropFilter)

  const onAccessFilter = (value, checked) => {
    updateSearchProps({
      filters: {
        role: pickMutator(checked)(value, searchParams.filters.role),
      },
    })
  }
  const onStatusFilter = (value, checked) => {
    updateSearchProps({
      filters: {
        status: pickMutator(checked)(value, searchParams.filters.status),
      },
    })
  }
  const onSuspensionFilter = (value, checked) => {
    updateSearchProps({
      filters: { suspended: checked ?? undefined },
    })
  }

  const onTextSearch = ({ searchTerm }) => {
    updateSearchProps({
      searchTerm,
    })
  }

  if (!hasFirstFetchFulfilled) {
    return (
      <div style={s('flex justify-center items-center h-full')}>
        <DottedCircleLoader size="lg" />
      </div>
    )
  }

  return (
    <Container>
      <FilterContainer>
        <FormProvider
          enableReinitialize={false}
          initialValues={{ searchTerm: '' }}
          onSubmit={onTextSearch}
        >
          {({ values, handleChange }) => {
            return (
              <Form>
                <SearchBar
                  placeholder={'Seach by name'}
                  onInputChange={handleChange}
                  value={values.searchTerm}
                  name="searchTerm"
                  id="searchTerm"
                  testId={TEST_IDS.ADMIN_USR_MANAGEMENT_SEARCH_INPUT}
                />
              </Form>
            )
          }}
        </FormProvider>
        <UserManagementFilters
          onAccessFilter={onAccessFilter}
          onStatusFilter={onStatusFilter}
          onSuspensionFilter={onSuspensionFilter}
        />
      </FilterContainer>

      <TableContainer>
        <Table
          loaderLabel="Fetching Users"
          columns={columns}
          data={data}
          loading={isFetchingData}
          fetchData={fetchData}
          paginationProps={paginationProps}
          testId={TEST_IDS.ADMIN_USR_MANAGEMENT_ALL_USR_TABLE}
        />
      </TableContainer>
    </Container>
  )
}

export default AllUsers
