import React from 'react'
import { useSelector } from 'react-redux'
import { selectIsNavDrawerOpen } from 'modules/ui'

import { TEST_IDS } from '@neo/constants'
import { s } from '@vega/styled'
import { NavigationDrawer, NavigationDrawerItem } from '@vega/components'
import { ReactComponent as HomeIcon } from '@vega/components/src/assets/images/home.svg'
import { ReactComponent as ApplicationsIcon } from '@vega/components/src/assets/images/applications.svg'
import { ReactComponent as UserIcon } from '@vega/components/src/assets/images/user-circle.svg'

import { routes } from 'routes'

function CreditAssessorNavigationDrawer() {
  const isNavDrawerOpen = useSelector(selectIsNavDrawerOpen)

  if (!isNavDrawerOpen) return null

  return (
    <NavigationDrawer homeRoute={routes.creditAssessor.overview}>
      <NavigationDrawerItem
        data-test-id={TEST_IDS.DRAWER_OVERVIEW}
        name="Overview"
        path={routes.creditAssessor.overview}
        Icon={HomeIcon}
      />
      <NavigationDrawerItem
        data-test-id={TEST_IDS.DRAWER_APPLICATIONS}
        name="Applications"
        path={routes.creditAssessor.applications.list}
        Icon={ApplicationsIcon}
      />
      <NavigationDrawerItem
        data-test-id={TEST_IDS.DRAWER_ACCOUNT}
        name="Account"
        path={routes.creditAssessor.account}
        Icon={UserIcon}
        style={s('mt-a mb-2')}
      />
    </NavigationDrawer>
  )
}

export { CreditAssessorNavigationDrawer }
