import React, { useEffect } from 'react'

import { styled, s } from '@vega/styled'
import { useHistory } from 'react-router'

import { useDispatch, useSelector } from 'react-redux'
import { useWizard, Wizard } from '@vega/formik-wizard'

import { FormProgress } from '../../components/FormProgress/FormProgress'
import { InfoSidebar } from './InfoSidebar'
import { BottomNavigation } from './BottomNavigation'
import { Title } from 'features/broker/applications/components/typography'
import {
  resetForm,
  selectCanSubmitApplication,
  selectSubmitApplicationStatus,
  selectProgressStepStatus,
} from 'modules/application'
import { routes } from 'routes'
import { ASYNC_STATUSES, TEST_IDS } from '@neo/constants'
import { selectDefaultRole } from '@vega/redux.profile'
import { CheckboxField } from '@vega/components'
import { useFormikContext } from 'formik'
import { and } from '@solta/ramda-extra'
import * as CONSTANTS from '@vega/constants'
import { markProgressStepAsVisited } from 'features/broker/applications/components/FormProgress/utils'

const {
  FORM_PROGRESS: {
    STEP_NAMES: { PRE_APPROVAL },
  },
} = CONSTANTS

const Root = styled.div(s('flex-1 flex items-center w-full h-full relative'))
const ProgressContainer = styled.div(s('bg-white h-full'))

const Core = styled.div(s('flex flex-column w-full h-full'))
const Content = styled.div(
  s('flex-1 flex justify-center w-full pt-6', {
    paddingRight: 200,
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  })
)
const FormContainer = styled.div(s('flex flex-column pt-1'), {
  width: 652,
  '> :last-child': s('pb-12'),
})
const InfoContainer = styled.div(s('relative', { pointerEvents: 'none' }))
const NavigationContainer = styled.div(s('relative zIndex-2'))

const Text = styled.p(s('m-0'))

const PreApprovalStep = ({ stepId }) => {
  const {
    values: {
      preApproval: { formHasCorrectInfo, applicantApprovesLoan },
    },
  } = useFormikContext()

  const dispatch = useDispatch()
  const history = useHistory()
  const goToApplicationList = () => history.replace(routes.broker.applications.list)
  const saveAndExit = () => {
    dispatch(resetForm())
    goToApplicationList()
  }
  const { previous, currentStep } = useWizard()
  const goBack = () => previous()
  const isSubmitting =
    useSelector(selectSubmitApplicationStatus) === ASYNC_STATUSES.PENDING

  const role = useSelector(selectDefaultRole)

  const checkboxesTicked = formHasCorrectInfo && applicantApprovesLoan
  const canNext = and(checkboxesTicked, useSelector(selectCanSubmitApplication(role)))

  const currentStepStatus = useSelector(selectProgressStepStatus(PRE_APPROVAL))
  useEffect(() => {
    if (currentStep?.id === stepId)
      markProgressStepAsVisited(PRE_APPROVAL, currentStepStatus)
  }, [currentStep, currentStepStatus, stepId])

  return (
    <Wizard.Step id={stepId} title="Your pre-approval">
      <Root>
        <ProgressContainer>
          <FormProgress saveAndExit={saveAndExit} />
        </ProgressContainer>

        <Core>
          <Content>
            <FormContainer>
              <Title>Review &amp; submit</Title>

              <Text style={s('mt-4')}>
                This is a chance for you and/or your applicant(s) to review all the
                details before submit this application. Feel free to edit/update any
                section of this application.
              </Text>

              <Text style={s('mt-8')}>Just a few more questions to go</Text>

              <CheckboxField
                name="preApproval.formHasCorrectInfo"
                testId={TEST_IDS.HAS_CORRECT_INFO_CHECKBOX}
                size="md"
                aria-label="formHasCorrectInfo"
                checkboxProps={{ style: s('mr-3') }}
                style={s('mt-6 text-grey-700')}
              >
                I confirm that all details, IDs, financials are accurately filled based
                on all documents provided by my applicant(s)
              </CheckboxField>

              <CheckboxField
                name="preApproval.applicantApprovesLoan"
                testId={TEST_IDS.APPLICANT_APPROVES_LOAN_CHECKBOX}
                size="md"
                aria-label="applicantApprovesLoan"
                checkboxProps={{ style: s('mr-3') }}
                style={s('mt-8 text-grey-700')}
              >
                I confirm that I have consulted with my applicant(s) about their goals
                and loan options. My applicant(s) is/are awared of this selection.
              </CheckboxField>
            </FormContainer>

            <InfoContainer>
              <InfoSidebar />
            </InfoContainer>
          </Content>

          <NavigationContainer>
            <BottomNavigation
              canNext={canNext}
              goBack={goBack}
              isSubmitting={isSubmitting}
              label="Submit"
            />
          </NavigationContainer>
        </Core>
      </Root>
    </Wizard.Step>
  )
}

export { PreApprovalStep }
