/* eslint-disable react/no-multi-comp */
import React from 'react'
import { isNilOrEmpty, prop, any, isTrue } from '@solta/ramda-extra'
import { PieChart as PieChartBase, DottedCircleLoader } from '@vega/components'
import { styled, s } from '@vega/styled'

const EMPTY_TOTAL_PLACEHOLDER = '-'

const Container = styled.div(
  s(
    'flex justify-between items-center bg-white rounded-lg border-1 border-solid border-grey-300 pt-5',
    {
      width: 380,
      height: 272,
    }
  )
)

const Title = styled.h2(s('m-0 text-grey-800 text-xl font-medium'))
const CategoryList = styled.ul(
  s('m-0 p-0', {
    listStyle: 'none',
    transform: 'translate(-30px, -70px)',
  }),
  prop('categoryListStyle')
)
const CategoryItem = styled.li(
  s('inline-block items-center', {
    width: 120,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  })
)

const Color = styled.div(s('inline-block rounded-sm mr-1'), ({ bgColor }) => ({
  background: bgColor,
  height: 12,
  width: 12,
  transform: 'translateY(2px)',
}))
const CategoryText = styled.span(s('text-grey-600 text-xs'))

const Column = styled.div(s('flex flex-column justify-center items-center'))

const ChartTooltip = ({ tooltipData: { label, frequency } }) => (
  <div
    style={s('text-grey-500 px-3 py-2 text-xs', {
      boxShadow:
        '0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1)',
    })}
  >
    <div style={s('mb-1')}>{label}</div>
    <div style={s('font-semibold text-grey-800')}>{frequency}</div>
  </div>
)

const TotalApplicationsIndicator = (total) => () => {
  const showEmptyTotalPlaceholder = any(isTrue)([
    isNilOrEmpty(total),
    Number.isNaN(total),
    total === 0,
  ])

  return (
    <div
      style={s(
        'absolute bg-white rounded-full flex flex-column justify-center items-center',
        {
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          height: 108,
          width: 108,
        }
      )}
    >
      <div style={s('text-grey-800 font-semibold', { fontSize: 28 })}>
        {showEmptyTotalPlaceholder ? EMPTY_TOTAL_PLACEHOLDER : total}
      </div>
      <div style={s('text-grey-600')}>Applications</div>
    </div>
  )
}

// eslint-disable-next-line react/no-multi-comp
function ApplicationPieChart({
  chartData,
  title,
  isLoading,
  total,
  showTotalApplicationsIndicator = false,
  categoryListStyle,
}) {
  const pieChartSize = 230

  if (isLoading)
    return (
      <Container style={s('flex items-center justify-center')}>
        <DottedCircleLoader size="md" />
      </Container>
    )

  return (
    <Container>
      <Column style={s('items-start')}>
        <Title style={s('ml-6')}>{title}</Title>
        <PieChartBase
          width={pieChartSize}
          height={pieChartSize}
          data={chartData}
          showPieLabel={false}
          pieStyle={{ cursor: 'pointer' }}
          tooltipContainerStyle={s('p-0')}
          tooltipOffsetY={18}
          tooltipOffsetX={-20}
          TooltipChildren={ChartTooltip}
          ExtraComponent={
            showTotalApplicationsIndicator && TotalApplicationsIndicator(total)
          }
        />
      </Column>

      <CategoryList categoryListStyle={categoryListStyle}>
        {chartData.map(({ label, frequency, color }) => (
          <CategoryItem
            key={`${label}-${frequency}-${color}`}
            style={{ marginBottom: 6 }}
          >
            <Color bgColor={color} />
            <CategoryText>{label}</CategoryText>
          </CategoryItem>
        ))}
      </CategoryList>
    </Container>
  )
}

export { ApplicationPieChart }
