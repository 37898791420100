import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { styled, s } from '@vega/styled'

import {
  getLoanApplication,
  selectApplicants,
  selectGetApplicationStatus,
  selectBrokerName,
  selectCreditAssessorCheck,
} from 'modules/application'
import { replace } from '@solta/ramda-extra'
import {
  getApplicantFullNames,
  getApplicantCheckStatus,
  getMultiStepApplicantCheckStatus,
} from './utils'
import { Header } from '../Header'
import { Button, ErrorPage, Loading } from '@vega/components'
import { ReactComponent as ArrowRight } from '@vega/components/src/assets/images/arrowRight.svg'
import { ItemVerifyStatus } from './ItemVerifyStatus'
import { ApplicantStatus } from './ApplicantStatus'
import { ASYNC_STATUSES } from '@neo/constants'
import * as CONSTANTS from '@vega/constants'
import { routes } from 'routes'

const { PENDING, REJECTED } = ASYNC_STATUSES
const {
  FORM_PROGRESS: {
    STEP_NAMES: {
      CLIENT_DETAILS,
      CLIENT_IDENTIFICATION,
      CREDIT_REPORT,
      INCOME_AND_ASSETS,
      EXPENSES_AND_LIABILITIES,
    },
  },
} = CONSTANTS

const Root = styled.div(s('flex flex-1 flex-column'))
const OverviewContainer = styled.div(s('p-8'))
const TopContainer = styled.div(s('flex mb-4'))
const TextContainer = styled.div(s(''))
const ButtonContainer = styled.div(s('flex items-center ml-a'))
const CardContainer = styled.div(s('flex'))
const CardTopContainer = styled.div(s('flex justify-between'))
const Title = styled.p(
  s('m-0 text-xl text-grey-800 font-semibold', {
    letterSpacing: '0.03em',
    lineHeight: 1.5,
  })
)
const SubTitle = styled.p(
  s('m-0 text-grey-800 text-base font-semibold', {
    letterSpacing: '0.03em',
    lineHeight: 1.5,
  })
)
const Card = styled.div(
  s('bg-white border-solid border-1 border-grey-200 rounded-lg p-5', {
    height: 173,
    width: 280,
  })
)
const Name = styled.p(s('m-0 mb-5'))
const ItemStatus = styled(ItemVerifyStatus)({ marginBottom: 14 })

const ApplicationDetails = () => {
  const { id } = useParams()
  const history = useHistory()

  const dispatch = useDispatch()

  const applicants = useSelector(selectApplicants)
  const getApplicationStatus = useSelector(selectGetApplicationStatus)
  const brokerName = useSelector(selectBrokerName)
  const creditAssessorCheck = useSelector(selectCreditAssessorCheck)

  const applicantNames = getApplicantFullNames(applicants)

  const reviewApplication = () =>
    history.push(replace(':id', id, routes.creditAssessor.applications.review))

  useEffect(() => {
    dispatch(getLoanApplication(id))
  }, [dispatch, id])

  if (getApplicationStatus === REJECTED) return <ErrorPage />

  if (getApplicationStatus === PENDING)
    return (
      <div style={s('w-full')}>
        <Loading style={s('m-a')} />
      </div>
    )

  return (
    <Root>
      <Header
        applicantNames={applicantNames}
        brokerName={brokerName}
        inspectedApplicationId={id}
      />

      <OverviewContainer>
        <TopContainer>
          <TextContainer>
            <Title style={s('mb-5')}>Applicants Details</Title>
            <SubTitle>Main Applicant(s)</SubTitle>
          </TextContainer>
          <ButtonContainer>
            <Button
              size="lg"
              EndIcon={<ArrowRight style={{ marginLeft: 10 }} />}
              style={s('ml-a w-10 p-4 text-xs')}
              onClick={reviewApplication}
            >
              Review application
            </Button>
          </ButtonContainer>
        </TopContainer>

        <CardContainer>
          {applicants &&
            applicants.map((applicant) => {
              const detailsAndIdCheckStatus = getMultiStepApplicantCheckStatus(
                applicant,
                creditAssessorCheck,
                CLIENT_DETAILS,
                CLIENT_IDENTIFICATION
              )

              const creditReportCheckStatus = getApplicantCheckStatus(
                applicant,
                creditAssessorCheck,
                CREDIT_REPORT
              )

              const incomeAndExpensesCheckStatus = getMultiStepApplicantCheckStatus(
                applicant,
                creditAssessorCheck,
                INCOME_AND_ASSETS,
                EXPENSES_AND_LIABILITIES
              )

              return (
                <Card key={applicant.id} style={s('mr-5')}>
                  <CardTopContainer>
                    <Name>
                      {applicant.firstName} {applicant.lastName}
                    </Name>
                    <ApplicantStatus status={'pending'} style={s('ml-a')} />
                  </CardTopContainer>
                  <ItemStatus status={detailsAndIdCheckStatus}>Verify IDs</ItemStatus>
                  <ItemStatus status={creditReportCheckStatus}>
                    Verify credit check
                  </ItemStatus>
                  <ItemStatus status={incomeAndExpensesCheckStatus} style={s('mb-0')}>
                    Access income and expenses
                  </ItemStatus>
                </Card>
              )
            })}
        </CardContainer>

        <SubTitle style={s('mb-4', { marginTop: 27 })}>
          Trustee(s) and Business Partner(s)
        </SubTitle>
      </OverviewContainer>
    </Root>
  )
}

export { ApplicationDetails }
