import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import TextareaAutosize from 'react-textarea-autosize'

import { ASYNC_STATUSES } from '@neo/constants'
import { Button as ButtonBase } from '@vega/components'
import { s, styled } from '@vega/styled'

import { createNote } from 'modules/application'
import { selectCreateNoteStatus } from 'modules/application/selectors'

const { PENDING, FULFILLED } = ASYNC_STATUSES

const Root = styled.div(s('flex flex-column p-3'))

const Textarea = styled(TextareaAutosize)(
  s('text-sm text-grey-800 font-normal', {
    border: 'none',
    outline: 'none',
    resize: 'none',
    '&::placeholder': s('text-sm text-grey-500 font-normal'),
  })
)
const Controls = styled.div(
  s('flex flex-row-reverse mt-2'),
  ({ visible }) => (!visible ? s('hidden') : {}),
  { cursor: 'initial' }
)

const Button = styled(ButtonBase)(
  s('text-sm p-0 font-normal text-grey-600', {
    ':disabled': s('bg-transparent'),
  })
)

const AddButton = styled(Button)(s('text-green-600'), ({ disabled }) =>
  disabled ? s('text-grey-400') : {}
)

export const AddNote = React.forwardRef(({ name, hasNotes, close }, overlayRef) => {
  const dispatch = useDispatch()

  const [expanded, setIsExpanded] = useState(!hasNotes)

  const [text, setText] = useState('')
  const addNoteStatus = useSelector(selectCreateNoteStatus)
  const isAddingNote = addNoteStatus === PENDING

  useEffect(() => {
    resetTextareaAfterNoteAdded()

    function resetTextareaAfterNoteAdded() {
      if (addNoteStatus === FULFILLED) setText('')
    }
  }, [addNoteStatus])

  function add() {
    if (text.length > 0) {
      dispatch(createNote({ formQuestionRef: name, content: text }))
    }
  }

  const textareaProps = expanded ? { minRows: 3 } : { maxRows: 1 }
  const textareaRef = React.useRef()

  useEffect(() => {
    scrollToBottomAndFocusTextareaOnExpanded()

    function scrollToBottomAndFocusTextareaOnExpanded() {
      if (expanded) {
        textareaRef.current.focus()
        overlayRef.current.scrollTop = overlayRef.current.scrollHeight
      }
    }
  }, [overlayRef, expanded])

  useEffect(() => {
    scrollToBottomAndRefocusTextareaOnNoteAdded()

    function scrollToBottomAndRefocusTextareaOnNoteAdded() {
      if (addNoteStatus === FULFILLED) {
        textareaRef.current.focus()
        overlayRef.current.scrollTop = overlayRef.current.scrollHeight
      }
    }
  }, [overlayRef, addNoteStatus])

  return (
    <Root
      onMouseLeave={() => setIsExpanded(false)}
      onMouseEnter={() => setIsExpanded(true)}
    >
      <Textarea
        placeholder={hasNotes ? 'Reply' : 'Add a note ...'}
        {...textareaProps}
        value={text}
        onChange={(e) => {
          if (!expanded) {
            setIsExpanded(true)
          }
          setText(e.target.value)
        }}
        readOnly={isAddingNote}
        ref={textareaRef}
      />
      <Controls visible={expanded}>
        <AddButton
          variant="text"
          disabled={text.length === 0 || isAddingNote}
          onClick={add}
          style={isAddingNote ? {} : s('ml-5')}
          loading={isAddingNote}
          loaderProps={{ size: 'sm', style: s('mr-2') }}
        >
          Add
        </AddButton>
        <Button variant="text" onClick={close}>
          Cancel
        </Button>
      </Controls>
    </Root>
  )
})
