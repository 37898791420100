import { prop, path } from '@solta/ramda-extra'
import { createSelector } from '@reduxjs/toolkit'
import { usersAdapter } from './module'

const selectFetchUsersStatus = path(['asyncState', 'users/fetchUsers'])

const selectUsers = prop('users')

const selectTotal = createSelector(selectUsers, prop('total'))

const selectIsFetchUsersLoading = createSelector(
  selectFetchUsersStatus,
  (status) => status === 'pending'
)

const {
  selectEntities: selectUserEntities,
  selectAll: selectUserList,
} = usersAdapter.getSelectors(prop('users'))

export { selectUserEntities, selectUserList, selectTotal, selectIsFetchUsersLoading }
