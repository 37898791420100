/* eslint-disable complexity */
import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { styled, s } from '@vega/styled'
import { useFormikContext } from 'formik'
import { useWizard, Wizard } from '@vega/formik-wizard'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  validationSchema,
  passportRequired,
  driverLicenceRequired,
} from './validationSchema'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { routes } from 'routes'
import moment from 'moment'
import {
  assocPath,
  compose,
  concat,
  path,
  prop,
  isTrue,
  all,
  isNilOrEmpty,
  isNotNilOrEmpty,
} from '@solta/ramda-extra'
import { uploadFile, deleteFile, selectUploadEntities } from '@vega/redux.upload'
import {
  selectHasUnexpectedError,
  setHasUnexpectedError,
  resetForm,
  updateFormProgress,
  updateClientIdentification,
  selectProgressStepStatus,
} from 'modules/application'

import { FormProgress } from '../../components/FormProgress/FormProgress'
import { InfoSidebar } from './InfoSidebar'
import { BottomNavigation } from './BottomNavigation'
import { StartIcon } from './StartIcon'
import { UploadFile, UploadInput } from '../../components/upload'
import {
  Title,
  Subtitle,
  Prompt as PromptBase,
} from 'features/broker/applications/components/typography'
import {
  Button as ButtonBase,
  TextField as TextFieldBase,
  DateField,
  CheckboxField,
  CountryComboBoxField,
} from '@vega/components'
import { TEST_IDS } from '@neo/constants'
import * as CONSTANTS from '@vega/constants'
import { markProgressStepAsVisited } from 'features/broker/applications/components/FormProgress/utils'
import { ErrorSummary, UnexpectedError } from 'features/common/applications'
import { formSubmitErrorHandler } from './formSubmitErrorHandler'

const {
  FORM_PROGRESS: {
    STEP_STATUS: { COMPLETED },
    STEP_NAMES: { CLIENT_IDENTIFICATION },
  },
} = CONSTANTS

const Root = styled.div(s('flex-1 flex items-center w-full h-full relative'))
const ProgressContainer = styled.div(s('bg-white h-full'))

const Core = styled.div(s('flex flex-column w-full h-full'))
const Content = styled.div(
  s('flex-1 flex justify-center w-full pt-6', {
    paddingRight: 200,
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  })
)
const FormContainer = styled.div(s('flex flex-column pt-1'), {
  width: 652,
  '> :last-child': s('pb-12'),
})
const InfoContainer = styled.div(s('relative', { pointerEvents: 'none' }))
const NavigationContainer = styled.div(s('relative zIndex-2'))

const TextField = styled(TextFieldBase)(s('mb-6'))
const Row = styled.div(s('flex justify-between'))
const Prompt = styled(PromptBase)(s('text-base mt-0 mb-4'))
const Button = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'hasError' && prop !== 'isComplete',
})(
  s('text-grey-800 border-grey p-4 h-3 mb-6'),
  ({ isComplete }) => isComplete && s('border-green-700'),
  ({ hasError }) => hasError && s('border-error-400')
)
const PaddingBox = styled.div(s('pb-16'))

const USER_CONTENTS_BUCKET = process.env.REACT_APP_USER_CONTENTS_S3_BUCKET

const UploadIdStep = ({
  applicantName,
  documents,
  applicantId,
  applicantPathId,
  stepId,
}) => {
  const {
    setFieldValue,
    isValid: isFormValid,
    dirty,
    submitCount,
    errors,
    isSubmitting,
  } = useFormikContext()
  const parentPath = `details.${applicantPathId}.documents`

  const dispatch = useDispatch()
  const history = useHistory()
  const { previous, currentStep, currentStepIndex, next } = useWizard()
  const uploadEntities = useSelector(selectUploadEntities)
  const hasUnexpectedError = useSelector(selectHasUnexpectedError)

  const [isPassportSelected, setIsPassportSelected] = useState(false)
  const [isDriverLicenceSelected, setIsDriverLicenceSelected] = useState(false)

  const passportFiles = path(['passport', 'files'], documents)
  const driverLicenceFiles = path(['driverLicence', 'files'], documents)

  const isPassportValid = passportRequired.isValidSync(prop('passport', documents))
  const isDriverLicenceValid = driverLicenceRequired.isValidSync(
    prop('driverLicence', documents)
  )

  const showPassportComplete = all(isTrue, [isPassportValid, !isPassportSelected])
  const showPassportHasError = all(isTrue, [
    !isFormValid,
    !isPassportValid,
    !isPassportSelected,
    dirty,
  ])

  const showDriverLicenceComplete = all(isTrue, [
    isDriverLicenceValid,
    !isDriverLicenceSelected,
  ])
  const showDriverLicenceHasError = all(isTrue, [
    !isFormValid,
    !isDriverLicenceValid,
    !isDriverLicenceSelected,
    dirty,
  ])

  const saveAndContinue = async () => {
    try {
      const uploadedPassportFiles = passportFiles.map(
        (file) => uploadEntities[file.key]
      )
      const uploadedDriverLicenceFiles = driverLicenceFiles.map(
        (file) => uploadEntities[file.key]
      )

      const mappedDocuments = compose(
        assocPath(['passport', 'files'], uploadedPassportFiles),
        assocPath(['driverLicence', 'files'], uploadedDriverLicenceFiles)
      )(documents)

      const updateIdResult = await dispatch(
        updateClientIdentification({ applicantId, documents: mappedDocuments })
      )
      unwrapResult(updateIdResult)

      dispatch(
        updateFormProgress({
          step: CLIENT_IDENTIFICATION,
          status: COMPLETED,
          applicantId,
        })
      )

      next()
    } catch (error) {
      formSubmitErrorHandler(error)
    }
  }

  const goToApplicationList = () => history.replace(routes.broker.applications.list)

  const saveAndExit = () => {
    dispatch(updateClientIdentification(documents))
    dispatch(resetForm())
    goToApplicationList()
  }

  const onPassportUpload = (files) => {
    const mappedFiles = files.map((file) => ({
      key: `${moment().unix()}-${prop('name', file)}`,
      fileName: prop('name', file),
      body: file,
    }))

    mappedFiles.forEach((file) =>
      dispatch(uploadFile({ bucket: USER_CONTENTS_BUCKET, file, requestId: file.key }))
    )

    const newFiles = concat(passportFiles, mappedFiles)

    setFieldValue(`${parentPath}.passport.files`, newFiles)
  }

  const onDriverLicenceUpload = (files) => {
    const mappedFiles = files.map((file) => ({
      key: `${moment().unix()}-${prop('name', file)}`,
      fileName: prop('name', file),
      body: file,
    }))

    mappedFiles.forEach((file) =>
      dispatch(uploadFile({ bucket: USER_CONTENTS_BUCKET, file, requestId: file.key }))
    )

    const newFiles = concat(driverLicenceFiles, mappedFiles)

    setFieldValue(`${parentPath}.driverLicence.files`, newFiles)
  }

  const removePassportFile = (key) => {
    const newFiles = passportFiles.filter((file) => file.key !== key)

    dispatch(deleteFile({ bucket: USER_CONTENTS_BUCKET, key }))
    setFieldValue(`${parentPath}.passport.files`, newFiles)
  }

  const removeDriverLicenceFile = (key) => {
    const newFiles = driverLicenceFiles.filter((file) => file.key !== key)

    dispatch(deleteFile({ bucket: USER_CONTENTS_BUCKET, key }))
    setFieldValue(`${parentPath}.driverLicence.files`, newFiles)
  }

  const goBack = () => previous()

  const currentStepStatus = useSelector(
    selectProgressStepStatus(CLIENT_IDENTIFICATION, applicantId)
  )
  useEffect(() => {
    if (currentStep?.id === stepId && isNotNilOrEmpty(applicantId))
      markProgressStepAsVisited(CLIENT_IDENTIFICATION, currentStepStatus, applicantId)
  }, [applicantId, currentStep, currentStepStatus, stepId])

  useEffect(() => {
    expandFormOnSubmissionErrors()

    function expandFormOnSubmissionErrors() {
      if (submitCount > 0 && !isFormValid) {
        setIsDriverLicenceSelected(true)
        setIsPassportSelected(true)
      }
    }
  }, [submitCount, isFormValid])

  return (
    <Wizard.Step
      id={stepId}
      title="Client Identification"
      validationSchema={useMemo(() => validationSchema(applicantPathId), [
        applicantPathId,
      ])}
      onSubmit={useCallback(saveAndContinue, [
        applicantId,
        uploadEntities,
        documents,
        currentStepIndex,
      ])}
    >
      <Root>
        <ProgressContainer>
          <FormProgress saveAndExit={saveAndExit} />
        </ProgressContainer>

        <Core>
          <Content>
            <FormContainer>
              <Title style={s('mb-4')}>Verify {applicantName}&apos;s ID</Title>

              <Subtitle style={s('mb-8')}>
                Before we move on to the next step, we need to verify {applicantName}
                &apos;s details. Please provide at least one of the following forms of
                ID. Uploading more than one form of ID may help the success of their
                application
              </Subtitle>

              <Button
                type="button"
                isComplete={showPassportComplete}
                hasError={showPassportHasError}
                variant={isPassportSelected ? 'contained' : 'outlined'}
                StartIcon={
                  <StartIcon
                    isComplete={showPassportComplete}
                    hasError={showPassportHasError}
                  />
                }
                data-test-id={TEST_IDS.PASSPORT_BUTTON}
                onClick={() => setIsPassportSelected(!isPassportSelected)}
              >
                New Zealand / Overseas Passport
              </Button>

              {isPassportSelected && (
                <>
                  <TextField
                    name={`${parentPath}.passport.passportNumber`}
                    aria-label="passportNumber"
                    label="Passport Number"
                    placeholder="Passport Number"
                    testId={TEST_IDS.PASSPORT_NUMBER_INPUT}
                  />

                  <Row>
                    <CountryComboBoxField
                      name={`${parentPath}.passport.countryOfOrigin`}
                      aria-label="passportCountryOrigin"
                      label="Country of Origin"
                      placeholder="Country of Origin"
                      style={s('mr-3 w-1/2', { height: 55 })}
                      labelStyles={{ style: s('mb-4') }}
                      testId={TEST_IDS.PASSPORT_COUNTRY_INPUT}
                    />

                    <DateField
                      name={`${parentPath}.passport.expiryDate`}
                      aria-label="passportExpiryDate"
                      label="Expiry Date"
                      containerProps={{ style: s('ml-3 w-1/2') }}
                      placeholder="DD/MM/YYYY"
                      testId={TEST_IDS.PASSPORT_EXPIRY_INPUT}
                    />
                  </Row>

                  <Prompt style={s('mt-6')}>Upload a digital copy</Prompt>

                  {passportFiles.map((file) => (
                    <UploadFile
                      key={file.key}
                      fileKey={file.key}
                      fileName={file.fileName}
                      onRemove={() => removePassportFile(file.key)}
                    />
                  ))}

                  <UploadInput
                    name={`${parentPath}.passport.files`}
                    onUpload={onPassportUpload}
                    testId={TEST_IDS.PASSPORT_UPLOAD_BUTTON}
                  />

                  <CheckboxField
                    name={`${parentPath}.passport.isVerified`}
                    size="md"
                    aria-label="passportCorrectInfo"
                    style={s('mt-8')}
                    containerProps={{ style: s('mb-10') }}
                    testId={TEST_IDS.PASSPORT_VERIFY_CHECKBOX}
                  >
                    I have read and agree to the broker non-disclosure agreement, and
                    verify {applicantName}&apos;s ID is matched and correct
                  </CheckboxField>
                </>
              )}

              <Button
                type="button"
                isComplete={showDriverLicenceComplete}
                hasError={showDriverLicenceHasError}
                variant={isDriverLicenceSelected ? 'contained' : 'outlined'}
                StartIcon={
                  <StartIcon
                    isComplete={showDriverLicenceComplete}
                    hasError={showDriverLicenceHasError}
                  />
                }
                onClick={() => setIsDriverLicenceSelected(!isDriverLicenceSelected)}
                data-test-id={TEST_IDS.LICENCE_BUTTON}
              >
                New Zealand Driver Licence
              </Button>

              {isDriverLicenceSelected && (
                <>
                  <TextField
                    name={`${parentPath}.driverLicence.licenceNumber`}
                    aria-label="DriverLicenceNumber"
                    label="Driver Licence Number"
                    placeholder="Driver Licence Number"
                    testId={TEST_IDS.LICENCE_NUMBER_INPUT}
                  />

                  <Row>
                    <TextField
                      name={`${parentPath}.driverLicence.class`}
                      aria-label="DriverLicenceClass"
                      label="Class"
                      style={s('mr-3 w-1/2')}
                      placeholder="Class"
                      testId={TEST_IDS.LICENCE_CLASS_INPUT}
                    />

                    <DateField
                      name={`${parentPath}.driverLicence.expiryDate`}
                      aria-label="DriverLicenceExpiryDate"
                      label="Expiry Date"
                      containerProps={{ style: s('ml-3 w-1/2') }}
                      placeholder="DD/MM/YYYY"
                      labelProps={{ style: s('mb-1') }}
                      testId={TEST_IDS.LICENCE_EXPIRY_INPUT}
                    />
                  </Row>

                  <Prompt>Upload a digital copy</Prompt>

                  {driverLicenceFiles.map((file) => (
                    <UploadFile
                      key={file.key}
                      fileKey={file.key}
                      fileName={file.fileName}
                      onRemove={() => removeDriverLicenceFile(file.key)}
                    />
                  ))}

                  <UploadInput
                    name={`${parentPath}.driverLicence.files`}
                    onUpload={onDriverLicenceUpload}
                    testId={TEST_IDS.LICENCE_UPLOAD_BUTTON}
                  />

                  <CheckboxField
                    name={`${parentPath}.driverLicence.isVerified`}
                    size="md"
                    aria-label="DriverLicenceCorrectInfo"
                    style={s('mt-8')}
                    testId={TEST_IDS.LICENCE_VERIFY_CHECKBOX}
                  >
                    I have read and agree to the broker non-disclosure agreement, and
                    verify {applicantName}&apos;s ID is matched and correct
                  </CheckboxField>
                </>
              )}

              <PaddingBox />
              <ErrorSummary
                errors={errors}
                visible={submitCount > 0 && !isFormValid}
                style={s('pb-0')}
              />
              <UnexpectedError
                visible={isNilOrEmpty(errors) && hasUnexpectedError}
                hideError={() => dispatch(setHasUnexpectedError(false))}
                style={s('p-0')}
              />
              <div style={s('mb-32')} />
            </FormContainer>

            <InfoContainer>
              <InfoSidebar />
            </InfoContainer>
          </Content>

          <NavigationContainer>
            <BottomNavigation goBack={goBack} isLoading={isSubmitting} />
          </NavigationContainer>
        </Core>
      </Root>
    </Wizard.Step>
  )
}

export { UploadIdStep }
