import React from 'react'
import { s } from '@vega/styled'
import { TEST_IDS } from '@neo/constants'
import { useSelector } from 'react-redux'
import { selectIsNavDrawerOpen } from 'modules/ui'

import { NavigationDrawer, NavigationDrawerItem } from '@vega/components'
import { ReactComponent as HomeIcon } from '@vega/components/src/assets/images/home.svg'
import { ReactComponent as PeopleIcon } from '@vega/components/src/assets/images/people.svg'
import { ReactComponent as ChartIcon } from '@vega/components/src/assets/images/chart.svg'
import { ReactComponent as UserIcon } from '@vega/components/src/assets/images/user-circle.svg'
import { ReactComponent as CalculatorIcon } from '@vega/components/src/assets/images/calculator.svg'
import { ReactComponent as ApplicationsIcon } from '@vega/components/src/assets/images/applications.svg'

function BrokerNavigationDrawer() {
  const prefixRoute = '/broker'

  const isNavDrawerOpen = useSelector(selectIsNavDrawerOpen)

  if (!isNavDrawerOpen) return null

  return (
    <NavigationDrawer homeRoute={`${prefixRoute}/overview`}>
      <NavigationDrawerItem
        data-test-id={TEST_IDS.DRAWER_OVERVIEW}
        name="Overview"
        path={`${prefixRoute}/overview`}
        Icon={HomeIcon}
      />
      <NavigationDrawerItem
        data-test-id={TEST_IDS.DRAWER_CLIENTS}
        name="Clients"
        path={`${prefixRoute}/clients`}
        Icon={PeopleIcon}
      />
      <NavigationDrawerItem
        data-test-id={TEST_IDS.DRAWER_APPLICATIONS}
        name="Applications"
        path={`${prefixRoute}/applications`}
        Icon={ApplicationsIcon}
      />
      <NavigationDrawerItem
        data-test-id={TEST_IDS.DRAWER_REPORTS}
        name="Reports"
        path={`${prefixRoute}/reports`}
        Icon={ChartIcon}
      />
      <NavigationDrawerItem
        data-test-id={TEST_IDS.DRAWER_CALCULATOR}
        name="Calculator"
        path={`${prefixRoute}/calculator`}
        Icon={CalculatorIcon}
      />
      <NavigationDrawerItem
        data-test-id={TEST_IDS.DRAWER_ACCOUNT}
        name="Account"
        path={`${prefixRoute}/account`}
        Icon={UserIcon}
        style={s('mt-a mb-2')}
      />
    </NavigationDrawer>
  )
}

export { BrokerNavigationDrawer }
