import {
  defaultTo,
  defaultWhen,
  find,
  identity,
  isNil,
  isNilOrEmpty,
  map,
  mapIndexed,
  mergeAll,
  omit,
  pipe,
  prop,
  propEq,
  range,
} from '@solta/ramda-extra'
import initialFormValues, {
  detailsViewModel,
  financialInfo,
  applicantApprovalTemplate,
} from 'modules/application/initialFormValues'
import { defaultOnErr } from './defaultOnErr'
import { convertISODate } from './helper'
import { mapToCreditReportViewModel } from './mapToCreditReportViewModel'
import { mapToFillMethodViewModel } from './mapToFillMethodViewModel'
import { mapToFinancialsViewModel } from './mapToFinancialsViewModel'
import { mapToFuturePlanViewModel } from './mapToFuturePlanViewModel'
import { mapToDetailsViewModel } from './mapToDetailsViewModel'
import { mapToCreditAssessorCheckViewModel } from './mapToCreditAssessorCheckViewModel'
import * as CONSTANTS from '@vega/constants'

const {
  LOAN_APPLICATION: {
    OUTCOMES: { APPROVED },
  },
} = CONSTANTS

export const mapToInitialStatus = identity

export const mapToInitialCreditAssessorCheck = (creditAssessorCheck) =>
  mapToCreditAssessorCheckViewModel(creditAssessorCheck)

export const mapToInitialIntent = ({
  loanPurpose,
  propertyPurpose,
  estimatedPropertyValue,
  borrowingAmount,
  expectedRentAmount,
}) => ({
  loanPurpose: defaultTo(undefined, loanPurpose),
  propertyPurpose: defaultTo(undefined, propertyPurpose),
  estimatedPropertyValue: defaultTo(undefined, estimatedPropertyValue),
  borrowingAmount: defaultTo(undefined, borrowingAmount),
  expectedRentAmount: defaultTo(undefined, expectedRentAmount),
})

export const mapToInitialStructure = ({
  loanFlexibilityPreference,
  loanRateType,
  fixedLoanRateTerm,
  repaymentType,
  repaymentFrequency,
  loanTerm,
  offsetAccount,
}) => ({
  loanFlexibilityPreference: defaultTo(1, loanFlexibilityPreference),
  loanRateType: defaultTo(undefined, loanRateType),
  fixedLoanRateTerm: defaultTo(undefined, fixedLoanRateTerm),
  repaymentType: defaultTo(undefined, repaymentType),
  repaymentFrequency: defaultTo(undefined, repaymentFrequency),
  maxLoanTerm: loanTerm ? loanTerm === 30 : undefined,
  loanTerm: defaultTo(undefined, loanTerm),
  offsetAccount: defaultTo(undefined, offsetAccount),
})

const deduceAdditionalPaymentData = (
  bonusEligible,
  commissionEligible,
  bonusAmount,
  commissionAmount,
  bonusRecurrence,
  commissionRecurrence
) => {
  if (bonusEligible)
    return {
      typeOfAdditionalPayment: 'bonus',
      bonusAmount,
      bonusRecurrence,
    }
  if (commissionEligible)
    return {
      typeOfAdditionalPayment: 'commission',
      commissionAmount,
      commissionRecurrence,
    }
  return {
    typeOfAdditionalPayment: 'no',
    bonusAmount: undefined,
    bonusRecurrence: undefined,
    commissionAmount: undefined,
    commissionRecurrence: undefined,
  }
}

const toEmploymentViewModel = map(
  ({
    inRole,
    endDate,
    startDate,
    bonusEligible,
    commissionEligible,
    bonusAmount,
    commissionAmount,
    bonusRecurrence,
    commissionRecurrence,
    ...otherProps
  }) => {
    const {
      typeOfAdditionalPayment,
      ...paymentAmountAndRecurrence
    } = deduceAdditionalPaymentData(
      bonusEligible,
      commissionEligible,
      bonusAmount,
      commissionAmount,
      bonusRecurrence,
      commissionRecurrence
    )

    return {
      ...otherProps,
      bonusEligible,
      commissionEligible,
      typeOfAdditionalPayment,
      ...paymentAmountAndRecurrence,
      inRole,
      startDate: convertISODate(startDate),
      endDate: inRole ? undefined : convertISODate(endDate),
    }
  }
)
const findApplicant = (applicantId) => find(propEq('id', applicantId))
export const mapToEmploymentViewModel = (applicantId) => (applicants) =>
  pipe(
    findApplicant(applicantId),
    prop('employment'),
    defaultOnErr(toEmploymentViewModel, financialInfo.employment)
  )(applicants)

export const mapToInitialFinancials = (
  financials,
  financialsMetadata,
  applicants,
  circumstanceChanges,
  creditReports
) =>
  map((applicantId) => {
    const financialsViewModel = mapToFinancialsViewModel(applicantId)(financials)
    const employment = mapToEmploymentViewModel(applicantId)(applicants)
    const fillMethod = mapToFillMethodViewModel(applicantId)(financialsMetadata)
    const futurePlan = mapToFuturePlanViewModel(applicantId)(circumstanceChanges)
    const creditReport = mapToCreditReportViewModel(applicantId)(creditReports)

    return {
      ...financialsViewModel,
      employment,
      fillMethod,
      futurePlan,
      creditReport,
    }
  })

const getNumOfCoApplicants = (numOfApplicants) => {
  return isNil(numOfApplicants) ? 0 : numOfApplicants - 1
}

const toApplicantsObject = pipe(
  mapIndexed((mappedModel, idx) => ({
    [`applicant${idx + 1}`]: mappedModel,
  })),
  mergeAll
)

const addMissingDetailsModels = (numOfApplicants) => (models = []) => {
  if (numOfApplicants <= models.length) return models
  const numOfMissingModels = numOfApplicants - models.length
  range(0, numOfMissingModels).forEach(() => models.push(detailsViewModel))
  return models
}

const mapToInitialDetails = (numOfApplicants) => (details) =>
  pipe(
    map(mapToDetailsViewModel),
    addMissingDetailsModels(numOfApplicants),
    toApplicantsObject
  )(details)

const mapToInitialPreApproval = ({
  brokerConfirmedDetailsCorrect,
  brokerConfirmedLoanWithClient,
  applicants,
}) => ({
  formHasCorrectInfo: brokerConfirmedDetailsCorrect,
  applicantApprovesLoan: brokerConfirmedLoanWithClient,
  applicants,
})

const sortAssessments = (applicantIds) => (assessments) => {
  const sortedAssessments = []
  applicantIds.map((id) => {
    const match = assessments.find((assessment) => assessment.applicantId === id)
    return !match ? sortedAssessments.push(null) : sortedAssessments.push(match)
  })
  return sortedAssessments
}

export const mapToInitialApplicantApproval = (model) => {
  if (!model) return applicantApprovalTemplate
  const newModel = omit(['applicantId', 'assessedBy'], model)
  if (newModel.decision === APPROVED)
    return {
      decision: APPROVED,
      idVerified: undefined,
      creditCheckVerified: undefined,
      financialsVerified: undefined,
      rejectionReason: undefined,
    }
  return newModel
}

export const mapToInitialFormValues = defaultOnErr((loanApplicationData) => {
  const {
    loanStructure,
    financials,
    creditReports,
    loanApplication: {
      intent,
      status,
      circumstanceChanges,
      financialsMetadata,
      applicantsDetails,
      creditAssessorCheck,
      consent,
      assessment: { applicants: assessedApplicants },
    },
  } = loanApplicationData

  const applicantIds = map(prop('id'))(applicantsDetails)
  const mapEachApplicantFinancials = mapToInitialFinancials(
    financials,
    financialsMetadata,
    applicantsDetails,
    circumstanceChanges,
    creditReports
  )
  const numOfApplicants = applicantsDetails.length

  return {
    details: defaultOnErr(
      mapToInitialDetails(numOfApplicants),
      initialFormValues.details
    )(applicantsDetails),

    financials: defaultOnErr(
      pipe(mapEachApplicantFinancials, toApplicantsObject),
      initialFormValues.financials
    )(applicantIds),

    intent: defaultOnErr(mapToInitialIntent, initialFormValues.intent)(intent),

    structure: defaultOnErr(
      mapToInitialStructure,
      initialFormValues.structure
    )(loanStructure),

    status: pipe(
      defaultWhen(isNilOrEmpty, initialFormValues.status),
      mapToInitialStatus
    )(status),

    creditAssessorCheck: defaultOnErr(
      mapToInitialCreditAssessorCheck,
      initialFormValues.creditAssessorCheck
    )(creditAssessorCheck),

    numOfCoApplicants: getNumOfCoApplicants(numOfApplicants),

    preApproval: defaultOnErr(
      mapToInitialPreApproval,
      initialFormValues.preApproval
    )(consent),

    invitations: initialFormValues.invitations,

    applicantApproval: pipe(
      sortAssessments(applicantIds),
      map(mapToInitialApplicantApproval),
      toApplicantsObject
    )(assessedApplicants),
  }
}, initialFormValues)
