import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  resetForm,
  selectProgressStepStatus,
  updateCreditAssessorCheck,
} from 'modules/application'
import { useWizard, Wizard } from '@vega/formik-wizard'
import { useHistory } from 'react-router-dom'
import { useFormikContext } from 'formik'
import { styled, s } from '@vega/styled'

import { routes } from 'routes'
import { BottomNavigation } from './BottomNavigation'
import { FormProgress } from '../../components/FormProgress'
import { InfoSidebar } from './InfoSidebar'
import { Prompt, Title, Subtitle } from './typography'

import {
  // FlexibilityLevel,
  // LoanFlexibilityPreferenceField,
  RadioButtonField,
  RadioButton,
} from '@vega/components'

import * as CONSTANTS from '@vega/constants'
// import FixedOptions from './FixedOptions'
import FloatingOptions from './FloatingOptions'
// import FixedAndFloatingOptions from './FixedAndFloatingOptions'
import LoanTerms from './LoanTerms'
import { CreditAssessorCheckbox } from '../../components/CreditAssessorCheckbox'

import { TEST_IDS } from '@neo/constants'
import { markProgressStepAsVisited } from 'features/creditAssessor/applications/components/FormProgress/utils'

const {
  FORM_PROGRESS: {
    STEP_NAMES: { OPTIONS },
  },
} = CONSTANTS

const Root = styled.div(s('flex-1 flex items-center w-full h-full relative'))
const ProgressContainer = styled.div(s('bg-white h-full'))

const Core = styled.div(s('flex flex-column w-full h-full'))
const Content = styled.div(
  s('flex-1 flex justify-center w-full pt-6', {
    paddingRight: 200,
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  })
)
const FormContainer = styled.div(s('flex flex-column pt-1'), {
  width: 652,
  '> :last-child': s('pb-12'),
})
const InfoContainer = styled.div(s('relative', { pointerEvents: 'none' }))
const NavigationContainer = styled.div(s('relative zIndex-2'))

function YourOptionsStep({ stepId }) {
  const dispatch = useDispatch()
  const history = useHistory()

  const saveAndContinue = (values) => {
    dispatch(updateCreditAssessorCheck(values.creditAssessorCheck))
  }

  const {
    values: { details, structure, creditAssessorCheck },
  } = useFormikContext()

  const loanRateType = structure?.loanRateType
  const applicantName = details?.applicant1?.firstName || 'your client'

  const goToApplicationList = () =>
    history.replace(routes.creditAssessor.applications.list)

  const saveAndExit = () => {
    dispatch(updateCreditAssessorCheck(creditAssessorCheck))
    dispatch(resetForm())
    goToApplicationList()
  }

  const { previous, currentStep } = useWizard()
  const goBack = () => previous()

  const renderLoanOptions = (type) => {
    return {
      // fixed: <FixedOptions applicantName={applicantName} />,
      floating: <FloatingOptions applicantName={applicantName} />,
      // eslint-disable-next-line camelcase
      // fixed_and_floating: <FixedAndFloatingOptions applicantName={applicantName} />,
    }[type]
  }

  const currentStepStatus = useSelector(selectProgressStepStatus(OPTIONS))
  useEffect(() => {
    if (currentStep?.id === stepId)
      markProgressStepAsVisited(OPTIONS, currentStepStatus)
  }, [currentStep, currentStepStatus, stepId])

  return (
    <Wizard.Step
      id={stepId}
      title="Your options"
      onSubmit={useCallback(saveAndContinue, [])}
    >
      <Root>
        <ProgressContainer>
          <FormProgress saveAndExit={saveAndExit} />
        </ProgressContainer>

        <Core>
          <Content>
            <FormContainer>
              <Title>Your Options</Title>
              <Subtitle>
                We&apos;re going to ask some questions to work out the best loan for{' '}
                {applicantName}&apos;s position
              </Subtitle>
              {/* <Prompt style={s('mt-0', { marginBottom: 2 })}>
                What is more important to {applicantName}?
              </Prompt>
              <LoanFlexibilityPreferenceField
                name="structure.loanFlexibilityPreference"
                aria-label="client priority"
                readOnly
                defaultValue={1}
                data-test-id={TEST_IDS.LOAN_FLEXABILITY_RB}
              >
                <FlexibilityLevel value={1}>1</FlexibilityLevel>
                <FlexibilityLevel value={2}>2</FlexibilityLevel>
                <FlexibilityLevel value={3}>3</FlexibilityLevel>
                <FlexibilityLevel value={4}>4</FlexibilityLevel>
                <FlexibilityLevel value={5}>5</FlexibilityLevel>
              </LoanFlexibilityPreferenceField> */}
              <Prompt>How does {applicantName} prefer their loan rate?</Prompt>
              <RadioButtonField
                name="structure.loanRateType"
                aria-label="loan rate type"
                isReadOnly
                testId={TEST_IDS.LOAN_RATE_TYPE_RB}
              >
                <RadioButton value="fixed" readOnly>
                  Fixed
                </RadioButton>
                <RadioButton value="floating">Floating</RadioButton>
                <RadioButton value="fixed_and_floating" readOnly>
                  Fixed and Floating Combo
                </RadioButton>
              </RadioButtonField>
              {loanRateType && renderLoanOptions(loanRateType)}
              {loanRateType && <LoanTerms applicantName={applicantName} />}

              <CreditAssessorCheckbox
                name="creditAssessorCheck.options.isVerified"
                ariaLabel="Confirm information"
                testId={TEST_IDS.ASSESSOR_APP_REVIEW_CHECKBOX}
              />
            </FormContainer>

            <InfoContainer>
              <InfoSidebar />
            </InfoContainer>
          </Content>

          <NavigationContainer>
            <BottomNavigation goBack={goBack} canNext />
          </NavigationContainer>
        </Core>
      </Root>
    </Wizard.Step>
  )
}

export { YourOptionsStep }
