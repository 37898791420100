import React from 'react'
import { FieldArray, useFormikContext } from 'formik'
import { Button, TextField, RadioButtonField, RadioButton } from '@vega/components'
import { financialInfo } from 'modules/application/initialFormValues'
import { ReactComponent as PlusIcon } from '@vega/components/src/assets/images/plus.svg'
import { LIABILITY } from '../constants'
import { Prompt as OriginalPrompt } from 'features/broker/applications/components/typography'
import { styled, s } from '@vega/styled'
import { TEST_IDS } from '@neo/constants'
import { NotesProvider } from 'features/broker/applications/components/NotesProvider'

const FieldContainer = styled.div(s('mb-6'))
const Prompt = styled(OriginalPrompt)(s('mb-4'))
const CreditCardTitle = styled.h2(s('text-xl mb-4'))

const DeleteCreditCardBtn = styled(Button)(
  s(
    'bg-transparent border-b-1 border-solid rounded-none p-0 mt-4 text-grey-800 border-grey-800'
  )
)
const AddCreditCardBtn = styled(Button)(
  s(
    'bg-transparent border-b-1 border-solid rounded-none p-0 mt-4 text-primary border-primary'
  )
)

function Cards({ parentPath, applicantPathId, applicantName }) {
  const { values: formikValues } = useFormikContext()

  const creditCards =
    formikValues?.financials?.[applicantPathId]?.liabilities?.creditCard?.cards

  return (
    <FieldArray
      name={`${parentPath}.liabilities.creditCard.cards`}
      render={(arrayHelpers) => (
        <div>
          {creditCards.map((card, idx) => {
            const creditCardPath = `${parentPath}.liabilities.creditCard.cards.${idx}`

            return (
              <div key={idx} name={card[idx]}>
                <CreditCardTitle>Credit Card {idx + 1}</CreditCardTitle>

                <NotesProvider name={`${creditCardPath}.ownership`}>
                  <FieldContainer>
                    <Prompt>
                      Is {applicantName} applying for this loan alone, or with a
                      co-applicant?
                    </Prompt>
                    <RadioButtonField
                      name={`${creditCardPath}.ownership`}
                      aria-label="credit card ownership"
                      testId={`${TEST_IDS.CREDIT_CARD_CO_APPLICANT_RB}${idx + 1}`}
                    >
                      <RadioButton value={LIABILITY.OWNERSHIP.SELF}>
                        Applying alone
                      </RadioButton>
                      <RadioButton value={LIABILITY.OWNERSHIP.SHARED}>
                        With others
                      </RadioButton>
                    </RadioButtonField>
                  </FieldContainer>
                </NotesProvider>

                <NotesProvider name={`${creditCardPath}.limit`}>
                  <FieldContainer>
                    <Prompt style={s('mb-0')}>
                      What&apos;s the credit limit on this card?
                    </Prompt>
                    <TextField
                      name={`${creditCardPath}.limit`}
                      aria-label="Credit limit"
                      prefix="$"
                      containerStyle={s('w-1/2')}
                      testId={`${TEST_IDS.CREDIT_LIMIT_INPUT}${idx + 1}`}
                    />
                  </FieldContainer>
                </NotesProvider>

                <NotesProvider name={`${creditCardPath}.outstandingBalance`}>
                  <FieldContainer>
                    <Prompt style={s('mb-0')}>
                      What&apos;s the total outstanding balance of all {applicantName}
                      &apos;s credit cards?
                    </Prompt>
                    <TextField
                      name={`${creditCardPath}.outstandingBalance`}
                      aria-label="outstanding balance"
                      prefix="$"
                      containerStyle={s('w-1/2')}
                      testId={`${TEST_IDS.OUTSTANDING_BALANCE_INPUT}${idx + 1}`}
                    />
                  </FieldContainer>
                </NotesProvider>

                <NotesProvider name={`${creditCardPath}.provider`}>
                  <FieldContainer>
                    <Prompt style={s('mb-0')}>Who is the provider institution?</Prompt>
                    <TextField
                      name={`${creditCardPath}.provider`}
                      aria-label="provider"
                      placeholder="ex. ANZ, AMEX"
                      containerStyle={s('w-1/2')}
                      testId={`${TEST_IDS.CREDIT_CARD_INSTITUTION_INPUT}${idx + 1}`}
                    />
                  </FieldContainer>
                </NotesProvider>

                <NotesProvider name={`${creditCardPath}.repayments`}>
                  <FieldContainer>
                    <Prompt style={s('mb-0')}>
                      How much are the repayments against this outstanding balance?
                    </Prompt>
                    <TextField
                      name={`${creditCardPath}.repayments`}
                      aria-label="Repayments of the loans"
                      prefix="$"
                      suffix="p.mth"
                      containerStyle={s('w-1/2')}
                      testId={`${TEST_IDS.CREDIT_CARD_REPAYMENT_INPUT}${idx + 1}`}
                    />
                  </FieldContainer>
                </NotesProvider>

                {idx !== 0 && (
                  <DeleteCreditCardBtn
                    type="button"
                    onClick={() => arrayHelpers.remove(idx)}
                    style={s('my-6')}
                    data-test-id={`${TEST_IDS.DELETE_CREDIT_CARD_BTN}${idx + 1}`}
                  >
                    Delete credit card
                  </DeleteCreditCardBtn>
                )}
              </div>
            )
          })}

          <CreditCardTitle>Have another credit card?</CreditCardTitle>

          <AddCreditCardBtn
            StartIcon={<PlusIcon width={13} />}
            type="button"
            onClick={() =>
              arrayHelpers.push(financialInfo?.liabilities?.creditCard?.cards?.[0])
            }
            style={s('my-6')}
            data-test-id={TEST_IDS.ADD_CREDIT_CARD_BTN}
          >
            <span style={s('mt-1 ml-1')}>Add credit card</span>
          </AddCreditCardBtn>
        </div>
      )}
    />
  )
}

export { Cards }
