/* eslint-disable react/no-multi-comp */
import React from 'react'
import {
  always,
  findIndex,
  ifElse,
  isNilOrEmpty,
  join,
  keys,
  map,
  mapIndexed,
  noop,
  pipe,
  prop,
  take,
} from '@solta/ramda-extra'

import { styled, s } from '@vega/styled'
import { USER_ROLES } from '@vega/constants'

import fmt from 'utils/formatters'

const Root = styled.div(
  s('py-5 px-4 bg-white show-box border-grey-200 flex flex-column', {
    height: 'max-content',
    minHeight: 227,
    borderRadius: '8px 0 8px 8px',
    cursor: 'pointer',
  })
)

const FirstRow = styled.div(s('flex justify-between align-center mb-3'))
const CreatedAt = styled.span(s('font-medium text-xs text-grey-500 my-a'))
const StatusContainer = styled.div(
  s('flex justify-center align-center px-2 py-1 bg-grey-100 text-grey-700', {
    borderRadius: '4px 0 4px 4px',
  }),
  ({ status }) =>
    ({
      Approved: s('bg-success-100 text-success-800'),
      Failed: s('bg-error-100 text-error-700'),
    }[status])
)
const DisplayedStatus = styled.span(s('m-a font-semibold text-xs'))

const SecondRow = styled.div(s('flex mb-4'))
const Title = styled.h1(s('text-grey-700 font-medium text-xl my-0'))

const ThirdRow = styled.div(s('flex justify-between align-center mb-3'))
const InfoContainer = styled.div()
const AvatarsContainer = styled.div(s('flex relative'), {
  marginBottom: 28,
  '& > div': s('absolute pin-t', { top: 7, backgroundColor: '#4365EE' }),
  '& > div:nth-of-type(2)': s('bg-primary', { left: 18 }),
  '& > div:nth-of-type(3)': s('', { left: 36, backgroundColor: '#4365EE' }),
})
const Avatar = styled.div(s('flex show-box border-grey-50'), {
  borderWidth: 0.5,
  borderRadius: '6px 0px 6px 6px',
  width: 24,
  height: 24,
})
const Initial = styled.div(
  s('m-a text-xs text-grey-50', { transform: 'translateY(-1px)' })
)
const Label = styled.span(s('text-xs text-grey-500 font-normal mb-2'))
const Value = styled.span(s('flex my-a align-center w-full'))
const ValueContainer = styled.div(s('flex font-semibold text-base text-grey-800 mt-2'))

const ProgressBar = styled.div(s('flex flex-column mt-2 mb-0 relative pt-1'))
const Slider = styled.div(
  s('bg-grey-50 show-box border-grey mb-2 flex w-full mb-10', {
    height: 12,
    borderWidth: 0.5,
    borderRadius: 30,
  })
)
const InnerBar = styled(Slider)(
  s('my-a', {
    height: 11,
    borderWidth: 0,
    backgroundColor: '#CAEDEA',
  }),
  ({ stage }) =>
    ({
      APPLICATION_SUBMISSION: s('hidden'),
      FAILED: s('hidden'),
      PROPERTY_SEARCH: { width: '36%' },
      PROPERTY_SUBMITTED: { width: '69%' },
      APPROVED: s('w-ful'),
    }[stage])
)
const Knob = styled.div(
  s('absolute bg-green-600 rounded-full', {
    '&::after': s('text-grey-800 font-medium absolute', {
      fontSize: 10,
      lineHeight: 1.5,
      wordSpacing: 100000,
      top: 14,
    }),
  }),
  ({ stage }) =>
    ({
      APPLICATION_SUBMISSION: {
        left: 0,
        '&::after': {
          content: `"Application Submission"`,
        },
      },
      FAILED: {
        left: 0,
        '&::after': {
          content: `"Application Submission"`,
        },
      },
      PROPERTY_SEARCH: {
        left: '33.33333%',
        '&::after': {
          content: `"Property Search"`,
          right: -20,
        },
      },
      PROPERTY_SUBMITTED: {
        left: '66.66667%',
        '&::after': {
          content: `"Property Submitted"`,
          right: -26,
        },
      },
      APPROVED: {
        left: '97%',
        '&::after': {
          content: `"Approved"`,
          right: -4,
        },
      },
    }[stage]),
  ({ isCurrent }) =>
    isCurrent
      ? s('bg-green-600', {
          top: 2.75,
          width: 14,
          height: 14,
          '&::after': { top: 18 },
        })
      : s('bg-grey-200', { width: 6, height: 6, top: 6.9 }),
  ({ isComplete }) => (isComplete ? { width: 0, height: 0 } : {}),
  ({ isComplete, stage }) =>
    isComplete && stage === 'PROPERTY_SEARCH' ? { left: '35%' } : {},
  ({ isComplete, stage }) =>
    isComplete && stage === 'PROPERTY_SUBMITTED' ? { left: '68%' } : {}
)
export const OverviewCard = ({
  createdAt,
  currentStage,
  applicants = [],
  deposit,
  maxLoanAmount,
  viewerRole,
  testId,
  onClick = noop,
  ...otherProps
}) => {
  const makeTitle = (names = []) => {
    if (names.length === 1) return `${names[0]}'s Application`
    if (names.length === 2) return `${join(' & ')(names)}'s Application`
    return `${names[0]} & Others' Application`
  }
  const getFirstNames = map(prop('firstName'))
  const renderTitle = ifElse(
    isNilOrEmpty,
    always('Application'),
    pipe(getFirstNames, map(fmt.capitalize), makeTitle)
  )

  const renderOneAvatar = (name, idx, names) => {
    const isThird = idx + 1 === 3
    const isFourthOrMore = idx + 1 > 3
    const numOfUndisplayedNames = names.length - 2 > 9 ? 9 : names.length - 2
    switch (true) {
      case isThird: {
        return (
          <Avatar key={idx}>
            <Initial>{`+${numOfUndisplayedNames}`}</Initial>
          </Avatar>
        )
      }
      case isFourthOrMore: {
        return null
      }
      default: {
        return (
          <Avatar key={idx}>
            <Initial>{name[0].toUpperCase()}</Initial>
          </Avatar>
        )
      }
    }
  }
  const renderEmptyAvatar = () => (
    <Avatar>
      <Initial>-</Initial>
    </Avatar>
  )

  const renderAvatars = ifElse(
    isNilOrEmpty,
    renderEmptyAvatar,
    pipe(getFirstNames, take(3), mapIndexed(renderOneAvatar))
  )

  const stageToStatus = {
    FAILED: 'Failed',
    APPLICATION_SUBMISSION: 'Pending',
    PROPERTY_SEARCH: 'Pending',
    PROPERTY_SUBMITTED: 'Auction Pending',
    APPROVED: 'Approved',
  }

  const isStageComplete = (targetStage) => {
    const stagesOrder = keys(stageToStatus)
    const currentStageIndex = findIndex((s) => s === currentStage)(stagesOrder)
    const targetStageIndex = findIndex((s) => s === targetStage)(stagesOrder)
    return targetStageIndex < currentStageIndex
  }

  const lookUpStatus = (stage) => stageToStatus[stage]

  const numOfApplicants = applicants.length

  return (
    <Root data-test-id={testId} onClick={onClick} {...otherProps}>
      <FirstRow>
        <CreatedAt>{`Created at ${fmt.formatDate(createdAt)}`}</CreatedAt>
        <StatusContainer status={lookUpStatus(currentStage)}>
          <DisplayedStatus>{lookUpStatus(currentStage)}</DisplayedStatus>
        </StatusContainer>
      </FirstRow>
      <SecondRow>
        <Title>{renderTitle(applicants)}</Title>
      </SecondRow>
      <ThirdRow>
        <InfoContainer>
          {viewerRole === USER_ROLES.BROKER ? (
            <>
              <AvatarsContainer numOfApplicants={numOfApplicants}>
                {renderAvatars(applicants)}
              </AvatarsContainer>
              <Label>Applicants</Label>
            </>
          ) : (
            <>
              <ValueContainer>{applicants.length > 1 ? 'Yes' : 'No'}</ValueContainer>
              <Label>Co-Applicant</Label>
            </>
          )}
        </InfoContainer>
        <InfoContainer>
          <ValueContainer>
            <Value>{fmt.formatMillionsOrLess(deposit)}</Value>
          </ValueContainer>
          <Label>Deposit</Label>
        </InfoContainer>
        <InfoContainer>
          <ValueContainer>
            <Value>{fmt.formatMillionsOrLess(maxLoanAmount)}</Value>
          </ValueContainer>
          <Label>Max loan amount</Label>
        </InfoContainer>
      </ThirdRow>
      <ProgressBar>
        <Slider>
          <InnerBar stage={currentStage} />
          <Knob
            stage="APPLICATION_SUBMISSION"
            isCurrent={
              currentStage === 'APPLICATION_SUBMISSION' || currentStage === 'FAILED'
            }
            isComplete={isStageComplete('APPLICATION_SUBMISSION')}
          />
          <Knob
            stage="PROPERTY_SEARCH"
            isCurrent={currentStage === 'PROPERTY_SEARCH'}
            isComplete={isStageComplete('PROPERTY_SEARCH')}
          />
          <Knob
            stage="PROPERTY_SUBMITTED"
            isCurrent={currentStage === 'PROPERTY_SUBMITTED'}
            isComplete={isStageComplete('PROPERTY_SUBMITTED')}
          />
          <Knob
            stage="APPROVED"
            isCurrent={currentStage === 'APPROVED'}
            isComplete={isStageComplete('APPROVED')}
          />
        </Slider>
      </ProgressBar>
    </Root>
  )
}
