import React from 'react'

import { styled, s } from '@vega/styled'
import { prop } from '@solta/ramda-extra'

const Container = styled.div(
  s('px-4 flex justify-between items-center bg-green-50'),
  prop('containerStyle')
)

const TabsContainer = styled.div(s('flex'))
const Tab = styled.li(
  s(
    'relative flex justify-center mx-4 py-1 font-normal no-decoration text-grey-800 text-base',
    {
      cursor: 'pointer',
    }
  )
)
const BottomLine = styled.div(
  s('absolute pin-bl-0 bg-green-700 w-full', {
    height: '3px',
    borderRadius: '1px 0px 1px 1px',
  })
)

function TopTabFilter({
  filters = [],
  currentFilter,
  containerStyle,
  onFilterSelected,
  ...props
}) {
  return (
    <Container containerStyle={containerStyle} {...props}>
      <TabsContainer>
        {filters.map(({ value, label }) => (
          <Tab key={value} onClick={() => onFilterSelected(value)}>
            {label}
            {value === currentFilter && <BottomLine />}
          </Tab>
        ))}
      </TabsContainer>
    </Container>
  )
}

export { TopTabFilter }
