import React, { useEffect } from 'react'
import { useWizard } from '@vega/formik-wizard'
import { useDispatch, useSelector } from 'react-redux'
import { styled, s } from '@vega/styled'

import {
  concat,
  concatAll,
  map,
  omit,
  path,
  pick,
  pipe,
  prop,
  range,
} from '@solta/ramda-extra'

import { YourGoalsStep } from './YourGoalsStep'
import { ClientIdentificationStep } from './ClientIdentificationStep'
import { YourDetailsStep } from './YourDetailsStep'
import { YourOptionsStep } from './YourOptionsStep'
import { LoanOptionsStep } from './LoanOptionsStep'
import { YourFinancialsSteps } from './YourFinancialsSteps'
import { PreApprovalStep } from './PreApprovalStep'
import {
  selectApplicantIds,
  selectNumOfApplicants,
  updateFormCurrentStepId,
  updateFormStatus,
} from 'modules/application'
import { useFormikContext } from 'formik'
import { rehydrate } from '@vega/redux.upload'
import * as CONSTANTS from '@vega/constants'

const { FORM_STEPS } = CONSTANTS

const Content = styled.div(s('w-full h-full flex flex-column', { overflowY: 'hidden' }))
const FormContent = styled.div(s('flex-1', { overflowY: 'auto' }))

function View() {
  const { currentStep, hasInitialized } = useWizard()
  const { values: formValues } = useFormikContext()
  const dispatch = useDispatch()

  useEffect(() => {
    updateFormProgressOnNewStep()
    function updateFormProgressOnNewStep() {
      if (currentStep && hasInitialized) {
        dispatch(updateFormCurrentStepId(currentStep.id))
        dispatch(updateFormStatus(currentStep.id))
      }
    }
  }, [dispatch, currentStep, hasInitialized])

  useEffect(() => {
    scrollTopOnNewStep()
    function scrollTopOnNewStep() {
      document.querySelector('#loan-application-form').scrollTop = 0
    }
  }, [currentStep])

  const numOfApplicants = useSelector(selectNumOfApplicants)
  const applicantIds = useSelector(selectApplicantIds)
  const countRange = range(0, numOfApplicants)

  const makeSharedProps = (formValues, applicantIds) => (idx) => {
    const applicantPathId = `applicant${idx + 1}`
    const applicantId = applicantIds[idx]
    const {
      firstName: applicantName = 'your client',
      documents,
    } = formValues?.details?.[applicantPathId]
    const isLastApplicant = idx + 1 === numOfApplicants
    return {
      applicantId,
      applicantName,
      applicantPathId,
      isLastApplicant,
      documents,
    }
  }

  const sharedProps = map(makeSharedProps(formValues, applicantIds))(countRange)
  const detailsStepProps = map(pick(['applicantPathId', 'applicantId']), sharedProps)
  const identificationStepProps = sharedProps
  const financialStepProps = map(omit(['documents']), sharedProps)

  const applicantsAdded = applicantIds.length > 0
  const isUploadModuleHydrated = React.useRef(false)

  useEffect(() => {
    rehydrateUploadModule()
    function rehydrateUploadModule() {
      if (hasInitialized && !isUploadModuleHydrated.current && applicantsAdded) {
        const getIdDocs = map(prop('documents'))

        const getPassportFiles = path(['passport', 'files'])
        const getDriverLicenceFiles = path(['driverLicence', 'files'])
        const getEachApplicantFiles = (docs) =>
          concat(getPassportFiles(docs), getDriverLicenceFiles(docs))
        const getAllApplicantFiles = pipe(map(getEachApplicantFiles), concatAll)

        const files = pipe(getIdDocs, getAllApplicantFiles)(sharedProps)
        dispatch(rehydrate(files))
        isUploadModuleHydrated.current = true
      }
    }
  }, [applicantsAdded, dispatch, hasInitialized, sharedProps])

  return (
    <Content id="loan-application-form">
      <FormContent>
        <YourDetailsStep sharedProps={detailsStepProps} />
        <ClientIdentificationStep sharedProps={identificationStepProps} />
        <YourFinancialsSteps sharedProps={financialStepProps} />
        <YourGoalsStep stepId={FORM_STEPS.GOALS} />
        <YourOptionsStep stepId={FORM_STEPS.OPTIONS} />
        <LoanOptionsStep stepId={FORM_STEPS.LOAN_OPTIONS} />
        <PreApprovalStep stepId={FORM_STEPS.PRE_APPROVAL} />
      </FormContent>
    </Content>
  )
}

export default View
