import { pick } from '@solta/ramda-extra'
import moment from 'moment'

import { mapToAddressModel } from './mapToAddressModel'

const mapToApplicantModel = (details) => {
  const {
    dob,
    currentAddress,
    mailingAddress,
    phoneNumber,
    isNZPR,
    residencyStatus,
    residentCountry,
  } = details

  const formattedDob = moment(dob, 'DD MM YYYY').format('YYYY-MM-DD').toString()

  const currentAddressWithType = mapToAddressModel(currentAddress, 'currentAddress')

  const mailingAddressWithType = mapToAddressModel(mailingAddress, 'mailingAddress')

  const addresses = [currentAddressWithType, mailingAddressWithType]

  const model = pick([
    'firstName',
    'lastName',
    'middleName',
    'preferredName',
    'gender',
    'marital',
    'email',
    'citizenCountry',
    'numOfDependants',
    'livingArrangement',
    'applicantType',
  ])(details)

  return {
    ...model,
    addresses,
    dob: formattedDob,
    phoneNumber: `+64${phoneNumber}`,
    residentCountry: isNZPR ? 'nz' : residentCountry,
    residencyStatus: isNZPR ? 'permanent_resident' : residencyStatus,
  }
}

export default mapToApplicantModel
