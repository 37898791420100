/* eslint-disable react/no-multi-comp */
/* eslint-disable complexity */
import React from 'react'

import flat from 'flat'

import {
  pipe,
  isNilOrEmpty,
  isNil,
  reject,
  match,
  head,
  replaceAll,
  replace,
  test,
} from '@solta/ramda-extra'
import { s, styled } from '@vega/styled'

const Root = styled.div(
  s('show-box bg-error-50 border-0 flex', { borderRadius: '8px 0 8px 8px' })
)

const LeftVerticalBar = styled.div({
  borderWidth: 4,
  borderRadius: 2,
  width: 6,
  backgroundColor: '#B90919',
})

// example path: financials.applicant1.employment.0.startDate
const parseItemCount = (errorPath) => {
  // assuming that we don't nest array within array, there should only be one match
  const extractArrayIndex = pipe(match(/\.[0-9]+\./), head)
  const removeDelimiter = replaceAll('.', '')
  const getCount = (stringIndex) => parseInt(stringIndex, 10) + 1
  return pipe(extractArrayIndex, removeDelimiter, getCount)(errorPath)
}

// see the tests in the same folder for context
export const addSectionIdentifierForDynamicField = (errorPath = '', error = '') => {
  const count = parseItemCount(errorPath)

  // the regex determines which dynamic field it is
  switch (true) {
    case test(/invitations\.[0-9]+\./, errorPath):
      return `${error} for Applicant ${count}`
    case test(/employment\.[0-9]+\./, errorPath):
      return `${error} for Employment ${count}`
    case test(/property\.properties\.[0-9]+\./, errorPath):
      return `${error} for Property ${count}`
    case test(/creditCard\.cards\.[0-9]+\./, errorPath):
      return `${error} for Credit Card ${count}`
    case test(/personalLoan\.loans\.[0-9]+\./, errorPath):
      return `${error} for Loan ${count}`
    default:
      return error
  }
}

// see https://html.com/anchors-links/
const ErrorWithInternalLink = ({ fieldPath, errorMessage, errorCount }) => {
  const pathExists = document.getElementById(fieldPath)

  // Because formik errors has the same structure as the form values object, we can use anchor to link to a hidden span under each field error message. See FormMessage component for more details
  return (
    <li style={s('pb-2')} key={fieldPath}>
      <p style={s('m-0 p-0')}>
        Error {errorCount}:{' '}
        {pathExists ? <a href={`#${fieldPath}`}>{errorMessage}</a> : errorMessage}
      </p>
    </li>
  )
}

export const ErrorSummary = ({
  errors,
  whitelistErrorPaths = [],
  visible = false,
  debug = false,
  ...props
}) => {
  if (!visible) return null

  // need to recheck since errors may not be up-to-date due to React's batching, resulting in an empty section
  if (isNilOrEmpty(errors)) return null

  const renderedErrors = pipe((errors) => {
    const pathAndValues = Object.entries(flat(errors))
    // various fields & hence their errors are not shown to user (such as propertyId), so we need to remove them from the error count before showing to the users
    let hiddenErrorsCount = 0

    return pathAndValues.map(([errorPath, error], index) => {
      const errorCount = index + 1 - hiddenErrorsCount

      const isDynamic = test(/\.[0-9]+\./, errorPath)
      // what comes after custom. is the field name that this error path links to
      // custom error is used to replace duplicate errors for multiple fields in a dynamic array where showing all of them don't make sense (e.g. checkboxes for selecting primary applicant)
      const isCustom = test(/custom\./, errorPath)

      const errorMessage =
        isDynamic && !isCustom
          ? addSectionIdentifierForDynamicField(errorPath, error)
          : error

      if (isCustom) {
        return (
          <ErrorWithInternalLink
            fieldPath={replace('custom.', '', errorPath)}
            errorMessage={errorMessage}
            errorCount={errorCount}
            key={errorPath}
          />
        )
      }

      const errorNotRendered = isNil(document.getElementById(errorPath))
      const errorIsIgnored = whitelistErrorPaths.includes(errorPath)

      if (errorNotRendered || errorIsIgnored) {
        hiddenErrorsCount += 1
        return null
      }

      return (
        <ErrorWithInternalLink
          fieldPath={errorPath}
          errorMessage={errorMessage}
          errorCount={errorCount}
          key={errorPath}
        />
      )
    })
  }, reject(isNilOrEmpty))(errors)

  if (isNilOrEmpty(renderedErrors)) return null

  return (
    <Root {...props}>
      <LeftVerticalBar>&nbsp;</LeftVerticalBar>
      <div style={s('flex-1 py-4 px-5')}>
        {debug && <code>{JSON.stringify(flat(errors), null, 4)}</code>}
        <h3 style={s('text-error-800 font-bold text-xl m-0')}>
          Form contains the following errors!
        </h3>
        <ul style={s('pl-6 mb-0 mt-3', { '& > li:not(:lastChild)': s('pb-2') })}>
          {renderedErrors}
        </ul>
      </div>
    </Root>
  )
}
