import React from 'react'
import ActionDropdownToggler from './ActionsDropdownToggler'
import { Dropdown, DropdownItem } from '@vega/components'
import { noop } from '@solta/ramda-extra'

export default function Actions({ recordId, actions = [], itemProps }) {
  return (
    <Dropdown
      dropdownMenuProps={{
        style: {
          top: 0,
          right: 20,
          zIndex: 1,
        },
      }}
      Toggler={({ toggle }) => <ActionDropdownToggler onClick={toggle} />}
    >
      {actions.map(({ name, onClick = noop }) => (
        <DropdownItem
          {...itemProps}
          key={name}
          text={name}
          onClick={() => onClick(recordId)}
        />
      ))}
    </Dropdown>
  )
}
