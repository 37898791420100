import { always, ifElse, isNilOrEmpty } from '@solta/ramda-extra'
import moment from 'moment'

const STRING_VALUE_PLACEHOLDER = '-'
const EMPTY_AMOUNT_PLACEHOLDER = '$ -'

const capitalize = (str = '') => `${str.charAt(0).toUpperCase()}${str.slice(1)}`

const formatCurrency = (value, digits = 0) =>
  new Intl.NumberFormat('en-NZ', {
    style: 'currency',
    currency: 'NZD',
    maximumFractionDigits: digits,
  }).format(value)

const convertDate = (date) => moment(date).format('DD/MM/YYYY')
const formatDate = ifElse(isNilOrEmpty, always(STRING_VALUE_PLACEHOLDER), convertDate)

const formatMonetaryAmount = ifElse(
  isNilOrEmpty,
  always(EMPTY_AMOUNT_PLACEHOLDER),
  formatCurrency
)

const formatMillionsOrLess = (val) => {
  if (isNilOrEmpty(val)) return EMPTY_AMOUNT_PLACEHOLDER

  if (val < 1_000_000) return formatMonetaryAmount(val)

  const valueInMil = val / 1_000_000
  const unit = valueInMil >= 2 ? 'mils' : 'mil'

  return `${formatMonetaryAmount(valueInMil, 1)} ${unit}`
}

const withEmptyPlaceholder = (value, placeholder) => {
  const emptyPlaceholder = placeholder || STRING_VALUE_PLACEHOLDER

  return ifElse(isNilOrEmpty, always(emptyPlaceholder), always(value))(value)
}

export default {
  capitalize,
  formatDate,
  formatMonetaryAmount,
  formatMillionsOrLess,
  withEmptyPlaceholder,
}
