import React from 'react'
import { useSelector } from 'react-redux'
import { TopNavBar, PropertyInfo } from 'features/common/property'
import { Button, DottedCircleLoader } from '@vega/components'
import {
  selectGetPropertyDetailsStatus,
  selectSearchedProperty,
} from 'modules/property'
import { ASYNC_STATUSES } from '@neo/constants'
import { styled, s } from '@vega/styled'

const Container = styled.div(
  s('flex flex-column items-center w-full px-10 h-full', {
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  })
)

const ContentContainer = styled.div(
  s('flex flex-column items-center w-full py-10', { maxWidth: 1200 })
)
const PropertyImage = styled.img(
  s('w-full object-cover', { borderRadius: '20px 0 20px 20px', maxHeight: 500 })
)

const DetailsContainer = styled.div(s('flex justify-between pt-8 px-24 w-full'))
const InfoContainer = styled.div(s('pr-4', { flexBasis: '80%' }))
const OperationContainer = styled.div(s('flex-1'))
const SaveBtn = styled(Button)(s('w-full'))

function PropertyDetails({ onClose, setCurrentPage }) {
  const isFetchingPropertyDetails =
    useSelector(selectGetPropertyDetailsStatus) === ASYNC_STATUSES.PENDING
  const property = useSelector(selectSearchedProperty)

  const onPropertySave = () => {
    setCurrentPage('savedPropertyQuestions')
  }
  const toPrevPage = () => setCurrentPage('propertySearch')

  if (isFetchingPropertyDetails)
    return (
      <Container style={{ display: 'grid', placeItems: 'center' }}>
        <DottedCircleLoader size="lg" />
      </Container>
    )

  return (
    <Container>
      <TopNavBar onClose={onClose} toPrevPage={toPrevPage} />

      <ContentContainer>
        <PropertyImage src={property?.imageSrc} />

        <DetailsContainer>
          <InfoContainer>
            <PropertyInfo property={property} />
          </InfoContainer>

          <OperationContainer>
            <SaveBtn onClick={onPropertySave}>Save property</SaveBtn>
          </OperationContainer>
        </DetailsContainer>
      </ContentContainer>
    </Container>
  )
}

export { PropertyDetails }
