import React from 'react'
import { useFormikContext } from 'formik'
import { useSelector } from 'react-redux'
import { calculatePercentageChange } from 'utils/calculatePercentageChange'
import { ComparisonCard } from 'features/common/overview/ComparisonCard'
import {
  selectReportFromClientsCountReportsByMonth,
  selectGetClientsCountReportStatus,
} from 'modules/report'
import { styled, s } from '@vega/styled'
import { ASYNC_STATUSES } from '@neo/constants'

const { PENDING } = ASYNC_STATUSES

const Container = styled.div(
  s('w-full flex items-center justify-between', {
    maxWidth: 1180,
  })
)

function ComparisonSection({ dateComparedWith, ...otherProps }) {
  const { values: formikValues } = useFormikContext()
  const { year, month } = formikValues
  const { prevYear, prevMonth } = dateComparedWith

  const isGettingClientsCountReport =
    useSelector(selectGetClientsCountReportStatus) === PENDING

  const clientsCountReportOfQueriedDate = useSelector(
    selectReportFromClientsCountReportsByMonth(year, month)
  )
  const clientsCountReportOfPrevMonth = useSelector(
    selectReportFromClientsCountReportsByMonth(prevYear || year, prevMonth)
  )

  const {
    totalReturningClients: totalReturningClientsOfQueriedDate,
    totalClients: totalClientsOfQueriedDate,
    totalNewClients: totalNewClientsOfQueriedDate,
  } = clientsCountReportOfQueriedDate
  const {
    totalReturningClients: totalReturningClientsOfPrevMonth,
    totalClients: totalClientsOfPrevMonth,
    totalNewClients: totalNewClientsOfPrevMonth,
  } = clientsCountReportOfPrevMonth

  return (
    <Container {...otherProps}>
      <ComparisonCard
        title="Total clients"
        currentValue={totalClientsOfQueriedDate}
        percentageChanges={calculatePercentageChange(
          totalClientsOfQueriedDate,
          totalClientsOfPrevMonth
        )}
        monthToBeComparedWith={prevMonth}
        yearToBeComparedWith={prevYear}
        isCurrentValuePercentage={false}
        isLoading={isGettingClientsCountReport}
        containerStyle={s('mr-5', { width: 380 })}
      />
      <ComparisonCard
        title="New clients"
        currentValue={totalNewClientsOfQueriedDate}
        percentageChanges={calculatePercentageChange(
          totalNewClientsOfQueriedDate,
          totalNewClientsOfPrevMonth
        )}
        monthToBeComparedWith={prevMonth}
        yearToBeComparedWith={prevYear}
        isCurrentValuePercentage={false}
        isLoading={isGettingClientsCountReport}
        containerStyle={s('mr-5', { width: 380 })}
      />
      <ComparisonCard
        title="Returning clients"
        currentValue={totalReturningClientsOfQueriedDate}
        percentageChanges={calculatePercentageChange(
          totalReturningClientsOfQueriedDate,
          totalReturningClientsOfPrevMonth
        )}
        isCurrentValuePercentage={false}
        monthToBeComparedWith={prevMonth}
        yearToBeComparedWith={prevYear}
        isLoading={isGettingClientsCountReport}
        containerStyle={{ width: 380 }}
      />
    </Container>
  )
}

export { ComparisonSection }
