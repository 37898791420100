import React from 'react'
import { isNilOrEmpty } from '@solta/ramda-extra'
import { s } from '@vega/styled'
import { RadioButton, RadioButtonField, TextField } from '@vega/components'
import { Subheading } from './layoutComponents'
import CreditProfileSection from './CreditProfileSection'
import CreditScoreSection from './CreditScoreSection'
import { CreditAssessorCheckbox } from '../../../components/CreditAssessorCheckbox'
import { TEST_IDS } from '@neo/constants'

function CreditReportSummary({ applicantPathId, applicantId, reportData }) {
  if (isNilOrEmpty(reportData?.profile) && isNilOrEmpty(reportData?.creditScores))
    return null

  return (
    <>
      <CreditScoreSection creditScores={reportData?.creditScores} />
      <CreditProfileSection profile={reportData?.profile} />

      <Subheading>Do you want to view the credit history?</Subheading>

      <RadioButtonField
        name={`${applicantPathId}.creditHistory.toggle`}
        aria-label="credit history toggle"
        containerStyle={s('w-full')}
        isReadOnly
      >
        <RadioButton value={true}>Yes</RadioButton>
        <RadioButton value={false}>No</RadioButton>
      </RadioButtonField>

      <Subheading>Additional notes</Subheading>
      <TextField
        name={`${applicantPathId}-additional-notes`}
        placeholder="Type your notes"
        optional={true}
        isReadOnly
      />

      <CreditAssessorCheckbox
        name={`creditAssessorCheck.creditReport.${applicantId}.isVerified`}
        ariaLabel="Confirm information"
        testId={TEST_IDS.ASSESSOR_APP_REVIEW_CHECKBOX}
      />
    </>
  )
}

export { CreditReportSummary }
