import {
  isNilOrEmpty,
  mapObjIndexed,
  compose,
  values,
  pipe,
  reject,
  defaultTo,
} from '@solta/ramda-extra'
import { mapAssets } from './mapAssets'
import { mapLiabilities } from './mapLiabilities'

const formatToArray = (mappedIncomes) => values(mappedIncomes)

const mapToIncomeUpdateModel = (incomes) =>
  mapObjIndexed((value, incomeType) => {
    return value?.eligibility
      ? {
          id: value?.id,
          name: incomeType,
          amount: value?.amount,
          recurrence: value?.recurrence,
        }
      : {
          id: value?.id,
          name: incomeType,
          amount: 0,
          // set recurrence to a random value to pass the lending api contract
          recurrence: 'half_yearly',
        }
  }, incomes)

const mapSalaryToIncomeViewModel = ({ income, salary }) => ({
  ...income,
  salary: {
    id: salary?.id,
    eligibility: true,
    amount: salary?.amount,
    recurrence: 'annually',
  },
})

const mapIncome = ({ income, salary }) => {
  const mappedIncome = pipe(
    mapSalaryToIncomeViewModel,
    mapToIncomeUpdateModel,
    formatToArray
  )({ income, salary })
  return mappedIncome
}

const mapExpenses = ({ housing, personal, other }) => {
  const expenses = { ...housing, ...personal, ...other }

  const mapToExpenseAccount = mapObjIndexed((v, k) => ({
    id: v.id,
    name: k,
    amount: defaultTo(0, v.value),
    recurrence: v.recurrence,
  }))

  return compose(values, mapToExpenseAccount)(expenses)
}

const isObjEmpty = (obj) => pipe(defaultTo({}), reject(isNilOrEmpty), isNilOrEmpty)(obj)
const mapToFinancialsModelProxy = (obj, mapFunc) => {
  if (isObjEmpty(obj)) return undefined

  return mapFunc(obj)
}

const mapToIncomeAndAssetsUpdateModel = ({ income, salary, assets }) => ({
  income: mapToFinancialsModelProxy({ income, salary }, mapIncome),
  assets: mapToFinancialsModelProxy(assets, mapAssets),
})
const mapToExpensesAndLiabilitiesUpdateModel = ({ expenses, liabilities }) => ({
  expenses: mapToFinancialsModelProxy(expenses, mapExpenses),
  liabilities: mapToFinancialsModelProxy(liabilities, mapLiabilities),
})

export default {
  mapToIncomeAndAssetsUpdateModel,
  mapToExpensesAndLiabilitiesUpdateModel,
}
