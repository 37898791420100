import { pick, map, pipe } from '@solta/ramda-extra'
import { getApplicantStatus } from './getApplicantStatus'

const getListOfApplicantStatuses = (applicants, formProgress) => {
  const getIdAndName = pick(['id', 'firstName'])

  const getNameAndStatus = (applicant) => {
    return {
      name: applicant.firstName,
      status: getApplicantStatus(formProgress, applicant.id),
    }
  }

  const applicantStatuses = pipe(map(getIdAndName), map(getNameAndStatus))(applicants)

  return applicantStatuses
}

export { getListOfApplicantStatuses }
