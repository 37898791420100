import React from 'react'
import { FieldArray, useFormikContext } from 'formik'
import { TextField, RadioButtonField, RadioButton } from '@vega/components'
import { LIABILITY } from '../constants'
import { Prompt as OriginalPrompt } from 'features/creditAssessor/applications/components/typography'
import { styled, s } from '@vega/styled'
import { TEST_IDS } from '@neo/constants'

const FieldContainer = styled.div(s('mb-6'))
const Prompt = styled(OriginalPrompt)(s('mb-4'))
const CreditCardTitle = styled.h2(s('text-xl mb-4'))

function Cards({ parentPath, applicantPathId, applicantName }) {
  const { values: formikValues } = useFormikContext()

  const creditCards =
    formikValues?.financials?.[applicantPathId]?.liabilities?.creditCard?.cards

  return (
    <FieldArray
      name={`${parentPath}.liabilities.creditCard.cards`}
      render={() => (
        <div>
          {creditCards.map((card, idx) => {
            const creditCardPath = `${parentPath}.liabilities.creditCard.cards[${idx}]`

            return (
              <div key={idx} name={card[idx]}>
                <CreditCardTitle>Credit Card {idx + 1}</CreditCardTitle>

                <FieldContainer>
                  <Prompt>
                    Is {applicantName} applying for this loan alone, or with a
                    co-applicant?
                  </Prompt>
                  <RadioButtonField
                    name={`${creditCardPath}.ownership`}
                    aria-label="credit card ownership"
                    testId={`${TEST_IDS.CREDIT_CARD_CO_APPLICANT_RB}${idx + 1}`}
                    isReadOnly
                  >
                    <RadioButton value={LIABILITY.OWNERSHIP.SELF}>
                      Applying alone
                    </RadioButton>
                    <RadioButton value={LIABILITY.OWNERSHIP.SHARED}>
                      With others
                    </RadioButton>
                  </RadioButtonField>
                </FieldContainer>

                <FieldContainer>
                  <Prompt style={s('mb-0')}>
                    What&apos;s the credit limit on this card?
                  </Prompt>
                  <TextField
                    name={`${creditCardPath}.limit`}
                    aria-label="Credit limit"
                    prefix="$"
                    containerStyle={s('w-1/2')}
                    testId={`${TEST_IDS.CREDIT_LIMIT_INPUT}${idx + 1}`}
                    isReadOnly
                  />
                </FieldContainer>

                <FieldContainer>
                  <Prompt style={s('mb-0')}>
                    What&apos;s the total outstanding balance of all {applicantName}
                    &apos;s credit cards?
                  </Prompt>
                  <TextField
                    name={`${creditCardPath}.outstandingBalance`}
                    aria-label="outstanding balance"
                    prefix="$"
                    containerStyle={s('w-1/2')}
                    testId={`${TEST_IDS.OUTSTANDING_BALANCE_INPUT}${idx + 1}`}
                    isReadOnly
                  />
                </FieldContainer>

                <FieldContainer>
                  <Prompt style={s('mb-0')}>Who is the provider institution?</Prompt>
                  <TextField
                    name={`${creditCardPath}.provider`}
                    aria-label="provider"
                    placeholder="ex. ANZ, AMEX"
                    containerStyle={s('w-1/2')}
                    testId={`${TEST_IDS.CREDIT_CARD_INSTITUTION_INPUT}${idx + 1}`}
                    isReadOnly
                  />
                </FieldContainer>

                <FieldContainer>
                  <Prompt style={s('mb-0')}>
                    How much are the repayments against this outstanding balance?
                  </Prompt>
                  <TextField
                    name={`${creditCardPath}.repayments`}
                    aria-label="Repayments of the loans"
                    prefix="$"
                    suffix="p.mth"
                    containerStyle={s('w-1/2')}
                    testId={`${TEST_IDS.CREDIT_CARD_REPAYMENT_INPUT}${idx + 1}`}
                    isReadOnly
                  />
                </FieldContainer>
              </div>
            )
          })}
        </div>
      )}
    />
  )
}

export { Cards }
