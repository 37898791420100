/* eslint-disable no-console */
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { prop } from '@solta/ramda-extra'

import { PageLong } from './mock/clients'
import { userService } from 'apiService'
import { normalizeError } from '@vega/services'

export const fetchClients = createAsyncThunk(
  'clients/fetchClients',
  async ({ searchTerm: q, pageIndex, limit, filters }, { rejectWithValue }) => {
    try {
      return await userService.fetchUsers({
        q,
        filters,
        start: limit * pageIndex,
        limit,
      })
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const fetchClient = createAsyncThunk(
  'clients/fetchClient',
  async (clientId, { rejectWithValue }) => {
    try {
      return await userService.fetchUser(clientId)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const searchClient = createAsyncThunk(
  'clients/searchClient',
  async (email, { rejectWithValue }) => {
    try {
      const client = PageLong.items.find((item) => item.email === email)

      return { client }
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const blockAccess = createAsyncThunk(
  'clients/blockAccess',
  async ({ id }, { rejectWithValue }) => {
    try {
      // eslint-disable-next-line no-console
      return console.log(`Block client ${id} access`)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const clientsAdapter = createEntityAdapter()

const initialState = clientsAdapter.getInitialState({
  entities: {},
  ids: [],
  inspectedClientId: undefined,
  total: undefined,
})

const clientSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    setInspectedClientId: (state, action) => {
      const clientId = action.payload
      state.inspectedClientId = clientId
    },
  },
  extraReducers: {
    [fetchClients.fulfilled]: (state, action) => {
      const { items: clients, total } = action.payload
      clientsAdapter.setAll(state, clients)
      state.total = total
    },
    [fetchClient.fulfilled]: (state, action) => {
      const clientId = prop('id', action.payload)

      state.entities[clientId] = action.payload
      state.inspectedClientId = clientId
    },
    [searchClient.fulfilled]: (state, action) => {
      const client = prop('client', action.payload)
      const clientId = prop('id', client)

      state.entities[clientId] = client
      state.inspectedClientId = clientId
    },
  },
})

export const { setInspectedClientId } = clientSlice.actions

const { reducer: clientReducer } = clientSlice
export { clientReducer }
