import { map, pipe, filter, isNotNilOrEmpty } from '@solta/ramda-extra'
import { belongTo, addMissingFields } from './helper'
import { financialInfo } from 'modules/application/initialFormValues'
import { LIABILITY } from 'features/broker/applications/CreateApplicationWizard/YourFinancialsSteps/YourExpensesAndLiabilitiesStep/constants'

const { ACCOUNTS } = LIABILITY

export const mapToLiabilitiesViewModel = (applicantId) => (liabilities) =>
  pipe(
    filter(belongTo(applicantId)),
    groupLiabilitiesByAccountName,
    mapLoanAccountsToViewModel,
    mapCreditCardAccountsToViewModel,
    formLiabilitiesViewModel,
    addMissingFields(financialInfo.liabilities)
  )(liabilities)

function groupLiabilitiesByAccountName(liabilityAccounts = []) {
  const loans = filter(
    (liability) => liability?.accountName === ACCOUNTS.PERSONAL_LOAN,
    liabilityAccounts
  )
  const creditCards = filter(
    (liability) => liability?.accountName === ACCOUNTS.CREDIT_CARD,
    liabilityAccounts
  )

  return {
    accounts: { loans, creditCards },
  }
}

function mapLoanAccountsToViewModel({ accounts, viewModel }) {
  const hasLoans = isNotNilOrEmpty(accounts?.loans)

  const mappedLoans = map(
    ({ id, accountAmount, repaymentAmount, description, ownership }) => ({
      id,
      type: description,
      ownership,
      outstandingBalance: accountAmount,
      repayments: repaymentAmount,
    }),
    accounts?.loans
  )
  const initialLoans = financialInfo.liabilities.personalLoan.loans

  const personalLoan = {
    isOwned: hasLoans,
    loans: hasLoans ? mappedLoans : initialLoans,
    confirmInfo: hasLoans,
  }

  return {
    accounts,
    viewModel: { ...viewModel, personalLoan },
  }
}

function mapCreditCardAccountsToViewModel({ accounts, viewModel }) {
  const hasCreditCards = isNotNilOrEmpty(accounts?.creditCards)

  const mappedCreditCards = map(
    ({ id, accountAmount, repaymentAmount, description, limit, ownership }) => ({
      id,
      provider: description,
      limit,
      ownership,
      outstandingBalance: accountAmount,
      repayments: repaymentAmount,
    }),
    accounts?.creditCards
  )
  const initialCreditCards = financialInfo.liabilities.creditCard.cards

  const creditCard = {
    isOwned: hasCreditCards,
    cards: hasCreditCards ? mappedCreditCards : initialCreditCards,
    confirmInfo: hasCreditCards,
  }

  return {
    accounts,
    viewModel: {
      ...viewModel,
      creditCard,
    },
  }
}

function formLiabilitiesViewModel({ viewModel }) {
  return {
    personalLoan: viewModel?.personalLoan,
    creditCard: viewModel?.creditCard,
  }
}
