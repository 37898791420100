import React, { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { AutoFill } from './AutoFill/AutoFill'
import { RadioButtonField, RadioButton } from '@vega/components'
import { Prompt } from 'features/broker/applications/components/typography'
import { s } from '@vega/styled'
import { FILL_METHOD } from './constants'
import { TEST_IDS } from '@neo/constants'

const { METHOD } = FILL_METHOD

function FillMethodView({
  autoFillIsSelected,
  parentPath,
  applicantId,
  applicantPathId,
  applicantName,
}) {
  const { setFieldTouched } = useFormikContext()

  useEffect(() => {
    setFieldTouched(`${parentPath}.fillMethod.method`, true)
  }, [parentPath, setFieldTouched])

  return (
    <>
      {autoFillIsSelected ? (
        <AutoFill
          applicantId={applicantId}
          applicantPathId={applicantPathId}
          parentPath={parentPath}
          applicantName={applicantName}
        />
      ) : (
        <>
          <Prompt style={s('mb-4')}>
            How would you like to continue with the financial section?
          </Prompt>
          <RadioButtonField
            name={`${parentPath}.fillMethod.method`}
            aria-label="fill option"
            containerStyle={s('w-full')}
            testId={TEST_IDS.FILL_METHOD_RB}
          >
            <RadioButton value={METHOD.AUTO_FILL}>Auto-fill</RadioButton>
            <RadioButton value={METHOD?.MANUAL_FILL}>Manual fill</RadioButton>
          </RadioButtonField>
        </>
      )}
    </>
  )
}

export { FillMethodView }
