import React, { useEffect, useCallback } from 'react'
import { useFormikContext } from 'formik'
import {
  Title,
  SectionTitle as SecTitle,
  Subtitle,
} from 'features/creditAssessor/applications/components/typography'
import { useWizard, Wizard } from '@vega/formik-wizard'
import { useHistory } from 'react-router-dom'
import { Expenses } from './Expense'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateCreditAssessorCheck,
  resetForm,
  selectProgressStepStatus,
} from 'modules/application'
import { FuturePlan } from './FuturePlan'
import { FormProgress } from '../../../components/FormProgress'
import { CreditAssessorCheckbox } from '../../../components/CreditAssessorCheckbox'
import { PersonalLoan, CreditCard } from './Liabilities'
import { InfoSidebar } from './InfoSidebar'
import { BottomNavigation } from './BottomNavigation'
import { routes } from 'routes'
import { styled, s } from '@vega/styled'
import { markProgressStepAsVisited } from 'features/creditAssessor/applications/components/FormProgress/utils'
import * as CONSTANTS from '@vega/constants'
import { TEST_IDS } from '@neo/constants'

const {
  FORM_PROGRESS: {
    STEP_NAMES: { EXPENSES_AND_LIABILITIES },
  },
} = CONSTANTS

const Root = styled.div(s('flex-1 flex items-center w-full h-full relative'))
const ProgressContainer = styled.div(s('bg-white h-full'))

const Core = styled.div(s('flex flex-column w-full h-full'))
const Content = styled.div(
  s('flex-1 flex justify-center w-full pt-6', {
    paddingRight: 200,
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  })
)
const FormContainer = styled.div(s('flex flex-column pt-1'), {
  width: 652,
  '> :last-child': s('pb-12'),
})
const InfoContainer = styled.div(s('relative', { pointerEvents: 'none' }))
const NavigationContainer = styled.div(s('relative zIndex-2'))

const SectionTitle = styled(SecTitle)(s('mt-5'))

function YourExpensesAndLiabilitiesStep({
  stepId,
  applicantName,
  applicantId,
  applicantPathId,
}) {
  const parentPath = `financials.${applicantPathId}`

  const history = useHistory()
  const dispatch = useDispatch()
  const {
    values: { creditAssessorCheck },
  } = useFormikContext()

  const saveAndContinue = (values) => {
    dispatch(updateCreditAssessorCheck(values.creditAssessorCheck))
  }

  const goToApplicationList = () =>
    history.replace(routes.creditAssessor.applications.list)

  const saveAndExit = () => {
    dispatch(updateCreditAssessorCheck(creditAssessorCheck))
    dispatch(resetForm())
    goToApplicationList()
  }

  const { previous, currentStep } = useWizard()
  const goBack = () => previous()

  const currentStepStatus = useSelector(
    selectProgressStepStatus(EXPENSES_AND_LIABILITIES, applicantId)
  )
  useEffect(() => {
    if (currentStep?.id === stepId)
      markProgressStepAsVisited(
        EXPENSES_AND_LIABILITIES,
        currentStepStatus,
        applicantId
      )
  }, [applicantId, currentStep, currentStepStatus, stepId])

  return (
    <Wizard.Step
      id={stepId}
      title="Your financials"
      onSubmit={useCallback(saveAndContinue, [])}
    >
      <Root>
        <ProgressContainer>
          <FormProgress saveAndExit={saveAndExit} />
        </ProgressContainer>

        <Core>
          <Content>
            <FormContainer>
              <Title style={s('mb-5')}>Let&apos;s talk financials</Title>
              <SectionTitle style={s('mt-0 mb-4')}>
                Next, {applicantName}&apos;s expenses
              </SectionTitle>
              <Subtitle style={s('mb-6')}>
                So we can get a better idea of {applicantName}&apos;s financial
                position, please tell us the expenses that apply to their household on a
                monthly basis. Estimates are fine, but the more accurate you can be, the
                better.
              </Subtitle>

              <Expenses
                applicantName={applicantName}
                parentPath={parentPath}
                applicantPathId={applicantPathId}
              />

              <PersonalLoan
                applicantName={applicantName}
                parentPath={parentPath}
                applicantPathId={applicantPathId}
              />

              <CreditCard
                applicantName={applicantName}
                parentPath={parentPath}
                applicantPathId={applicantPathId}
              />

              <FuturePlan
                applicantName={applicantName}
                parentPath={parentPath}
                applicantPathId={applicantPathId}
              />

              <CreditAssessorCheckbox
                name={`creditAssessorCheck.expensesAndLiabilities.${applicantId}.isVerified`}
                ariaLabel="Confirm information"
                testId={TEST_IDS.ASSESSOR_APP_REVIEW_CHECKBOX}
              />
            </FormContainer>

            <InfoContainer>
              <InfoSidebar />
            </InfoContainer>
          </Content>

          <NavigationContainer>
            <BottomNavigation goBack={goBack} canNext />
          </NavigationContainer>
        </Core>
      </Root>
    </Wizard.Step>
  )
}

export { YourExpensesAndLiabilitiesStep }
