import {
  defaultWhen,
  flatten,
  head,
  isNilOrEmpty,
  join,
  map,
  match,
  partition,
  pipe,
  replaceAll,
  split,
  test,
  trim,
} from '@solta/ramda-extra'

export const mapToAddressModel = ({ propertyAddress, propertyId }, addressType) => {
  const [streetAddress, suburb, city] = pipe(split(','), map(trim))(propertyAddress)

  const betweenParens = /(\(.+\))/
  const extraInfo = pipe(
    match(betweenParens),
    head,
    defaultWhen(isNilOrEmpty, '')
  )(streetAddress)

  const removeExtraInfo = pipe(replaceAll(extraInfo, ''), trim)

  const hasNumber = test(/[0-9]/)
  const [streetNumber, streetName] = pipe(
    removeExtraInfo,
    split(' '),
    partition(hasNumber),
    map(join(' ')),
    flatten
  )(streetAddress)

  return {
    streetNumber: trim(`${streetNumber} ${extraInfo}`),
    propertyId,
    streetName,
    suburb,
    city,
    countryISO: 'nz',
    addressType,
  }
}
