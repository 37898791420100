import React from 'react'
import { prop } from '@solta/ramda-extra'
import { useWizard } from '@vega/formik-wizard'
import { styled, s } from '@vega/styled'

const Step = styled.li(
  s(
    'no-decoration text-base text-grey-500 text-sm font-medium flex flex-column justify-between items-start'
  ),
  { cursor: 'pointer', lineHeight: 0.4 }
)
const Circle = styled.div(
  s('show-box border-grey-300 flex items-center mr-2 justify-center'),
  {
    borderRadius: '50%',
  },
  ({ isComplete }) =>
    isComplete &&
    s('text-green-700 bg-green-500 font-semibold border-0', {
      backgroundColor: 'rgba(88, 198, 189, 0.1)',
    }),
  ({ isCurrent }) => isCurrent && s('text-white font-semibold bg-green-700 border-0'),
  prop('circleStyles')
)
const Row = styled.div(s('flex items-center justify-center'))
const StepNumber = styled.p()
const Label = styled.p(s('mr-4'))

const getCircleStyleBySize = {
  sm: s('w-1 h-1'),
  lg: s('w-2 h-2'),
}

export const FormProgressLink = ({
  label,
  isComplete = false,
  isCurrent = false,
  stepNumber,
  stepId,
  size = 'sm',
  children,
  ...props
}) => {
  const { go } = useWizard()

  return (
    <Step onClick={() => go(stepId)} {...props}>
      <Row>
        <Circle
          isCurrent={isCurrent}
          isComplete={isComplete}
          circleStyles={getCircleStyleBySize[size]}
        >
          <StepNumber>{stepNumber}</StepNumber>
        </Circle>
        <Label>{label}</Label>
      </Row>
      {children}
    </Step>
  )
}
