import { useState, useCallback, useEffect } from 'react'
import { mergeDeepRight, noop } from '@solta/ramda-extra'

export const usePagedSearch = (
  fetchFuncToMemoize = noop,
  { searchTerm = '', pageIndex = 0, filters = {}, limit = 20, total = 0 } = {}
) => {
  const initialState = {
    searchTerm,
    pageIndex,
    filters,
    limit,
    total,
  }
  const [params, setParams] = useState(initialState)

  const memoizedFetch = useCallback(fetchFuncToMemoize, [])
  useEffect(() => {
    memoizedFetch({
      pageIndex: params.pageIndex,
      searchTerm: params.searchTerm,
      filters: params.filters,
      limit: params.limit,
    })
  }, [memoizedFetch, params.searchTerm, params.pageIndex, params.filters, params.limit])

  const useSetters = () => {
    const updateParams = useCallback((newParams) => {
      setParams((prev) => mergeDeepRight(prev, newParams))
    }, [])

    const resetAll = useCallback(() => {
      setParams(initialState)
    }, [])

    return {
      updateParams,
      resetAll,
    }
  }

  const usePagination = () => {
    const { limit, pageIndex } = params

    const setPageIndex = useCallback((pageIndex) => {
      setParams((prev) => ({ ...prev, pageIndex }))
    }, [])

    const gotoPage = useCallback(
      (idx) => {
        setPageIndex(idx)
      },
      [setPageIndex]
    )

    const nextPage = useCallback(() => {
      gotoPage(pageIndex + 1)
    }, [pageIndex, gotoPage])

    const previousPage = useCallback(() => {
      gotoPage(pageIndex - 1)
    }, [pageIndex, gotoPage])

    const pageCount = Math.ceil(total / limit)
    const canNextPage = pageIndex + 1 < pageCount
    const canPreviousPage = pageIndex > 0

    return {
      gotoPage,
      nextPage,
      previousPage,
      pageCount,
      canPreviousPage,
      canNextPage,
      pageIndex,
    }
  }

  return {
    currentSearchParams: params,
    paginationProps: usePagination(),
    setters: useSetters(),
  }
}
