import React, { useLayoutEffect } from 'react'
import { isNotNilOrEmpty } from '@solta/ramda-extra'
import { styled, s } from '@vega/styled'
import { Loading } from '@vega/components'
import { Title, Subtitle } from 'features/broker/applications/components/typography'
import { useFormikContext } from 'formik'
import { FillMethodView } from './FillMethodView'
import {
  selectGetFinancialsRetrievalMetadataStatus,
  getFinancialsRetrievalMetadata,
} from 'modules/application'
import { FILL_METHOD } from './constants'
import { useSelector, useDispatch } from 'react-redux'
import { ASYNC_STATUSES } from '@neo/constants'

const { PENDING } = ASYNC_STATUSES
const { METHOD } = FILL_METHOD

const DialogTitle = styled.span(s('mb-2 text-green-700 text-lg'))

const Dialog = styled.div(
  s(
    'w-full py-6 px-8 bg-white border-1 border-solid border-grey-200 rounded-b-lg rounded-tl-lg'
  )
)

function View({
  applicantName,
  parentPath,
  applicantId,
  applicantPathId,
  prefillDataHasBeenRetrieved,
}) {
  const dispatch = useDispatch()
  const { values } = useFormikContext()

  const fillMethod = values?.financials[applicantPathId]?.fillMethod?.method
  const isGettingFinancialsRetrievalMetadata =
    useSelector(selectGetFinancialsRetrievalMetadataStatus) === PENDING

  const autoFillIsSelected = fillMethod === METHOD.AUTO_FILL
  const autoFillMethodIsSelected = isNotNilOrEmpty(
    values?.financials[applicantPathId]?.fillMethod?.autoFillMethod
  )

  useLayoutEffect(() => {
    dispatch(getFinancialsRetrievalMetadata(applicantId))
  }, [applicantId, dispatch])

  if (isGettingFinancialsRetrievalMetadata) return <Loading />

  return (
    <div>
      <Title style={s('mb-4')}>Let&apos;s talk financials</Title>
      <Subtitle style={s('mb-6')}>
        To continue this section, there are two input types for {applicantName}&apos;s
        financial position. If you select manual, please prepare any banking documents,
        payslips, asset certificates, loan papers, and anything else that will help them
      </Subtitle>

      {prefillDataHasBeenRetrieved ? (
        <Dialog>
          <div style={s('flex items-center mb-4')}>
            <DialogTitle>Success!</DialogTitle>
          </div>
          <Subtitle>
            We have received {applicantName}&apos;s bank data from illion. You can go
            back to edit the information or click &apos;Next&apos; to confirm the bank
            details
          </Subtitle>
        </Dialog>
      ) : (
        <FillMethodView
          autoFillIsSelected={autoFillIsSelected || autoFillMethodIsSelected}
          parentPath={parentPath}
          applicantId={applicantId}
          applicantPathId={applicantPathId}
          applicantName={applicantName}
        />
      )}
    </div>
  )
}

export { View }
