import React from 'react'
import { styled, s } from '@vega/styled'
import { Subtitle, Prompt } from 'features/broker/applications/components/typography'
import { useDispatch, useSelector } from 'react-redux'
import {
  getFinancialsRetrievalData,
  selectFinancialsRetrievalMetadata,
} from 'modules/application'
import { useInterval } from './useInterval'
import { DottedCircleLoader } from '@vega/components'
import { LOAN_APPLICATION } from '@neo/constants'

const { FINANCIALS_RETRIEVAL_STATUS } = LOAN_APPLICATION

const Dialog = styled.div(
  s(
    'w-full py-6 px-8 mt-5 bg-white border-1 border-solid border-grey-200 rounded-b-lg rounded-tl-lg'
  )
)

const Row = styled.div(s('flex items-center mb-4'))

function SendEmailDialog({ applicantId }) {
  const POLLING_INTERVAL_MS = 30000

  const dispatch = useDispatch()

  const financialsRetrievalMetadata = useSelector(
    selectFinancialsRetrievalMetadata(applicantId)
  )
  const isRetrievingFinancialsData =
    financialsRetrievalMetadata?.status === FINANCIALS_RETRIEVAL_STATUS.STARTED

  useInterval(
    async () => {
      dispatch(getFinancialsRetrievalData(applicantId))
    },
    isRetrievingFinancialsData,
    POLLING_INTERVAL_MS
  )

  return (
    <Dialog>
      <Row>
        <Prompt>Invite sent!</Prompt>
        <DottedCircleLoader style={{ transform: 'translateX(-10px)' }} />
      </Row>
      <Subtitle>
        It will take 2-3 minutes to link your bank account. You can continue to the next
        step or wait for pre-fill process complete. We will let you know when the
        information is ready to review.
      </Subtitle>
    </Dialog>
  )
}

export { SendEmailDialog }
