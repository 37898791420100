import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchInvitations, selectInvitationList, selectTotal } from 'modules/invitation'

import { usePagedSearch } from 'hooks'

import { Table } from '@vega/components'
import { Rows, Columns } from './TableFormat'

import { styled, s } from '@vega/styled'
import { INVITATION_STATUSES, TEST_IDS } from '@neo/constants'

const Container = styled.div(
  s('flex flex-column w-full max-h-full bg-white h-full border-0 mx-4 mb-2'),
  {
    overflow: 'hidden',
  }
)

function PendingInvitation() {
  const dispatch = useDispatch()

  const invitationList = useSelector(selectInvitationList)
  const total = useSelector(selectTotal)

  const columns = Columns()
  const data = Rows(invitationList)

  const fetchData = (searchParams) => dispatch(fetchInvitations(searchParams))

  const { paginationProps } = usePagedSearch(fetchData, {
    total,
    pageIndex: 0,
    filters: {
      statuses: [
        INVITATION_STATUSES.INVITED,
        INVITATION_STATUSES.EXPIRED,
        INVITATION_STATUSES.REVOKED,
      ],
    },
  })

  return (
    <Container>
      <Table
        columns={columns}
        data={data}
        paginationProps={paginationProps}
        testId={TEST_IDS.ADMIN_USR_MANAGEMENT_PENDING_TABLE}
      />
    </Container>
  )
}

export default PendingInvitation
