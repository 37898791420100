import * as Validator from 'yup'
import { isTrue, values } from '@solta/ramda-extra'
import { LIABILITY } from './constants'

const { LOAN_TYPES } = LIABILITY

const { object, string, number, boolean, mixed, array } = Validator

const requiredString = string().required()
const requiredNumber = number().required()
const requiredBoolean = boolean().required()

const loanSchema = object({
  type: requiredString.oneOf(values(LOAN_TYPES)).label('Loan type'),
  ownership: requiredString.label('Loan applicant'),
  outstandingBalance: requiredNumber.min(0).label('Outstanding balance'),
  repayments: requiredNumber.min(0).label('Repayments'),
})

const creditCardSchema = object({
  ownership: requiredString.label('Credit Card Ownership'),
  outstandingBalance: requiredNumber.min(0).label('Credit Card Outstanding Balance'),
  repayments: requiredNumber.min(0).label('Credit Card Repayments'),
  limit: requiredNumber.min(0).label('Credit Card Limit'),
  provider: requiredString.label('Credit Card Provider'),
})

const expensesAndLiabilitiesSchema = object({
  expenses: object({
    housing: object({
      rent: object({
        value: number().min(0).label('Rent'),
        recurrence: requiredString.oneOf([
          'weekly',
          'fortnightly',
          'monthly',
          'quarterly',
        ]),
      }),
      food: object({
        value: number().min(0).label('Food And Groceries'),
        recurrence: requiredString.oneOf([
          'weekly',
          'fortnightly',
          'monthly',
          'quarterly',
        ]),
      }),
      insurance: object({
        value: number().min(0).label('Insurance'),
        recurrence: requiredString.oneOf([
          'weekly',
          'fortnightly',
          'monthly',
          'quarterly',
        ]),
      }),
      telecommunication: object({
        value: number().min(0).label('Subscriptions'),
        recurrence: requiredString.oneOf([
          'weekly',
          'fortnightly',
          'monthly',
          'quarterly',
        ]),
      }),
      propertyMaintenance: object({
        value: number().min(0).label('Property investment'),
        recurrence: requiredString.oneOf([
          'weekly',
          'fortnightly',
          'monthly',
          'quarterly',
        ]),
      }),
    }),
    personal: object({
      health: object({
        value: number().min(0).label('Medical and health'),
        recurrence: requiredString.oneOf([
          'weekly',
          'fortnightly',
          'monthly',
          'quarterly',
        ]),
      }),
      clothing: object({
        value: number().min(0).label('Clothing and personal care'),
        recurrence: requiredString.oneOf([
          'weekly',
          'fortnightly',
          'monthly',
          'quarterly',
        ]),
      }),
      entertainment: object({
        value: number().min(0).label('Recreation and entertainment'),
        recurrence: requiredString.oneOf([
          'weekly',
          'fortnightly',
          'monthly',
          'quarterly',
        ]),
      }),
      childcare: object({
        value: number().min(0).label('Childcare'),
        recurrence: requiredString.oneOf([
          'weekly',
          'fortnightly',
          'monthly',
          'quarterly',
        ]),
      }),
      childEducation: object({
        value: number().min(0).label('Children education'),
        recurrence: requiredString.oneOf([
          'weekly',
          'fortnightly',
          'monthly',
          'quarterly',
        ]),
      }),
    }),
    other: object({
      selfEducation: object({
        value: number().min(0).label('Self-education'),
        recurrence: requiredString.oneOf([
          'weekly',
          'fortnightly',
          'monthly',
          'quarterly',
        ]),
      }),
      transportation: object({
        value: number().min(0).label('Transportation'),
        recurrence: requiredString.oneOf([
          'weekly',
          'fortnightly',
          'monthly',
          'quarterly',
        ]),
      }),
    }),
    totalExpense: object({
      total: requiredNumber.min(0).label('Total Expense'),
      housing: requiredNumber.min(0).label('Housing Expense'),
      personal: requiredNumber.min(0).label('Personal Expense'),
      other: requiredNumber.min(0).label('Other Expense'),
    }),
  }),
  liabilities: object({
    personalLoan: object({
      isOwned: requiredBoolean.label('Loan ownership'),
      loans: mixed().when('isOwned', {
        is: (isOwned) => isOwned,
        then: array().required().of(loanSchema),
        otherwise: array().notRequired().nullable(),
      }),
      confirmInfo: mixed().when('isOwned', {
        is: (isOwned) => isOwned,
        then: boolean()
          .required()
          .test({
            test: (value) => isTrue(value),
            message: 'Please confirm the loan details is correct',
          })
          .label('This'),
        otherwise: boolean().notRequired().nullable(),
      }),
    }),
    creditCard: object({
      isOwned: requiredBoolean.label('Please tell us if you own any credit cards'),
      cards: mixed().when('isOwned', {
        is: (isOwned) => isOwned,
        then: array().required().of(creditCardSchema),
        otherwise: array().notRequired().nullable(),
      }),
      confirmInfo: mixed().when('isOwned', {
        is: (isOwned) => isOwned,
        then: boolean()
          .required()
          .test({
            test: (value) => isTrue(value),
            message: 'Please confirm the credit cards details is correct',
          })
          .label('This'),
        otherwise: boolean().notRequired().nullable(),
      }),
    }),
  }),
  futurePlan: object({
    hasAnticipatedChanges: requiredBoolean.label('Anticipated changes'),
    anticipatedEvent: mixed().when('hasAnticipatedChanges', {
      is: isTrue,
      then: requiredString.label('Anticipated event'),
      otherwise: string().notRequired(),
    }),
    adaptationPlan: mixed().when('hasAnticipatedChanges', {
      is: isTrue,
      then: requiredString.label('Adaptation Plan'),
      otherwise: string().notRequired(),
    }),
  }),
})

export const validationSchema = (applicantPathId) =>
  object().shape({
    financials: object({
      [applicantPathId]: expensesAndLiabilitiesSchema,
    }),
  })
