/* eslint-disable complexity */
import React from 'react'
import { s } from '@vega/styled'
import { Button } from '@vega/components'
import { isNil } from '@solta/ramda-extra'
import { Title, Subtitle } from 'features/broker/applications/components/typography'
import { CreditReportSummary } from './CreditReportSummary'
import {
  startCreditCheck,
  selectCreditReport,
  selectStartCreditCheckStatus,
} from 'modules/application'
import { useSelector, useDispatch } from 'react-redux'
import { ASYNC_STATUSES } from '@neo/constants'
import Loader from './CreditReportLoadingSection'

function View({ applicantPathId, applicantId }) {
  const dispatch = useDispatch()
  const creditCheckStartedStatus = useSelector(selectStartCreditCheckStatus())
  const creditReport = useSelector(selectCreditReport(applicantPathId))

  const isReportLoading = creditCheckStartedStatus === ASYNC_STATUSES.PENDING
  const isReportReady = creditCheckStartedStatus === ASYNC_STATUSES.FULFILLED

  return (
    <div>
      <Title style={s('mb-4')}>Let&apos;s have a look at the Credit Score</Title>
      <Subtitle style={s('mb-6')}>
        We can base the decision of proceeding forward with the application on the
        outcome of the Credit Score. We are a partner with Equifax, who provide us with
        the credit report.
      </Subtitle>

      {!isReportLoading && !isReportReady && isNil(creditReport) && (
        <>
          <p>Do you want to do the credit check now?</p>
          <Button
            size="lg"
            type="button"
            onClick={() => dispatch(startCreditCheck(applicantId))}
          >
            Check now
          </Button>
        </>
      )}

      {isReportLoading && <Loader />}

      {isReportReady && (
        <CreditReportSummary
          applicantPathId={applicantPathId}
          reportData={creditReport}
        />
      )}
    </div>
  )
}

export { View }
