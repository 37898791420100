import React from 'react'
import { useSelector } from 'react-redux'
import { styled, s } from '@vega/styled'
import { selectFormProgress, selectApplicantIds } from 'modules/application'
import { ProgressItemStatus } from './ProgressItemStatus'
import {
  checkStepStatus,
  checkIsSectionCompleted,
} from 'features/broker/applications/components/FormProgress/utils'
import { ITEM_STATUSES } from './constants'
import * as CONSTANTS from '@vega/constants'

const {
  FORM_PROGRESS: {
    STEP_STATUS: { COMPLETED },
  },
} = CONSTANTS

const { DEFAULT, VERIFIED, FAILED } = ITEM_STATUSES

const Container = styled.div(
  s(
    'flex bg-white p-6 border-solid border-1 border-grey-200 rounded-l-lg rounded-br-lg',
    { height: 72 }
  )
)
const FormStatus = styled(ProgressItemStatus)(s('mr-8'))

const FormProgress = ({ creditReportStatus }) => {
  const formProgress = useSelector(selectFormProgress)
  const applicantIds = useSelector(selectApplicantIds)
  const numOfApplicants = applicantIds.length

  const getStepStatus = (step) => (step ? VERIFIED : DEFAULT)

  const isStepCompleted = (stepName, applicantId) =>
    checkStepStatus(formProgress, stepName, COMPLETED, applicantId)

  const isSectionCompleted = (sectionName) =>
    checkIsSectionCompleted(formProgress, sectionName, numOfApplicants)

  const formCompletionMap = {
    details: isSectionCompleted('details'),
    financials: isSectionCompleted('financials'),
    goals: isStepCompleted('goals'),
    options: isStepCompleted('options'),
    preApproval: isStepCompleted('preApproval'),
  }

  const financialsStep = getStepStatus(formCompletionMap.financials)

  const getFinancialsStatus = (financialsStep, status = 'WARNING') =>
    status === 'FAILED' ? FAILED : financialsStep

  return (
    <Container>
      <FormStatus status={getStepStatus(formCompletionMap.details)}>
        Client Details
      </FormStatus>
      <FormStatus status={getFinancialsStatus(financialsStep, creditReportStatus)}>
        Client Financials
      </FormStatus>
      <FormStatus status={getStepStatus(formCompletionMap.goals)}>
        Client Goals
      </FormStatus>
      <FormStatus status={getStepStatus(formCompletionMap.options)}>
        Client Options
      </FormStatus>
      <FormStatus
        status={getStepStatus(formCompletionMap.preApproval)}
        style={s('mr-0')}
      >
        Pre-approval
      </FormStatus>
    </Container>
  )
}

export { FormProgress }
