/* eslint-disable complexity */
import React from 'react'
import { noop } from '@solta/ramda-extra'
import { Formik, Form } from 'formik'
import { Modal as ModalBase } from '@vega/components'
import { SubmitPropertyQuestions } from './SubmitPropertyQuestions'

function SubmitPropertyModal({
  isOpen,
  onClose = noop,
  openSavePropertyModal = noop,
  loanApplicationId,
  property,
}) {
  return (
    <ModalBase isOpen={isOpen} onClose={onClose}>
      <Formik
        enableReinitialize
        initialValues={{
          hasMadeOffer: undefined,
          hasWonOffer: undefined,
          isIntendedToSubmit: undefined,
        }}
        onSubmit={noop}
      >
        <Form>
          <SubmitPropertyQuestions
            openSavePropertyModal={openSavePropertyModal}
            onModalClose={onClose}
            loanApplicationId={loanApplicationId}
            propertyId={property?.propertyId}
          />
        </Form>
      </Formik>
    </ModalBase>
  )
}

export { SubmitPropertyModal }
