import { defaultTo, map, pipe } from '@solta/ramda-extra'
import * as CONSTANTS from '@vega/constants'
import PROPERTY_CONSTANTS from 'features/broker/applications/CreateApplicationWizard/YourFinancialsSteps/YourIncomeAndPropertiesStep/AssetsAndProperties/constants'

const { OWNERSHIP_TYPE, REPAYMENT_TYPE } = PROPERTY_CONSTANTS
const {
  LOAN_APPLICATION: {
    INTENT: { PROPERTY_PURPOSE },
  },
} = CONSTANTS

export const mapAssets = ({ cashSavings, shares, property }) => {
  const mapToFinancialProperties = pipe(
    defaultTo([]),
    mapRentalIncomeByPropertyPurpose,
    mapEquityPercentageByOwnership,
    mapLoanDetailsByLoanExistence,
    mapToPropertiesModel
  )

  const properties = property?.eligibility ? property?.properties : []

  return {
    cashSavings: cashSavings?.amount,
    sharesValue: shares.eligibility ? shares?.amount : 0,
    properties: mapToFinancialProperties(properties),
  }
}

function mapRentalIncomeByPropertyPurpose(properties) {
  return map((property) => {
    let mappedRentalIncome = property?.rentalIncome

    if (property?.propertyPurpose === PROPERTY_PURPOSE.LIVING) mappedRentalIncome = 0

    return {
      ...property,
      rentalIncome: mappedRentalIncome,
    }
  }, properties)
}

function mapEquityPercentageByOwnership(properties) {
  return map((property) => {
    let mappedEquityPercentage = property?.equityPercentage

    if (property?.ownershipStructure === OWNERSHIP_TYPE.CLIENT)
      mappedEquityPercentage = 100

    return {
      ...property,
      equityPercentage: mappedEquityPercentage,
    }
  }, properties)
}

function mapLoanDetailsByLoanExistence(properties) {
  return map((property) => {
    let mappedTotalLoan = property?.totalLoan
    let mappedLoanRepayment = property?.loanRepayment
    let mappedLoanRepaymentType = property?.loanRepaymentType

    if (property?.isPaidOff) {
      mappedTotalLoan = 0
      mappedLoanRepayment = 0
      mappedLoanRepaymentType = REPAYMENT_TYPE.NONE
    }

    return {
      ...property,
      totalLoan: mappedTotalLoan,
      loanRepayment: mappedLoanRepayment,
      loanRepaymentType: mappedLoanRepaymentType,
    }
  }, properties)
}

function mapToPropertiesModel(properties) {
  return map(
    ({
      propertyPurpose,
      rentalIncome,
      propertyAddress,
      estimatedPropertyValue,
      ownershipStructure,
      equityPercentage,
      isPaidOff,
      totalLoan,
      loanRepayment,
      loanRepaymentType,
      intendedAsSecurity,
    }) => ({
      propertyPurpose,
      rentalIncome,
      propertyId: propertyAddress?.propertyId,
      propertyAddress: propertyAddress?.propertyAddress,
      estimatedPropertyValue,
      ownershipStructure,
      equityPercentage,
      isPaidOff,
      totalLoan,
      loanRepayment,
      loanRepaymentType,
      intendedAsSecurity,
    }),
    properties
  )
}
