import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { noop } from '@solta/ramda-extra'
import { Modal as ModalBase, Button as ButtonBase } from '@vega/components'
import {
  PropertyInfo,
  TopNavBar,
  PropertyRejectedModal,
} from 'features/common/property'
import { PropertyApprovedModal } from './PropertyApprovedModal'
import { PropertyQueryModal } from './PropertyQueryModal'
import {
  approveProperty,
  selectInspectedPropertyId,
  selectPropertyById,
  selectApprovePropertyStatus,
} from 'modules/property'
import { styled, s } from '@vega/styled'
import { ASYNC_STATUSES } from '@neo/constants'

const { PENDING } = ASYNC_STATUSES

const Container = styled.div(
  s('flex flex-column items-center w-full px-10 bg-green-50', {
    height: '100vh',
    width: '100vw',
    overflowY: 'auto',
  })
)

const ContentContainer = styled.div(
  s('flex flex-column items-center w-full py-10', { maxWidth: 1200 })
)
const PropertyImage = styled.img(
  s('w-full object-cover', { borderRadius: '20px 0 20px 20px', maxHeight: 500 })
)

const DetailsContainer = styled.div(s('flex justify-between pt-8 px-24 w-full'))
const InfoContainer = styled.div(s('pr-4', { flexBasis: '80%' }))
const OperationContainer = styled.div(s('flex-1'))
const Button = styled(ButtonBase)(s('w-full border-grey-300 mb-3'))

function ReviewPropertyModal({ isOpen, onClose = noop }) {
  const dispatch = useDispatch()

  const propertyId = useSelector(selectInspectedPropertyId)
  const property = useSelector((state) => selectPropertyById(state, propertyId))
  const isApprovingProperty = useSelector(selectApprovePropertyStatus) === PENDING

  const [isApprovedModalOpen, setIsApprovedModalOpen] = useState(false)
  const [isRejectedModalOpen, setIsRejectedModalOpen] = useState(false)
  const [isQueryModalOpen, setIsQueryModalOpen] = useState(false)

  const onPropertyApproveBtnClicked = () => {
    const openPropertyApprovedModal = () => setIsApprovedModalOpen(true)

    dispatch(approveProperty({ callback: openPropertyApprovedModal }))
  }
  const onPropertyRejectBtnClicked = () => {
    setIsRejectedModalOpen(true)
  }
  const onAskQuestionBtnClicked = () => {
    setIsQueryModalOpen(true)
  }

  return (
    <ModalBase isOpen={isOpen} onClose={onClose}>
      <Container>
        <TopNavBar onClose={onClose} toPrevPage={onClose} />

        <ContentContainer>
          <PropertyImage src={property?.imageSrc} />

          <DetailsContainer>
            <InfoContainer>
              <PropertyInfo property={property} />
            </InfoContainer>

            <OperationContainer>
              <span style={s('block text-grey-800 mb-3')}>What is your decision?</span>

              <Button
                variant="outlined"
                onClick={onPropertyApproveBtnClicked}
                loading={isApprovingProperty}
              >
                Approve
              </Button>
              <Button variant="outlined" onClick={onPropertyRejectBtnClicked}>
                Reject
              </Button>
              <Button variant="outlined" onClick={onAskQuestionBtnClicked}>
                Ask a Question
              </Button>
            </OperationContainer>
          </DetailsContainer>
        </ContentContainer>

        <PropertyApprovedModal
          isOpen={isApprovedModalOpen}
          onClose={() => setIsApprovedModalOpen(false)}
        />
        <PropertyRejectedModal
          isOpen={isRejectedModalOpen}
          onClose={() => setIsRejectedModalOpen(false)}
        />
        <PropertyQueryModal
          isOpen={isQueryModalOpen}
          onClose={() => setIsQueryModalOpen(false)}
        />
      </Container>
    </ModalBase>
  )
}

export { ReviewPropertyModal }
