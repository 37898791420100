/* eslint-disable complexity */
import { map } from '@solta/ramda-extra'
import moment from 'moment'

const getOvertimeDetails = (
  overtimeEligible,
  overtimeAmount,
  overtimePaymentRecurrence
) => {
  if (overtimeEligible) {
    return { overtimeAmount, overtimePaymentRecurrence }
  }
  return {}
}

const mapEmployment = (employment) => {
  return map(
    ({
      employmentNature,
      employmentBasis,
      employerName,
      inRole,
      startDate,
      endDate,
      roleType,
      remunerationAmount,
      payRecurrence,
      overtimeEligible,
      overtimeAmount,
      overtimePaymentRecurrence,
      typeOfAdditionalPayment,
      bonusAmount,
      bonusRecurrence,
      commissionAmount,
      commissionRecurrence,
    }) => {
      const {
        overtimeAmount: mappedOvertimeAmount,
        overtimePaymentRecurrence: mappedOvertimePaymentRecurrence,
      } = getOvertimeDetails(
        overtimeEligible,
        overtimeAmount,
        overtimePaymentRecurrence
      )

      const mappedBonusAmount =
        typeOfAdditionalPayment === 'bonus' ? bonusAmount : undefined
      const mappedBonusRecurrence =
        typeOfAdditionalPayment === 'bonus' ? bonusRecurrence : undefined

      const mappedCommissionAmount =
        typeOfAdditionalPayment === 'commission' ? commissionAmount : undefined
      const mappedCommissionRecurrence =
        typeOfAdditionalPayment === 'commission' ? commissionRecurrence : undefined

      const mappedEndDate = inRole
        ? moment().toISOString()
        : moment(endDate, 'DD MM YYYY hh:mm:ss').toISOString()

      return {
        employmentNature,
        employmentBasis,
        employerName,
        inRole,
        startDate: moment(startDate, 'DD MM YYYY hh:mm:ss').toISOString(),
        endDate: mappedEndDate,
        roleType,
        remunerationAmount,
        payRecurrence,
        overtimeEligible,
        overtimeAmount: mappedOvertimeAmount,
        overtimePaymentRecurrence: mappedOvertimePaymentRecurrence,
        bonusEligible: Boolean(typeOfAdditionalPayment === 'bonus'),
        bonusAmount: mappedBonusAmount,
        bonusRecurrence: mappedBonusRecurrence,
        commissionEligible: Boolean(typeOfAdditionalPayment === 'commission'),
        commissionAmount: mappedCommissionAmount,
        commissionRecurrence: mappedCommissionRecurrence,
      }
    },
    employment
  )
}

export default ({ employment }) => ({
  employment: mapEmployment(employment),
})
