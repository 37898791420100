import React from 'react'
import { useSelector } from 'react-redux'
import { calculatePercentageChange } from 'utils/calculatePercentageChange'
import { ComparisonCard } from 'features/common/overview/ComparisonCard'
import { selectGetApplicationsSummaryReportStatus } from 'modules/report'
import { styled, s } from '@vega/styled'
import { ASYNC_STATUSES } from '@neo/constants'

const { PENDING } = ASYNC_STATUSES

const Container = styled.div(
  s('w-full flex items-center justify-between', {
    maxWidth: 1180,
  })
)

function ComparisonSection({
  summaryReportOfQueriedDate = {},
  summaryReportOfPrevMonth = {},
  dateComparedWith,
  ...otherProps
}) {
  const { prevYear, prevMonth } = dateComparedWith

  const isGettingSummaryReport =
    useSelector(selectGetApplicationsSummaryReportStatus) === PENDING

  const {
    totalApprovedApplications: totalApprovedAppsOfQueriedDate,
    totalRejectedApplications: totalRejectedAppsOfQueriedDate,
    totalPendingApplications: totalPendingAppsOfQueriedDate,
    totalLoanApplications: totalLoanAppsOfQueriedDate,
    percentageRate: percentageRateOfQueriedDate,
  } = summaryReportOfQueriedDate

  const {
    totalApprovedApplications: totalApprovedAppsOfPrevMonth,
    totalRejectedApplications: totalRejectedAppsOfPrevMonth,
    totalPendingApplications: totalPendingAppsOfPrevMonth,
    totalLoanApplications: totalLoanAppsOfPrevMonth,
  } = summaryReportOfPrevMonth

  return (
    <Container {...otherProps}>
      <ComparisonCard
        title="Total Applications"
        currentValue={totalLoanAppsOfQueriedDate}
        percentageChanges={calculatePercentageChange(
          totalLoanAppsOfQueriedDate,
          totalLoanAppsOfPrevMonth
        )}
        monthToBeComparedWith={prevMonth}
        yearToBeComparedWith={prevYear}
        isCurrentValuePercentage={false}
        isLoading={isGettingSummaryReport}
        containerStyle={s('mr-5')}
      />
      <ComparisonCard
        title="Success rate"
        currentValue={percentageRateOfQueriedDate?.approval}
        percentageChanges={calculatePercentageChange(
          totalApprovedAppsOfQueriedDate,
          totalApprovedAppsOfPrevMonth
        )}
        monthToBeComparedWith={prevMonth}
        yearToBeComparedWith={prevYear}
        isLoading={isGettingSummaryReport}
        containerStyle={s('mr-5')}
      />
      <ComparisonCard
        title="Rejected rate"
        currentValue={percentageRateOfQueriedDate?.rejection}
        percentageChanges={calculatePercentageChange(
          totalRejectedAppsOfQueriedDate,
          totalRejectedAppsOfPrevMonth
        )}
        monthToBeComparedWith={prevMonth}
        yearToBeComparedWith={prevYear}
        isLoading={isGettingSummaryReport}
        containerStyle={s('mr-5')}
      />
      <ComparisonCard
        title="Pending rate"
        currentValue={percentageRateOfQueriedDate?.pending}
        percentageChanges={calculatePercentageChange(
          totalPendingAppsOfQueriedDate,
          totalPendingAppsOfPrevMonth
        )}
        monthToBeComparedWith={prevMonth}
        yearToBeComparedWith={prevYear}
        isLoading={isGettingSummaryReport}
      />
    </Container>
  )
}

export { ComparisonSection }
