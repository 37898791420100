/* eslint-disable complexity */
import React from 'react'
import { useSelector } from 'react-redux'
import {
  selectDataFromInProgressReportByDayRange,
  selectGetApplicationsSummaryReportStatus,
  selectGetInProgressApplicationsReportStatus,
  selectGetApplicationsBreakdownReportStatus,
  selectApplicationsBreakdownReport,
} from 'modules/report'
import { calculateNumOfPendingAppsForBreakdown } from './utils/calculateNumOfPendingAppsForBreakdown'
import { ApplicationPieChart } from 'features/common/overview/ApplicationPieChart'

import { styled, s } from '@vega/styled'

import { ASYNC_STATUSES } from '@neo/constants'

const { PENDING } = ASYNC_STATUSES

const Container = styled.div(
  s('flex justify-between items-center w-full', { maxWidth: 1180 })
)

function ChartSection({ summaryReportOfQueriedDate = {}, ...otherProps }) {
  const { loanPurposeSummary } = summaryReportOfQueriedDate

  const isGettingSummaryReport =
    useSelector(selectGetApplicationsSummaryReportStatus) === PENDING
  const isGettingInProgressApplicationsReport =
    useSelector(selectGetInProgressApplicationsReportStatus) === PENDING
  const isGettingApplicationsBreakdownReport =
    useSelector(selectGetApplicationsBreakdownReportStatus) === PENDING

  // ============ Purpose chart data ============
  const applicationPurposeChartData = [
    {
      label: 'Refinance',
      frequency: loanPurposeSummary?.numOfRefinance,
      color: '#3CAFA5',
    },
    {
      label: 'Purchase',
      frequency: loanPurposeSummary?.numOfPurchase,
      color: '#E7E481',
    },
  ]

  const totalPurposeAmount =
    loanPurposeSummary?.numOfRefinance + loanPurposeSummary?.numOfPurchase

  // ============ In progress chart data ============
  const inProgressDataForFiveToTenDays = useSelector(
    selectDataFromInProgressReportByDayRange(5, 10)
  )
  const inProgressDataForElevenToTwentyDays = useSelector(
    selectDataFromInProgressReportByDayRange(11, 20)
  )
  const inProgressDataForMoreThanTwentyDays = useSelector(
    selectDataFromInProgressReportByDayRange(20, undefined)
  )

  const inProgressApplicationsChartData = [
    {
      label: '5 - 10 days',
      frequency: inProgressDataForFiveToTenDays?.value,
      color: '#3CAFA5',
    },
    {
      label: '11 - 20 days',
      frequency: inProgressDataForElevenToTwentyDays?.value,
      color: '#87CF91',
    },
    {
      label: '20 days +',
      frequency: inProgressDataForMoreThanTwentyDays?.value,
      color: '#E7E481',
    },
  ]

  const totalInProgressApplications =
    inProgressDataForFiveToTenDays?.value +
    inProgressDataForElevenToTwentyDays?.value +
    inProgressDataForMoreThanTwentyDays?.value

  // ============ Applications breakdown chart data ===========

  const applicationsBreakdownReport = useSelector(selectApplicationsBreakdownReport)
  const numOfPendingApplications = calculateNumOfPendingAppsForBreakdown(
    applicationsBreakdownReport
  )

  const applicationsBreakdownChartData = [
    {
      label: 'Affordability Pre-approval',
      frequency: applicationsBreakdownReport?.affordability_pre_approved?.cnt || 0,
      color: '#3CAFA5',
    },
    {
      label: 'Property Pre-approved',
      frequency: applicationsBreakdownReport?.property_pre_approved?.cnt || 0,
      color: '#87CF91',
    },
    {
      label: 'Final approved',
      frequency: applicationsBreakdownReport?.unconditional_offer?.cnt || 0,
      color: '#E7E481',
    },
    {
      label: 'Rejected',
      frequency: applicationsBreakdownReport?.rejected?.cnt || 0,
      color: '#EB954C',
    },
    {
      label: 'Pending',
      frequency: numOfPendingApplications,
      color: '#DE425B',
    },
  ]

  return (
    <Container {...otherProps}>
      <ApplicationPieChart
        chartData={applicationsBreakdownChartData}
        title="Application breakdown"
        isLoading={isGettingApplicationsBreakdownReport}
        categoryListStyle={{ transform: 'translate(15px, -25px)' }}
      />

      <ApplicationPieChart
        chartData={inProgressApplicationsChartData}
        title="Appl. pending period"
        isLoading={isGettingInProgressApplicationsReport}
        total={totalInProgressApplications}
        showTotalApplicationsIndicator
        categoryListStyle={{ transform: 'translate(15px, -50px)' }}
      />

      <ApplicationPieChart
        chartData={applicationPurposeChartData}
        title="Appl. purpose"
        isLoading={isGettingSummaryReport}
        total={totalPurposeAmount}
        showTotalApplicationsIndicator
        categoryListStyle={{ transform: 'translate(15px, -62px)' }}
      />
    </Container>
  )
}

export { ChartSection }
