import store from 'app/store'
import { updateFormProgress } from 'modules/application'
import * as CONSTANTS from '@vega/constants'
import { includes } from '@solta/ramda-extra'

const {
  FORM_PROGRESS: {
    STEP_STATUS: { VISITED, COMPLETED },
  },
} = CONSTANTS

const visitedStatuses = [VISITED, COMPLETED]

const prevStepStatusIsNotVisited = (prevStepStatus, visitedStatuses) =>
  !includes(prevStepStatus, visitedStatuses)

export const markProgressStepAsVisited = (stepName, prevStepStatus, applicantId) => {
  if (prevStepStatusIsNotVisited(prevStepStatus, visitedStatuses))
    store.dispatch(updateFormProgress({ step: stepName, status: VISITED, applicantId }))
}
