/* eslint-disable react/no-multi-comp */
import React from 'react'
import PropTypes from 'prop-types'
import { prop, pipe } from '@solta/ramda-extra'
import { ReactComponent as DownArrow } from '@vega/components/src/assets/images/stockArrowDown.svg'
import { ReactComponent as UpArrow } from '@vega/components/src/assets/images/stockArrowTop.svg'
import { DottedCircleLoader } from '@vega/components'
import { styled, s } from '@vega/styled'

const { object, bool, string, number, oneOfType } = PropTypes

const Container = styled.div(
  s(
    'flex flex-column justify-between bg-white border-1 border-solid border-grey-300 rounded-lg px-6 py-4',
    {
      width: 300,
      height: 110,
    }
  ),
  prop('containerStyle')
)

const Title = styled.span(s('text-grey-600'))
const PercentageChange = styled.span(s('text-base text-error-700'))
const Month = styled.span(s('text-grey-700 font-semibold'))

const CurrentValue = styled.span(s('text-grey-700 text-3xl'))
const Percentage = styled.span(s('text-base'))

const Row = styled.div(s('flex'))
const Column = styled.div(s('flex flex-column'))

const getChangesStatus = (changes) => {
  if (changes === 'N/A') return 'notApplicable'
  if (changes > 0) return 'positive'
  if (changes < 0) return 'negative'
  return 'unchanged'
}

const changesValueStylesByStatus = {
  positive: s('text-success-700', { marginRight: 1 }),
  negative: s('text-error-700', { marginRight: 1 }),
  unchanged: s('text-grey-500'),
  notApplicable: s('text-grey-500'),
}

const arrowIconByChangesStatus = {
  positive: <UpArrow style={s('text-success-600')} />,
  negative: <DownArrow style={s('text-error-600')} />,
}

const monthNameByNumber = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
}

const defaultNaNToZero = (v) => {
  if (Number.isNaN(v) || v === 'NaN') return 0
  return v
}

const defaultInfiniteValueToInapplicable = (v) => {
  if (v === Infinity) return 'N/A'
  return v
}

function ComparisonCard({
  title,
  currentValue = 0,
  monthToBeComparedWith,
  percentageChanges,
  isCurrentValuePercentage = true,
  isLoading = false,
  containerStyle,
  ...otherProps
}) {
  const formattedPercentageChanges = pipe(
    defaultNaNToZero,
    defaultInfiniteValueToInapplicable
  )(percentageChanges)

  const showChangesPercentSign = formattedPercentageChanges !== 'N/A'
  const changesStatus = getChangesStatus(formattedPercentageChanges)

  if (isLoading)
    return (
      <Container
        style={s('flex items-center justify-center')}
        containerStyle={containerStyle}
      >
        <DottedCircleLoader size="md" />
      </Container>
    )

  return (
    <Container containerStyle={containerStyle} {...otherProps}>
      <Row style={s('justify-between')}>
        <Title>{title}</Title>

        <Column style={s('items-end')}>
          <Row style={s('justify-end items-center')}>
            <PercentageChange style={changesValueStylesByStatus[changesStatus]}>
              {formattedPercentageChanges}
              {showChangesPercentSign && '%'}
            </PercentageChange>
            {arrowIconByChangesStatus[changesStatus]}
          </Row>
          <span style={s('text-grey-500', { fontSize: 10 })}>
            Compared to <Month>{monthNameByNumber[monthToBeComparedWith]}</Month>
          </span>
        </Column>
      </Row>

      <CurrentValue>
        {currentValue}
        {isCurrentValuePercentage && <Percentage>%</Percentage>}
      </CurrentValue>
    </Container>
  )
}

ComparisonCard.propTypes = {
  title: string,
  currentValue: number,
  monthToBeComparedWith: oneOfType([string, number]),
  percentageChanges: oneOfType([string, number]),
  isPercentage: bool,
  isLoading: bool,
  containerStyle: object,
}

export { ComparisonCard }
