import { mergeDeepRight, isNilOrEmpty, find, defaultTo, pipe } from '@solta/ramda-extra'
import { financialInfo } from 'modules/application/initialFormValues'

const lookUpRetrievalMetadataByApplicantId = (applicantId) => (financialsMetadata) =>
  find((d) => d.applicantId === applicantId)(
    defaultTo([], financialsMetadata?.dataRetrieval)
  )

const extractAccessMethod = (dataRetrievalMetadataOfApplicant) => {
  return dataRetrievalMetadataOfApplicant?.accessMethod
}
const mergeToInitialFillMethod = (accessMethod) =>
  mergeDeepRight(financialInfo.fillMethod, {
    autoFillMethod: accessMethod,
  })

// final mapper for fillMethod
export const mapToFillMethodViewModel = (applicantId) => (financialsMetadata = {}) => {
  if (isNilOrEmpty(applicantId)) {
    return financialInfo.fillMethod
  }

  return pipe(
    lookUpRetrievalMetadataByApplicantId(applicantId),
    extractAccessMethod,
    mergeToInitialFillMethod
  )(financialsMetadata)
}
