import { clone } from '@solta/ramda-extra'
import * as CONSTANTS from '@vega/constants'

const {
  FORM_PROGRESS: { STEP_STATUS },
} = CONSTANTS

export const detailsViewModel = {
  firstName: undefined,
  lastName: undefined,
  middleName: undefined,
  preferredName: undefined,
  dob: undefined,
  gender: undefined,
  marital: undefined,
  numOfDependants: 0,
  currentAddress: { propertyId: undefined, propertyAddress: undefined },
  mailingAddress: { propertyId: undefined, propertyAddress: undefined },
  livingArrangement: undefined,
  email: undefined,
  phoneNumber: undefined,
  isNZPR: undefined,
  citizenCountry: undefined,
  residentCountry: undefined,
  residencyStatus: undefined,
  documents: {
    passport: {
      passportNumber: undefined,
      countryOfOrigin: undefined,
      expiryDate: undefined,
      files: [],
      isVerified: undefined,
    },
    driverLicence: {
      licenceNumber: undefined,
      class: undefined,
      expiryDate: undefined,
      files: [],
      isVerified: undefined,
    },
  },
}

const details = {
  applicant1: clone(detailsViewModel),
  applicant2: clone(detailsViewModel),
}

export const financialInfo = {
  fillMethod: { method: undefined, autoFillMethod: undefined },
  employment: [
    {
      employmentNature: undefined,
      employmentBasis: undefined,
      employerName: undefined,
      inRole: false,
      startDate: undefined,
      endDate: undefined,
      roleType: undefined,
      remunerationAmount: undefined,
      payRecurrence: undefined,
      overtimeEligible: undefined,
      overtimeAmount: undefined,
      overtimePaymentRecurrence: undefined,
      typeOfAdditionalPayment: 'no',
      bonusAmount: undefined,
      bonusRecurrence: undefined,
      commissionAmount: undefined,
      commissionRecurrence: undefined,
    },
  ],
  salary: {
    id: undefined,
    amount: undefined,
  },
  income: {
    superannuation: {
      id: undefined,
      eligibility: undefined,
      amount: undefined,
      recurrence: undefined,
    },
    governmentPayment: {
      id: undefined,
      eligibility: undefined,
      amount: undefined,
      recurrence: undefined,
    },
    dividends: {
      id: undefined,
      eligibility: undefined,
      amount: undefined,
      recurrence: undefined,
    },
    companyProfitBeforeTax: {
      id: undefined,
      eligibility: undefined,
      amount: undefined,
      recurrence: undefined,
    },
    interestEarned: {
      id: undefined,
      eligibility: undefined,
      amount: undefined,
      recurrence: undefined,
    },
    childSupport: {
      id: undefined,
      eligibility: undefined,
      amount: undefined,
      recurrence: undefined,
    },
  },
  assets: {
    cashSavings: {
      id: undefined,
      amount: undefined,
    },
    shares: { eligibility: undefined, id: undefined, amount: undefined },
    property: {
      eligibility: undefined,
      properties: [
        {
          id: undefined,
          propertyPurpose: undefined,
          rentalIncome: undefined,
          propertyAddress: { propertyId: undefined, propertyAddress: undefined },
          estimatedPropertyValue: undefined,
          ownershipStructure: undefined,
          equityPercentage: undefined,
          isPaidOff: undefined,
          totalLoan: undefined,
          loanRepayment: undefined,
          loanRepaymentType: undefined,
          intendedAsSecurity: undefined,
        },
      ],
    },
  },
  expenses: {
    housing: {
      rent: { id: undefined, value: undefined, recurrence: 'monthly' },
      food: { id: undefined, value: undefined, recurrence: 'monthly' },
      insurance: { id: undefined, value: undefined, recurrence: 'monthly' },
      telecommunication: { id: undefined, value: undefined, recurrence: 'monthly' },
      propertyMaintenance: { id: undefined, value: undefined, recurrence: 'monthly' },
    },
    personal: {
      health: { id: undefined, value: undefined, recurrence: 'monthly' },
      clothing: { id: undefined, value: undefined, recurrence: 'monthly' },
      entertainment: { id: undefined, value: undefined, recurrence: 'monthly' },
      childcare: { id: undefined, value: undefined, recurrence: 'monthly' },
      childEducation: { id: undefined, value: undefined, recurrence: 'monthly' },
    },
    other: {
      selfEducation: { id: undefined, value: undefined, recurrence: 'monthly' },
      transportation: { id: undefined, value: undefined, recurrence: 'monthly' },
    },
    totalExpense: {
      total: 0,
      housing: 0,
      personal: 0,
      other: 0,
    },
  },
  liabilities: {
    personalLoan: {
      isOwned: undefined,
      loans: [
        {
          id: undefined,
          type: undefined,
          ownership: undefined,
          outstandingBalance: undefined,
          repayments: undefined,
        },
      ],
      confirmInfo: false,
    },
    creditCard: {
      isOwned: undefined,
      cards: [
        {
          id: undefined,
          ownership: undefined,
          outstandingBalance: undefined,
          repayments: undefined,
          limit: undefined,
          provider: undefined,
        },
      ],
      confirmInfo: false,
    },
  },
  creditReport: {
    creditScores: {
      primaryScore: undefined,
      scorecardName: undefined,
      scorecardVersion: undefined,
      riskRank: undefined,
      riskIndex: undefined,
      riskOdds: undefined,
    },
    creditAccounts: [
      {
        industryType: undefined,
        limit: undefined,
        provider: undefined,
        productType: undefined,
        accountNumber: undefined,
        accountStatus: undefined,
        dateOpened: undefined,
        dateUpdated: undefined,
        responsibility: undefined,
        paymentFrequency: undefined,
        identity: undefined,
      },
    ],
    profile: {
      firstName: undefined,
      lastName: undefined,
      middleName: undefined,
      dob: undefined,
      gender: undefined,
      addresses: [
        {
          streetNumber: undefined,
          streetName: undefined,
          suburb: undefined,
          city: undefined,
        },
      ],
    },
  },
  futurePlan: {
    hasAnticipatedChanges: undefined,
    anticipatedEvent: undefined,
    adaptationPlan: undefined,
  },
}

const financials = {
  applicant1: clone(financialInfo),
  applicant2: clone(financialInfo),
}

const intent = {
  loanPurpose: undefined,
  propertyPurpose: undefined,
  estimatedPropertyValue: undefined,
  borrowingAmount: undefined,
  expectedRentAmount: undefined,
}

const structure = {
  loanFlexibilityPreference: 1,
  loanRateType: 'floating',
  fixedLoanRateTerm: undefined,
  repaymentType: undefined,
  repaymentFrequency: undefined,
  maxLoanTerm: undefined,
  loanTerm: undefined,
  offsetAccount: undefined,
}

const preApproval = {
  formHasCorrectInfo: false,
  applicantApprovesLoan: false,
  /* applicants consent example

    "6115d6348d4337001ce5c912": {
    "confirmedDetailsCorrect": true,
    "confirmedLoanOptions": true,
    "understoodPartialSubmission": true,
    "_id": "6115dc73a42fa447437ce5dc"
    }
  */
  applicants: {},
}

const status = {
  progress: {
    clientInvitation: STEP_STATUS.UNVISITED,
    clientDetails: [],
    clientIdentification: [],
    financialsFillMethodSelection: [],
    creditReport: [],
    incomeAndAssets: [],
    expensesAndLiabilities: [],
    applicantApproval: [],
    goals: STEP_STATUS.UNVISITED,
    options: STEP_STATUS.UNVISITED,
    preApproval: STEP_STATUS.UNVISITED,
  },
  currentStepId: 'details.invitation',
  stage: 'draft',
}

const creditAssessorCheck = {
  clientDetails: {},
  clientIdentification: {},
  creditReport: {},
  incomeAndAssets: {},
  expensesAndLiabilities: {},
  goals: { isVerified: false },
  options: { isVerified: false },
  loanOptions: { isVerified: false },
  preApproval: { isVerified: false },
}

const numOfCoApplicants = 0

export const applicantApprovalTemplate = {
  decision: undefined,
  idVerified: undefined,
  creditCheckVerified: undefined,
  financialsVerified: undefined,
  rejectionReason: undefined,
}

export const invitationTemplate = {
  isNew: undefined,
  isPrimary: false,
  firstName: undefined,
  email: undefined,
}

const invitations = [invitationTemplate]

const applicantApproval = { applicant1: applicantApprovalTemplate }

export default {
  invitations,
  details,
  financials,
  intent,
  structure,
  preApproval,
  status,
  creditAssessorCheck,
  numOfCoApplicants,
  applicantApproval,
}
