import React, { useEffect, useMemo } from 'react'
import { styled, s } from '@vega/styled'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateFormProgress,
  resetForm,
  selectAddApplicantsToApplicationStatus,
  selectFinancialsRetrievalMetadata,
  selectProgressStepStatus,
} from 'modules/application'
import { useFormikContext } from 'formik'
import { routes } from 'routes'
import { useHistory } from 'react-router-dom'
import { FormProgress } from 'features/broker/applications/components/FormProgress'
import { Wizard, useWizard } from '@vega/formik-wizard'
import { InfoSidebar } from './InfoSidebar'
import { BottomNavigation } from './BottomNavigation'
import { Loading } from '@vega/components'
import { View } from './View'
import { validationSchema } from './validationSchema'
import {
  ifElse,
  isTrue,
  isNotNilOrEmpty,
  and,
  isFalse,
  isNilOrEmpty,
} from '@solta/ramda-extra'
import { ASYNC_STATUSES, LOAN_APPLICATION } from '@neo/constants'
import * as CONSTANTS from '@vega/constants'
import { markProgressStepAsVisited } from 'features/broker/applications/components/FormProgress/utils'

const {
  FORM_PROGRESS: {
    STEP_STATUS: { VISITED, COMPLETED },
    STEP_NAMES: { FILL_METHOD_SELECTION },
  },
} = CONSTANTS

const { PENDING } = ASYNC_STATUSES
const { FINANCIALS_RETRIEVAL_STATUS } = LOAN_APPLICATION

const Root = styled.div(s('flex-1 flex items-center w-full h-full relative'))
const ProgressContainer = styled.div(s('bg-white h-full'))

const Core = styled.div(s('flex flex-column w-full h-full'))
const Content = styled.div(
  s('flex-1 flex justify-center w-full pt-6', {
    paddingRight: 200,
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  })
)
const FormContainer = styled.div(s('flex flex-column pt-1'), {
  width: 652,
  '> :last-child': s('pb-12'),
})
const InfoContainer = styled.div(s('relative'))
const NavigationContainer = styled.div(s('relative zIndex-2'))

function YourFillOptionsStep({
  stepId,
  applicantName,
  applicantId,
  applicantPathId,
  isLastApplicant = false,
}) {
  const parentPath = `financials.${applicantPathId}`
  const history = useHistory()
  const dispatch = useDispatch()
  const { next, go, previous, currentStep } = useWizard()
  const { values, errors, isSubmitting } = useFormikContext()

  const autoFillMethodIsSelected = isNotNilOrEmpty(
    values?.financials?.[applicantPathId]?.fillMethod?.autoFillMethod
  )
  const isCreatingAndAddApplicantsStatus =
    useSelector(selectAddApplicantsToApplicationStatus) === PENDING
  const financialsRetrievalMetadata = useSelector(
    selectFinancialsRetrievalMetadata(applicantId)
  )
  const prefillDataHasBeenRetrieved =
    financialsRetrievalMetadata?.status === FINANCIALS_RETRIEVAL_STATUS.COMPLETED

  const goToApplicationList = () => history.replace(routes.broker.applications.list)

  const memorizedValidationSchema = useMemo(
    () => validationSchema(applicantPathId, prefillDataHasBeenRetrieved),
    [applicantPathId, prefillDataHasBeenRetrieved]
  )

  const goToGoalsStep = () => go('goals')

  const goToNextApplicantFillMethodStep = () =>
    go('financials.your-fill-methods-applicant2')

  const saveAndSkipFinancialsStep = () => {
    dispatch(
      updateFormProgress({
        step: FILL_METHOD_SELECTION,
        status: VISITED,
        applicantId,
      })
    )

    ifElse(isTrue, goToGoalsStep, goToNextApplicantFillMethodStep)(isLastApplicant)
  }

  const saveAndContinue = () => {
    dispatch(
      updateFormProgress({
        step: FILL_METHOD_SELECTION,
        status: COMPLETED,
        applicantId,
      })
    )
    next()
  }

  const onSubmit = () =>
    ifElse(
      isTrue,
      saveAndSkipFinancialsStep,
      saveAndContinue
    )(and(autoFillMethodIsSelected, isFalse(prefillDataHasBeenRetrieved)))

  const saveAndExit = () => {
    dispatch(resetForm())
    goToApplicationList()
  }

  const currentStepStatus = useSelector(
    selectProgressStepStatus(FILL_METHOD_SELECTION, applicantId)
  )
  useEffect(() => {
    if (currentStep?.id === stepId)
      markProgressStepAsVisited(FILL_METHOD_SELECTION, currentStepStatus, applicantId)
  }, [applicantId, currentStep, currentStepStatus, stepId])

  if (isCreatingAndAddApplicantsStatus) return <Loading />

  const canNext = isNilOrEmpty(errors?.financials?.[applicantPathId]?.fillMethod)
  const goBack = () => previous()

  return (
    <Wizard.Step
      id={stepId}
      title="Your Fill Options"
      validationSchema={memorizedValidationSchema}
    >
      <Root>
        <ProgressContainer>
          <FormProgress saveAndExit={saveAndExit} />
        </ProgressContainer>

        <Core>
          <Content>
            <FormContainer>
              <View
                applicantName={applicantName}
                applicantId={applicantId}
                applicantPathId={applicantPathId}
                parentPath={parentPath}
                prefillDataHasBeenRetrieved={prefillDataHasBeenRetrieved}
              />
            </FormContainer>

            <InfoContainer>
              <InfoSidebar />
            </InfoContainer>
          </Content>

          <NavigationContainer>
            <BottomNavigation
              goBack={goBack}
              canNext={canNext}
              onNext={onSubmit}
              isLoading={isSubmitting}
            />
          </NavigationContainer>
        </Core>
      </Root>
    </Wizard.Step>
  )
}

export { YourFillOptionsStep }
