import {
  all,
  clone,
  isNilOrEmpty,
  eqProps,
  isNotNil,
  isNotNilOrEmpty,
  isTrue,
  replaceAll,
} from '@solta/ramda-extra'

const hasDefinedAddress = (prop) => isNotNil(prop?.propertyAddress)
export const sameAddresses = (current = {}, mailing = {}) =>
  all(isTrue)([
    hasDefinedAddress(current),
    hasDefinedAddress(mailing),
    eqProps('propertyId')(current, mailing),
  ])

export const removePhoneNumberSpaces = (values, applicantPathId) => {
  const removeSpaces = replaceAll(' ', '')
  const copy = clone(values)
  const applicantPhoneNumber = copy?.details?.[applicantPathId].phoneNumber

  if (isNotNilOrEmpty(applicantPhoneNumber)) {
    copy.details[applicantPathId].phoneNumber = removeSpaces(applicantPhoneNumber)
  }

  return copy
}

export const formatPhoneNumber = (phone) => {
  // normalize string and remove all unnecessary characters
  if (isNilOrEmpty(phone)) return phone
  phone = phone.replace(/[^\d]/g, '')

  // check if number length equals to 10
  if (phone.length === 10) {
    // reformat and return phone number
    return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
  }

  return phone

  // var phone = '(123)4567890';
  // phone = normalize(phone); //(123) 456-7890
}
