import React from 'react'
import { Formik, Form } from 'formik'
import * as Validator from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { noop } from '@solta/ramda-extra'
import { ReactComponent as X } from '@vega/components/src/assets/images/x.svg'
import { Modal as ModalBase, Button, FormTextarea } from '@vega/components'
import { rejectProperty, selectRejectPropertyStatus } from 'modules/property'
import { styled, s } from '@vega/styled'
import { ASYNC_STATUSES } from '@neo/constants'

const { PENDING } = ASYNC_STATUSES
const { string } = Validator

const Container = styled(Form)(
  s('flex flex-column bg-white p-4 w-48', { borderRadius: 12 })
)
const Header = styled.div(s('w-full flex justify-end mb-4'))
const Body = styled.div(s('pb-8 pl-6 pr-10'))

const Title = styled.span(s('block text-grey-800 font-semibold text-2xl mb-6'))

function PropertyRejectedModal({ isOpen, onClose = noop }) {
  const dispatch = useDispatch()
  const isRejectingProperty = useSelector(selectRejectPropertyStatus) === PENDING

  return (
    <ModalBase isOpen={isOpen} onClose={onClose}>
      <Formik
        enableReinitialize
        initialValues={{
          rejectReason: '',
        }}
        validationSchema={Validator.object({
          rejectReason: string()
            .required('Please provide the reason why you reject this property')
            .label('Reject reason'),
        })}
        onSubmit={({ rejectReason }) => dispatch(rejectProperty({ rejectReason }))}
      >
        <Container>
          <Header>
            <X onClick={onClose} style={{ cursor: 'pointer' }} />
          </Header>

          <Body>
            <Title>Please provide the reason why you reject this property</Title>

            <FormTextarea
              name="rejectReason"
              placeholder="Type your reasons"
              rows={5}
              containerStyle={s('mb-4')}
            />

            <Button type="submit" style={s('w-8')} loading={isRejectingProperty}>
              Submit
            </Button>
          </Body>
        </Container>
      </Formik>
    </ModalBase>
  )
}

export { PropertyRejectedModal }
