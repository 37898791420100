import React from 'react'
import { styled, s } from '@vega/styled'
import { FormInput } from '@vega/components'
import { TEST_IDS } from '@neo/constants'

const EmailInput = styled(FormInput)(s('mb-8'))

function View() {
  return (
    <>
      <FormInput
        testId={TEST_IDS.BROKER_INVITE_NAME_INPUT}
        label="Given name"
        name="firstName"
        baseContainerStyle={s('pr-0')}
        style={s('mb-3')}
      />
      <EmailInput
        testId={TEST_IDS.BROKER_INVITE_EMAIL_INPUT}
        label="Email"
        name="email"
        baseContainerStyle={s('pr-0')}
      />
    </>
  )
}

export { View }
