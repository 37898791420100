import React from 'react'
import moment from 'moment'
import { isNilOrEmpty } from '@solta/ramda-extra'
import { ReactComponent as BedroomIcon } from '@vega/components/src/assets/images/bedroom.svg'
import { ReactComponent as BathroomIcon } from '@vega/components/src/assets/images/bathroom.svg'
import { ReactComponent as GarageIcon } from '@vega/components/src/assets/images/car.svg'
import { ReactComponent as BuildingSizeIcon } from '@vega/components/src/assets/images/buildingSize.svg'
import { ReactComponent as LandSizeIcon } from '@vega/components/src/assets/images/landSize.svg'
import { SquareMeterValue } from '@vega/components'
import formatters from 'utils/formatters'
import { styled, s } from '@vega/styled'

const { formatMonetaryAmount, withEmptyPlaceholder } = formatters

const Container = styled.div(s('w-full'))

const Column = styled.div(s('flex flex-column'))
const Row = styled.div(s('flex items-center'))

const Subtitle = styled.h2(s('m-0 mb-6 font-normal text-2xl text-grey-800'))
const Label = styled.span(s('text-grey-600'))
const Value = styled.span(s('font-medium text-grey-800'))

const Price = styled.span(s('font-semibold text-xl text-grey-800'))
const EstimatedValue = styled(Price)(
  s('text-3xl ml-2', { transform: 'translateY(5px)' })
)

const SummaryBlock = styled.div(s('flex items-center mr-10'))
const iconStyle = { marginRight: 5 }

const ValuationBlock = styled.div(s('flex flex-column mr-16'))

const SeparateLine = styled.div(
  s('border-0 border-b-1 border-solid border-grey-200 w-full')
)

function PropertyInfo({ property }) {
  const { address, details = {}, valuation = {} } = property
  const {
    estimatedValue,
    lastValued,
    capitalValue,
    improvementValue,
    landValue,
  } = valuation
  const {
    numOfBedrooms,
    numOfBathrooms,
    numOfGarages,
    floorArea,
    landArea,
    propertyType,
    yearBuilt,
  } = details

  const formattedLastValuedDate = isNilOrEmpty(lastValued)
    ? '-'
    : moment(lastValued).format('DD MMMM YYYY')

  return (
    <Container>
      <Column style={s('pb-6')}>
        <Subtitle>{address}</Subtitle>
        <Row style={s('items-end')}>
          <Label>Estimated</Label>
          <EstimatedValue>{formatMonetaryAmount(estimatedValue)}</EstimatedValue>
        </Row>
      </Column>

      <SeparateLine />

      <Column style={s('py-6')}>
        <Subtitle>Property details</Subtitle>
        <Row style={s('mb-6')}>
          <SummaryBlock>
            <BedroomIcon style={iconStyle} />
            <Value>{withEmptyPlaceholder(numOfBedrooms)}</Value>
          </SummaryBlock>

          <SummaryBlock>
            <BathroomIcon style={iconStyle} />
            <Value>{withEmptyPlaceholder(numOfBathrooms)}</Value>
          </SummaryBlock>

          <SummaryBlock>
            <GarageIcon style={iconStyle} />
            <Value>{withEmptyPlaceholder(numOfGarages)}</Value>
          </SummaryBlock>

          <SummaryBlock>
            <BuildingSizeIcon style={iconStyle} />
            <Value>
              <SquareMeterValue value={withEmptyPlaceholder(floorArea)} />
            </Value>
          </SummaryBlock>

          <SummaryBlock>
            <LandSizeIcon style={iconStyle} />
            <Value>
              <SquareMeterValue value={withEmptyPlaceholder(landArea)} />
            </Value>
          </SummaryBlock>
        </Row>
        <Row>
          <Row style={s('mr-8')}>
            <Label style={s('mr-2')}>Property type: </Label>
            <Value>{withEmptyPlaceholder(propertyType)}</Value>
          </Row>
          <Row>
            <Label style={s('mr-2')}>Year built (approximate): </Label>
            <Value>{withEmptyPlaceholder(yearBuilt)}</Value>
          </Row>
        </Row>
      </Column>

      <SeparateLine />

      <Column style={s('py-6')}>
        <Subtitle>Value breakdown</Subtitle>
        <Row>
          <ValuationBlock>
            <Label style={s('mb-3')}>Capital value</Label>
            <Price>{formatMonetaryAmount(capitalValue)}</Price>
          </ValuationBlock>

          <ValuationBlock>
            <Label style={s('mb-3')}>Land value</Label>
            <Price>{formatMonetaryAmount(landValue)}</Price>
          </ValuationBlock>

          <ValuationBlock>
            <Label style={s('mb-3')}>Improvement value</Label>
            <Price>{formatMonetaryAmount(improvementValue)}</Price>
          </ValuationBlock>

          <ValuationBlock>
            <Label style={s('mb-3')}>Last valued</Label>
            <Price>{formattedLastValuedDate}</Price>
          </ValuationBlock>
        </Row>
      </Column>

      <SeparateLine />
    </Container>
  )
}

export { PropertyInfo }
