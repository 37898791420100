import { find, isNilOrEmpty, ifElse } from '@solta/ramda-extra'
import { financialInfo } from 'modules/application/initialFormValues'

export const mapToFuturePlanViewModel = (applicantId) => (circumstanceChanges) => {
  const circumstanceChangeOfApplicant = find(
    (c) => c?.applicantId === applicantId,
    circumstanceChanges
  )

  return ifElse(
    isNilOrEmpty,
    returnUnspecifiedFuturePlan,
    returnSpecifiedFuturePlan
  )(circumstanceChangeOfApplicant?.affordabilityChanges)
}

function returnUnspecifiedFuturePlan() {
  const initialFuturePlan = financialInfo?.futurePlan
  return initialFuturePlan
}

function returnSpecifiedFuturePlan(affordabilityChanges) {
  const emptyFuturePlan = { ...financialInfo?.futurePlan, hasAnticipatedChanges: false }
  const mappedFuturePlan = {
    hasAnticipatedChanges: true,
    anticipatedEvent: affordabilityChanges?.event,
    adaptationPlan: affordabilityChanges?.adaptationPlan,
  }

  const specifiedToHaveNoChanges = affordabilityChanges?.event === 'none'
  if (specifiedToHaveNoChanges) return emptyFuturePlan

  return mappedFuturePlan
}
