import React from 'react'
import { RadioButtonField, RadioButton, CheckboxField } from '@vega/components'
import { useFormikContext } from 'formik'
import {
  SectionTitle,
  Subtitle,
  Prompt as OriginalPrompt,
} from 'features/broker/applications/components/typography'
import { styled, s } from '@vega/styled'
import { Loans } from './Loans'
import { TEST_IDS } from '@neo/constants'
import { NotesProvider } from 'features/broker/applications/components/NotesProvider'

const FieldContainer = styled.div(s('mb-6'))
const Prompt = styled(OriginalPrompt)(s('mb-4'))

function PersonalLoan({ applicantName, parentPath, applicantPathId }) {
  const { values: formikValues } = useFormikContext()

  const hasPersonalLoans = Boolean(
    formikValues?.financials[applicantPathId]?.liabilities?.personalLoan?.isOwned
  )

  return (
    <>
      <SectionTitle style={s('mt-10 mb-5')}>
        Let&apos;s talk about other loans
      </SectionTitle>
      <Subtitle style={s('mb-6')}>
        We have a couple of questions about personal loans, which include both secured
        (e.g. car loan) and unsecured ones
      </Subtitle>
      <NotesProvider name={`${parentPath}.liabilities.personalLoan.isOwned`}>
        <FieldContainer>
          <Prompt>Does {applicantName} have any personal loans?</Prompt>
          <RadioButtonField
            name={`${parentPath}.liabilities.personalLoan.isOwned`}
            aria-label="personal loans"
            testId={TEST_IDS.HAS_PERSONAL_LOANS_RB}
          >
            <RadioButton value={true}>Yes</RadioButton>
            <RadioButton value={false}>No</RadioButton>
          </RadioButtonField>
        </FieldContainer>
      </NotesProvider>

      {hasPersonalLoans && (
        <>
          <Loans
            parentPath={parentPath}
            applicantPathId={applicantPathId}
            applicantName={applicantName}
          />
          <CheckboxField
            name={`${parentPath}.liabilities.personalLoan.confirmInfo`}
            size="md"
            aria-label="confirm info"
            containerProps={{ style: s('mt-8 mb-6') }}
            testId={`${TEST_IDS.CONFIRM_PERSONAL_LOAN_CHECKBOX}`}
          >
            I confirm the personal loan(s) details is correct
          </CheckboxField>
        </>
      )}
    </>
  )
}

export { PersonalLoan }
