import React from 'react'
import { Wizard } from '@vega/formik-wizard'
import { routes } from 'routes'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectGetApplicationStatus,
  selectFormState,
  selectNumOfApplicants,
} from 'modules/application/selectors'
import { useHideNavDrawer } from 'hooks/useHideNavDrawer'
import { replace } from '@solta/ramda-extra'
import { s } from '@vega/styled'
import View from './View'
import { useHistory, useParams } from 'react-router-dom'
import { getLoanApplication, submitApplication } from 'modules/application'
import { ErrorPage, Loading } from '@vega/components'
import { ASYNC_STATUSES } from '@neo/constants'
import { generateSteps, removeIdentifier } from './utils'

const { PENDING, REJECTED } = ASYNC_STATUSES

function CreateApplicationWizard() {
  const { id } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()

  useHideNavDrawer()

  React.useEffect(() => {
    dispatch(getLoanApplication(id))
  }, [dispatch, id])

  const getApplicationStatus = useSelector(selectGetApplicationStatus)

  const {
    invitations,
    numOfCoApplicants,
    details,
    financials,
    intent,
    structure,
    preApproval,
    status: { currentStepId },
  } = useSelector(selectFormState)

  const numOfApplicants = useSelector(selectNumOfApplicants)
  const orderedSteps = React.useMemo(() => generateSteps(numOfApplicants), [
    numOfApplicants,
  ])

  if (getApplicationStatus === REJECTED) return <ErrorPage />

  const goToApplicationStatusPreview = () =>
    history.replace(replace(':id', id, routes.broker.applications.statusPreview))

  const submitAndExit = () => {
    dispatch(submitApplication())
    goToApplicationStatusPreview()
  }

  // NOTE: cannot use the Wizard loader as we need to wait for server to send back numOfApplicants before rendering the form.
  if (getApplicationStatus === PENDING)
    return (
      <div style={s('w-full')}>
        <Loading style={s('m-a')} />
      </div>
    )

  return (
    <Wizard
      savedStepId={currentStepId}
      order={orderedSteps}
      formatStepInUrl={removeIdentifier}
      initialValues={{
        invitations,
        details,
        financials,
        intent,
        structure,
        preApproval,
        numOfCoApplicants,
      }}
      basePath={`${routes.broker.applications.create}/${id}`}
      formProps={{
        style: s('flex-1 flex flex-column', { overflow: 'hidden' }),
      }}
      onSubmit={submitAndExit}
      autoNext={false}
    >
      <View />
    </Wizard>
  )
}

export { CreateApplicationWizard }
