import {
  map,
  pipe,
  filter,
  ifElse,
  isTrue,
  reject,
  includes,
  mergeAll,
} from '@solta/ramda-extra'
import { belongTo, addMissingFields } from './helper'
import { financialInfo } from 'modules/application/initialFormValues'

export const mapToIncomeViewModel = (applicantId) => (incomeAccounts) => {
  return pipe(
    filter(belongTo(applicantId)),
    filterOutIncomeByAccountName('salary'),
    map(toIncomeViewModel),
    mergeAll,
    addMissingFields(financialInfo.income)
  )(incomeAccounts)
}

function filterOutIncomeByAccountName(ignoreIncomeAccount) {
  return (incomeAccounts) =>
    reject((account) => includes(account?.accountName, ignoreIncomeAccount))(
      incomeAccounts
    )
}

function toIncomeViewModel({ id, accountName, accountAmount, recurrence }) {
  return ifElse(
    isTrue,
    mapToUnpossessedIncome(id, accountName),
    mapToPossessedIncome(id, accountName, accountAmount, recurrence)
  )(accountAmount === 0)
}
function mapToPossessedIncome(id, name, accountAmount, recurrence) {
  return () => ({
    [name]: { id, eligibility: true, amount: accountAmount, recurrence },
  })
}
function mapToUnpossessedIncome(id, name) {
  return () => ({
    [name]: {
      id,
      eligibility: false,
      amount: undefined,
      recurrence: undefined,
    },
  })
}
