import React, { useEffect } from 'react'
import { Switch, Route, Redirect, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { styled, s } from '@vega/styled'
import { ASYNC_STATUSES } from '@neo/constants'
import { getLoanApplication, selectGetApplicationStatus } from 'modules/application'
import { ErrorPage, Loading } from '@vega/components'
import { Header } from './Header'
import { ApplicationDetails } from './ApplicationDetails'
import { PropertyWatchlist } from './PropertyWatchlist'
import { SubmittedProperties } from './SubmittedProperties'
import { LoanOutcome } from './LoanOutcome'
import { routes } from 'routes'

const { PENDING, REJECTED } = ASYNC_STATUSES

const Container = styled.div(s('w-full flex flex-column', { overflow: 'hidden' }))

function ApplicationOverviewRouter() {
  const { id } = useParams()
  const getApplicationStatus = useSelector(selectGetApplicationStatus)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getLoanApplication(id))
  }, [dispatch, id])

  if (getApplicationStatus === PENDING)
    return (
      <div style={s('w-full')}>
        <Loading style={s('m-a')} />
      </div>
    )
  if (getApplicationStatus === REJECTED) return <ErrorPage />

  return (
    <Container>
      <Header loanApplicationId={id} />
      <Switch>
        <Route path={routes.client.applications.overview.details}>
          <ApplicationDetails loanApplicationId={id} />
        </Route>

        <Route path={routes.client.applications.overview.propertySearch}>
          <PropertyWatchlist loanApplicationId={id} />
        </Route>

        <Route path={routes.client.applications.overview.submittedProperty}>
          <SubmittedProperties loanApplicationId={id} />
        </Route>

        <Route path={routes.client.applications.overview.loanOutcome}>
          <LoanOutcome loanApplicationId={id} />
        </Route>
        <Redirect to={routes.client.applications.list} />
      </Switch>
    </Container>
  )
}

export { ApplicationOverviewRouter }
