import React, { useEffect, useCallback } from 'react'
import { styled, s } from '@vega/styled'
import { useFormikContext } from 'formik'
import { useWizard, Wizard } from '@vega/formik-wizard'
import { useDispatch, useSelector } from 'react-redux'
import {
  resetForm,
  selectProgressStepStatus,
  updateCreditAssessorCheck,
} from 'modules/application'
import { useHistory } from 'react-router-dom'
import { routes } from 'routes'
import { FormProgress } from '../../components/FormProgress/FormProgress'
import { InfoSidebar } from './InfoSidebar'
import {
  Title,
  Subtitle,
  Prompt as PromptBase,
} from 'features/creditAssessor/applications/components/typography'
import { CreditAssessorCheckbox } from '../../components/CreditAssessorCheckbox'
import { RadioButtonField, RadioButton, TextField } from '@vega/components'
import { ExpectedRentAmount } from './ExpectedRentAmount'
import { BottomNavigation } from './BottomNavigation'
import { isNotNilOrEmpty } from '@solta/ramda-extra'
import { TEST_IDS } from '@neo/constants'
import * as CONSTANTS from '@vega/constants'
import { markProgressStepAsVisited } from 'features/creditAssessor/applications/components/FormProgress/utils'

const {
  FORM_PROGRESS: {
    STEP_NAMES: { GOALS },
  },
} = CONSTANTS

const Root = styled.div(s('flex-1 flex items-center w-full h-full relative'))
const ProgressContainer = styled.div(s('bg-white h-full'))

const Core = styled.div(s('flex flex-column w-full h-full'))
const Content = styled.div(
  s('flex-1 flex justify-center w-full pt-6', {
    paddingRight: 200,
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  })
)
const FormContainer = styled.div(s('flex flex-column pt-1'), {
  width: 652,
  '> :last-child': s('pb-12'),
})
const InfoContainer = styled.div(s('relative', { pointerEvents: 'none' }))
const NavigationContainer = styled.div(s('relative zIndex-2'))

const Prompt = styled(PromptBase)(s('mt-6 mb-4'))

const YourGoalsStep = ({ stepId }) => {
  const {
    values: { details, intent, creditAssessorCheck },
  } = useFormikContext()

  const applicantName = details?.applicant1?.firstName || 'your client'

  const { loanPurpose } = intent

  const dispatch = useDispatch()
  const history = useHistory()

  const saveAndContinue = async (values) => {
    dispatch(updateCreditAssessorCheck(values.creditAssessorCheck))
  }

  const goToApplicationList = () =>
    history.replace(routes.creditAssessor.applications.list)

  const saveAndExit = () => {
    dispatch(updateCreditAssessorCheck(creditAssessorCheck))
    dispatch(resetForm())
    goToApplicationList()
  }

  const { previous, currentStep } = useWizard()
  const goBack = () => previous()
  const isLoanPurposePicked = isNotNilOrEmpty(loanPurpose)

  const currentStepStatus = useSelector(selectProgressStepStatus(GOALS))
  useEffect(() => {
    if (currentStep?.id === stepId) markProgressStepAsVisited(GOALS, currentStepStatus)
  }, [currentStep, currentStepStatus, stepId])

  return (
    <Wizard.Step
      id={stepId}
      title="Your Goals"
      onSubmit={useCallback(saveAndContinue, [])}
    >
      <Root>
        <ProgressContainer>
          <FormProgress saveAndExit={saveAndExit} />
        </ProgressContainer>

        <Core>
          <Content>
            <FormContainer>
              <Title style={s('mb-4')}>
                Let&apos;s discuss {applicantName}&apos;s goals
              </Title>

              <Subtitle style={s('mb-8')}>
                We love to say &quot;your wish is our command&quot;, but we&apos;re not
                a genie. Tell us {applicantName}&apos;s goals by answering the following
                questions
              </Subtitle>

              <Prompt style={s('mt-0')}>What is {applicantName} looking to do?</Prompt>
              <RadioButtonField
                name="intent.loanPurpose"
                aria-label="loanPurpose"
                isReadOnly
                testId={TEST_IDS.LOAN_PURPOSE_RB}
              >
                <RadioButton value="purchase">Client is purchasing</RadioButton>
                <RadioButton value="refinance">Client is refinancing</RadioButton>
              </RadioButtonField>

              {isLoanPurposePicked && (
                <>
                  <Prompt>
                    Does {applicantName} live in this property, or is it an investment?
                  </Prompt>
                  <RadioButtonField
                    name="intent.propertyPurpose"
                    aria-label="propertyPurpose"
                    isReadOnly
                    testId={TEST_IDS.PROPERTY_PURPOSE_RB}
                  >
                    <RadioButton value="living">Live-in</RadioButton>
                    <RadioButton value="investment">Investment</RadioButton>
                  </RadioButtonField>

                  <Prompt style={s('mb-0')}>
                    How much does {applicantName} think this property is worth? (an
                    estimated value is acceptable)
                  </Prompt>
                  <TextField
                    name="intent.estimatedPropertyValue"
                    aria-label="estimatedPropertyValue"
                    containerStyle={s('w-1/2')}
                    prefix="$"
                    isReadOnly
                    testId={TEST_IDS.EST_PROPERTY_VALUE_INPUT}
                  />

                  <Prompt style={s('mb-0')}>
                    How much does {applicantName} want to borrow against this property?
                    This should cover their current loan, plus any additional funds
                    they&apos;d like to borrow
                  </Prompt>
                  <TextField
                    name="intent.borrowingAmount"
                    aria-label="borrowingAmount"
                    containerStyle={s('w-1/2')}
                    prefix="$"
                    isReadOnly
                    testId={TEST_IDS.BORROWING_AMOUNT_INPUT}
                  />

                  <ExpectedRentAmount applicantName={applicantName} />
                </>
              )}

              <CreditAssessorCheckbox
                name="creditAssessorCheck.goals.isVerified"
                ariaLabel="Confirm information"
                testId={TEST_IDS.ASSESSOR_APP_REVIEW_CHECKBOX}
              />
            </FormContainer>

            <InfoContainer>
              <InfoSidebar />
            </InfoContainer>
          </Content>

          <NavigationContainer>
            <BottomNavigation goBack={goBack} canNext />
          </NavigationContainer>
        </Core>
      </Root>
    </Wizard.Step>
  )
}

export { YourGoalsStep }
