import React, { useState } from 'react'
import { noop } from '@solta/ramda-extra'
import { Formik } from 'formik'
import { Modal as ModalBase } from '@vega/components'
import { SavedPropertyQuestions } from './SavedPropertyQuestions'
import { PropertySearch } from './PropertySearch'
import { PropertyDetails } from './PropertyDetails'
import { styled, s } from '@vega/styled'

const Container = styled.div(
  s('bg-green-50', { height: '100vh', width: '100vw', overflowY: 'auto' })
)

function SavePropertyModal({
  isOpen = noop,
  onClose = noop,
  loanApplicationId,
  property,
}) {
  const [currentPage, setCurrentPage] = useState('propertySearch')

  const onModalClose = () => {
    onClose()
    setCurrentPage('propertySearch')
  }

  return (
    <ModalBase isOpen={isOpen} onClose={onModalClose}>
      <Formik
        enableReinitialize
        initialValues={{
          search: '',
          hasMadeOffer: undefined,
          hasWonOffer: undefined,
          isIntendedToSubmit: undefined,
        }}
        onSubmit={noop}
      >
        <Container>
          {currentPage === 'propertySearch' && (
            <PropertySearch onClose={onModalClose} setCurrentPage={setCurrentPage} />
          )}
          {currentPage === 'propertyDetails' && (
            <PropertyDetails
              onClose={onModalClose}
              setCurrentPage={setCurrentPage}
              loanApplicationId={loanApplicationId}
            />
          )}
          {currentPage === 'savedPropertyQuestions' && (
            <SavedPropertyQuestions
              onClose={onModalClose}
              setCurrentPage={setCurrentPage}
              loanApplicationId={loanApplicationId}
              propertyId={property?.propertyId}
            />
          )}
        </Container>
      </Formik>
    </ModalBase>
  )
}

export { SavePropertyModal }
