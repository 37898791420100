import { map, concatAll, pipe, filter } from '@solta/ramda-extra'
import * as CONSTANTS from '@vega/constants'

const {
  FORM_PROGRESS: {
    STEP_STATUS: { COMPLETED },
  },
} = CONSTANTS

const stepNamesBySection = {
  details: ['clientDetails', 'clientIdentification'],
  financials: [
    'financialsFillMethodSelection',
    'creditReport',
    'incomeAndAssets',
    'expensesAndLiabilities',
  ],
}
export const checkIsSectionCompleted = (formProgress, section, numOfApplicants) => {
  const stepNames = stepNamesBySection[section]
  const numOfCompletedStatusesRequired = stepNames.length * numOfApplicants

  const noApplicantRegistered = numOfCompletedStatusesRequired === 0
  if (noApplicantRegistered) return false

  return pipe(
    getStatusesForEachStepNames(formProgress),
    getNumOfCompletedStatuses,
    isSectionCompleted(numOfCompletedStatusesRequired)
  )(stepNames)
}

function getStatusesForEachStepNames(progress) {
  return (stepNames) => {
    return pipe(
      map((stepName) => progress[stepName]),
      concatAll
    )(stepNames)
  }
}

function getNumOfCompletedStatuses(statuses) {
  const completedStatuses = filter((status) => status?.status === COMPLETED, statuses)
  return completedStatuses.length
}

function isSectionCompleted(numOfCompletedStatuses) {
  return (numOfCompletedStatusesRequired) => {
    return numOfCompletedStatuses === numOfCompletedStatusesRequired
  }
}
