import * as Validator from 'yup'
import * as CONSTANTS from '@vega/constants'

const {
  LOAN_APPLICATION: {
    OUTCOMES: { APPROVED, REJECTED },
  },
} = CONSTANTS

const { object, mixed, boolean, string } = Validator

const validationSchemaTemplate = object({
  decision: mixed()
    .oneOf([APPROVED, REJECTED])
    .required('Please make a decision for this applicant')
    .label('Decision'),
  idVerified: boolean().when('decision', {
    is: REJECTED,
    then: boolean().required('Please select an option').label('Id Verfied'),
    otherwise: boolean().optional().label('Id Verfied'),
  }),
  creditCheckVerified: boolean().when('decision', {
    is: REJECTED,
    then: boolean().required('Please select an option').label('Credit Check Verfied'),
    otherwise: boolean().optional().label('Credit Check Verfied'),
  }),
  financialsVerified: boolean().when('decision', {
    is: REJECTED,
    then: boolean().required('Please select an option').label('Financials Verfied'),
    otherwise: boolean().optional().label('Financials Verfied'),
  }),
  rejectionReason: string().when('decision', {
    is: REJECTED,
    then: string().required('Please select an option').label('Rejection Reason'),
    otherwise: string().optional().label('Rejection Reason'),
  }),
})

export const validationSchema = (applicantPathId) =>
  object({
    applicantApproval: object({ [applicantPathId]: validationSchemaTemplate }),
  })
