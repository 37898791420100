import React from 'react'
import { TextField, RadioButtonField, RadioButton } from '@vega/components'
import { Subtitle } from 'features/creditAssessor/applications/components/typography'
import { styled, s } from '@vega/styled'
import { TEST_IDS } from '@neo/constants'

import { ExpenseRow } from './ExpenseRow'

const Column = styled.div(s('flex flex-column mb-6'))
const Row = styled.div(s('flex items-end justify-between'))

function Personal({ totalPersonalExpenses, parentPath }) {
  return (
    <>
      <ExpenseRow
        type="Personal"
        value={totalPersonalExpenses}
        style={s('mt-2 mb-6')}
      />

      <Column>
        <Subtitle>Medical and health</Subtitle>
        <Row>
          <TextField
            name={`${parentPath}.expenses.personal.health.value`}
            prefix="$"
            containerStyle={s('w-2/5 mr-5')}
            testId={TEST_IDS.HEALTH_COST_INPUT}
            isReadOnly
          />
          <RadioButtonField
            name={`${parentPath}.expenses.personal.health.recurrence`}
            aria-label="Medical and health"
            containerStyle={s('w-full')}
            testId={TEST_IDS.HEALTH_PAY_FREQ_RB}
            isReadOnly
          >
            <RadioButton value="weekly">Weekly</RadioButton>
            <RadioButton value="fortnightly">Fortnightly</RadioButton>
            <RadioButton value="monthly">Monthly</RadioButton>
            <RadioButton value="quarterly">Quarterly</RadioButton>
          </RadioButtonField>
        </Row>
      </Column>

      <Column>
        <Subtitle>Clothing and personal care</Subtitle>
        <Row>
          <TextField
            name={`${parentPath}.expenses.personal.clothing.value`}
            prefix="$"
            containerStyle={s('w-2/5 mr-5')}
            testId={TEST_IDS.PERSONAL_CARE_COST_INPUT}
            isReadOnly
          />
          <RadioButtonField
            name={`${parentPath}.expenses.personal.clothing.recurrence`}
            aria-label="Clothing and personal care"
            containerStyle={s('w-full')}
            testId={TEST_IDS.PERSONAL_CARE_PAY_FREQ_RB}
            isReadOnly
          >
            <RadioButton value="weekly">Weekly</RadioButton>
            <RadioButton value="fortnightly">Fortnightly</RadioButton>
            <RadioButton value="monthly">Monthly</RadioButton>
            <RadioButton value="quarterly">Quarterly</RadioButton>
          </RadioButtonField>
        </Row>
      </Column>

      <Column>
        <Subtitle>Recreation and entertainment</Subtitle>
        <Row>
          <TextField
            name={`${parentPath}.expenses.personal.entertainment.value`}
            prefix="$"
            containerStyle={s('w-2/5 mr-5')}
            testId={TEST_IDS.RECREATIONAL_COST_INPUT}
            isReadOnly
          />
          <RadioButtonField
            name={`${parentPath}.expenses.personal.entertainment.recurrence`}
            aria-label="Recreation and entertainment"
            containerStyle={s('w-full')}
            testId={TEST_IDS.RECREATIONAL_PAY_FREQ_RB}
            isReadOnly
          >
            <RadioButton value="weekly">Weekly</RadioButton>
            <RadioButton value="fortnightly">Fortnightly</RadioButton>
            <RadioButton value="monthly">Monthly</RadioButton>
            <RadioButton value="quarterly">Quarterly</RadioButton>
          </RadioButtonField>
        </Row>
      </Column>

      <Column>
        <Subtitle>Childcare</Subtitle>
        <Row>
          <TextField
            name={`${parentPath}.expenses.personal.childcare.value`}
            prefix="$"
            containerStyle={s('w-2/5 mr-5')}
            testId={TEST_IDS.CHILDCARE_COST_INPUT}
            isReadOnly
          />
          <RadioButtonField
            name={`${parentPath}.expenses.personal.childcare.recurrence`}
            aria-label="Childcare"
            containerStyle={s('w-full')}
            testId={TEST_IDS.CHILDCARE_PAY_FREQ_RB}
            isReadOnly
          >
            <RadioButton value="weekly">Weekly</RadioButton>
            <RadioButton value="fortnightly">Fortnightly</RadioButton>
            <RadioButton value="monthly">Monthly</RadioButton>
            <RadioButton value="quarterly">Quarterly</RadioButton>
          </RadioButtonField>
        </Row>
      </Column>

      <Column>
        <Subtitle>Children&apos;s education</Subtitle>
        <Row>
          <TextField
            name={`${parentPath}.expenses.personal.childEducation.value`}
            prefix="$"
            containerStyle={s('w-2/5 mr-5')}
            testId={TEST_IDS.CHILD_EDUCATION_COST_INPUT}
            isReadOnly
          />
          <RadioButtonField
            name={`${parentPath}.expenses.personal.childEducation.recurrence`}
            aria-label="Children’s education"
            containerStyle={s('w-full')}
            testId={TEST_IDS.CHILD_EDUCATION_PAY_FREQ_RB}
            isReadOnly
          >
            <RadioButton value="weekly">Weekly</RadioButton>
            <RadioButton value="fortnightly">Fortnightly</RadioButton>
            <RadioButton value="monthly">Monthly</RadioButton>
            <RadioButton value="quarterly">Quarterly</RadioButton>
          </RadioButtonField>
        </Row>
      </Column>
    </>
  )
}

export { Personal }
