/* eslint-disable complexity */
import React, { useEffect, useState, useCallback } from 'react'
import { styled, s } from '@vega/styled'
import { useFormikContext } from 'formik'
import { useWizard, Wizard } from '@vega/formik-wizard'
import { passportRequired, driverLicenceRequired } from './validationSchema'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { routes } from 'routes'
import { path, prop, isTrue, all, isNotNilOrEmpty } from '@solta/ramda-extra'
import { selectUploadEntities } from '@vega/redux.upload'
import {
  resetForm,
  updateCreditAssessorCheck,
  selectProgressStepStatus,
  downloadApplicantDocuments,
} from 'modules/application'
import { FormProgress } from '../../components/FormProgress'
import { InfoSidebar } from './InfoSidebar'
import { BottomNavigation } from './BottomNavigation'
import { UploadFile, IdPreview } from '../../components/upload'
import { StartIcon } from './StartIcon'
import { CreditAssessorCheckbox } from '../../components/CreditAssessorCheckbox'
import {
  Title,
  Subtitle,
  Prompt as PromptBase,
} from 'features/creditAssessor/applications/components/typography'
import {
  Button as ButtonBase,
  TextField as TextFieldBase,
  DateField,
  CountryComboBoxField,
} from '@vega/components'
import { TEST_IDS } from '@neo/constants'
import * as CONSTANTS from '@vega/constants'
import { markProgressStepAsVisited } from 'features/creditAssessor/applications/components/FormProgress/utils'

const {
  FORM_PROGRESS: {
    STEP_NAMES: { CLIENT_IDENTIFICATION },
  },
} = CONSTANTS

const Root = styled.div(s('flex-1 flex items-center w-full h-full relative'))
const ProgressContainer = styled.div(s('bg-white h-full'))

const Core = styled.div(s('flex flex-column w-full h-full'))
const Content = styled.div(
  s('flex-1 flex justify-center w-full pt-6', {
    paddingRight: 200,
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  })
)
const FormContainer = styled.div(s('flex flex-column pt-1'), {
  width: 652,
  '> :last-child': s('pb-12'),
})
const InfoContainer = styled.div(s('relative', { pointerEvents: 'none' }))
const NavigationContainer = styled.div(s('relative zIndex-2'))

const TextField = styled(TextFieldBase)(s('mb-6'))
const Row = styled.div(s('flex justify-between'))
const Prompt = styled(PromptBase)(s('text-base mt-0 mb-4'))
const Button = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'hasError' && prop !== 'isComplete',
})(
  s('text-grey-800 border-grey p-4 h-3 mb-6'),
  ({ isComplete }) => isComplete && s('border-green-700'),
  ({ hasError }) => hasError && s('border-error-400')
)
const PaddingBox = styled.div(s('pb-16'))

const UploadIdStep = ({
  applicantName,
  documents,
  applicantId,
  applicantPathId,
  stepId,
}) => {
  const {
    isValid: isFormValid,
    dirty,
    values: { creditAssessorCheck },
  } = useFormikContext()
  const parentPath = `details.${applicantPathId}.documents`

  const dispatch = useDispatch()
  const history = useHistory()
  const uploadEntities = useSelector(selectUploadEntities)

  const [isPassportSelected, setIsPassportSelected] = useState(false)
  const [isDriverLicenceSelected, setIsDriverLicenceSelected] = useState(false)

  const passportFiles = path(['passport', 'files'], documents)
  const driverLicenceFiles = path(['driverLicence', 'files'], documents)

  const isPassportValid = passportRequired.isValidSync(prop('passport', documents))
  const isDriverLicenceValid = driverLicenceRequired.isValidSync(
    prop('driverLicence', documents)
  )

  const showPassportComplete = all(isTrue, [isPassportValid, !isPassportSelected])
  const showPassportHasError = all(isTrue, [
    !isFormValid,
    !isPassportValid,
    !isPassportSelected,
    dirty,
  ])

  const showDriverLicenceComplete = all(isTrue, [
    isDriverLicenceValid,
    !isDriverLicenceSelected,
  ])
  const showDriverLicenceHasError = all(isTrue, [
    !isFormValid,
    !isDriverLicenceValid,
    !isDriverLicenceSelected,
    dirty,
  ])

  const saveAndContinue = (values) => {
    dispatch(updateCreditAssessorCheck(values.creditAssessorCheck))
  }

  const goToApplicationList = () =>
    history.replace(routes.creditAssessor.applications.list)

  const saveAndExit = () => {
    dispatch(updateCreditAssessorCheck(creditAssessorCheck))
    dispatch(resetForm())
    goToApplicationList()
  }

  const { previous, currentStep } = useWizard()

  const goBack = () => previous()

  const currentStepStatus = useSelector(
    selectProgressStepStatus(CLIENT_IDENTIFICATION, applicantId)
  )

  useEffect(() => {
    if (currentStep?.id === stepId && isNotNilOrEmpty(applicantId))
      markProgressStepAsVisited(CLIENT_IDENTIFICATION, currentStepStatus, applicantId)

    dispatch(downloadApplicantDocuments(applicantId))
  }, [applicantId, currentStep, currentStepStatus, dispatch, stepId])

  return (
    <Wizard.Step
      id={stepId}
      title="Client Identification"
      onSubmit={useCallback(saveAndContinue, [applicantId, uploadEntities, documents])}
    >
      <Root>
        <ProgressContainer>
          <FormProgress saveAndExit={saveAndExit} />
        </ProgressContainer>

        <Core>
          <Content>
            <FormContainer>
              <Title style={s('mb-4')}>Verify {applicantName}&apos;s ID</Title>

              <Subtitle style={s('mb-8')}>
                Before we move on to the next step, we need to verify {applicantName}
                &apos;s details. Please provide at least one of the following forms of
                ID. Uploading more than one form of ID may help the success of their
                application
              </Subtitle>

              <Button
                type="button"
                isComplete={showPassportComplete}
                hasError={showPassportHasError}
                variant={isPassportSelected ? 'contained' : 'outlined'}
                StartIcon={
                  <StartIcon
                    isComplete={showPassportComplete}
                    hasError={showPassportHasError}
                  />
                }
                data-test-id={TEST_IDS.PASSPORT_BUTTON}
                onClick={() => setIsPassportSelected(!isPassportSelected)}
              >
                New Zealand / Overseas Passport
              </Button>

              {isPassportSelected && (
                <>
                  <TextField
                    name={`${parentPath}.passport.passportNumber`}
                    aria-label="passportNumber"
                    label="Passport Number"
                    placeholder="Passport Number"
                    isReadOnly
                    testId={TEST_IDS.PASSPORT_NUMBER_INPUT}
                  />

                  <Row>
                    <CountryComboBoxField
                      name={`${parentPath}.passport.countryOfOrigin`}
                      aria-label="passportCountryOrigin"
                      label="Country of Origin"
                      placeholder="Country of Origin"
                      style={s('mr-3 w-1/2', { height: 55 })}
                      labelStyles={{ style: s('mb-4') }}
                      readOnly
                      testId={TEST_IDS.PASSPORT_COUNTRY_INPUT}
                    />

                    <DateField
                      name={`${parentPath}.passport.expiryDate`}
                      aria-label="passportExpiryDate"
                      label="Expiry Date"
                      containerProps={{ style: s('ml-3 w-1/2') }}
                      placeholder="DD/MM/YYYY"
                      isReadOnly
                      testId={TEST_IDS.PASSPORT_EXPIRY_INPUT}
                    />
                  </Row>

                  <Prompt style={s('mt-6')}>Upload a digital copy</Prompt>

                  {passportFiles.map((file) => (
                    <>
                      <IdPreview image={file.presignedURL} />
                      <UploadFile
                        fileName={file.fileName}
                        fileURL={file.presignedURL}
                        isReadOnly
                      />
                    </>
                  ))}
                </>
              )}

              <Button
                type="button"
                isComplete={showDriverLicenceComplete}
                hasError={showDriverLicenceHasError}
                variant={isDriverLicenceSelected ? 'contained' : 'outlined'}
                StartIcon={
                  <StartIcon
                    isComplete={showDriverLicenceComplete}
                    hasError={showDriverLicenceHasError}
                  />
                }
                onClick={() => setIsDriverLicenceSelected(!isDriverLicenceSelected)}
                data-test-id={TEST_IDS.LICENCE_BUTTON}
              >
                New Zealand Driver Licence
              </Button>

              {isDriverLicenceSelected && (
                <>
                  <TextField
                    name={`${parentPath}.driverLicence.licenceNumber`}
                    aria-label="DriverLicenceNumber"
                    label="Driver Licence Number"
                    placeholder="Driver Licence Number"
                    isReadOnly
                    testId={TEST_IDS.LICENCE_NUMBER_INPUT}
                  />

                  <Row>
                    <TextField
                      name={`${parentPath}.driverLicence.class`}
                      aria-label="DriverLicenceClass"
                      label="Class"
                      style={s('mr-3 w-1/2')}
                      placeholder="Class"
                      isReadOnly
                      testId={TEST_IDS.LICENCE_CLASS_INPUT}
                    />

                    <DateField
                      name={`${parentPath}.driverLicence.expiryDate`}
                      aria-label="DriverLicenceExpiryDate"
                      label="Expiry Date"
                      containerProps={{ style: s('ml-3 w-1/2') }}
                      placeholder="DD/MM/YYYY"
                      labelProps={{ style: s('mb-1') }}
                      isReadOnly
                      testId={TEST_IDS.LICENCE_EXPIRY_INPUT}
                    />
                  </Row>

                  <Prompt>Upload a digital copy</Prompt>

                  {driverLicenceFiles.map((file) => (
                    <>
                      <IdPreview image={file.presignedURL} />
                      <UploadFile
                        fileName={file.fileName}
                        fileURL={file.presignedURL}
                        isReadOnly
                      />
                    </>
                  ))}
                </>
              )}

              <CreditAssessorCheckbox
                name={`creditAssessorCheck.clientIdentification.${applicantId}.isVerified`}
                ariaLabel="Confirm information"
                testId={TEST_IDS.ASSESSOR_APP_REVIEW_CHECKBOX}
              />

              <PaddingBox />
            </FormContainer>

            <InfoContainer>
              <InfoSidebar />
            </InfoContainer>
          </Content>

          <NavigationContainer>
            <BottomNavigation goBack={goBack} canNext />
          </NavigationContainer>
        </Core>
      </Root>
    </Wizard.Step>
  )
}

export { UploadIdStep }
