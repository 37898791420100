import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  isNotNilOrEmpty,
  isNilOrEmpty,
  values,
  pipe,
  toLower,
  head,
} from '@solta/ramda-extra'
import { LoaderWithLabel } from '@vega/components'
import moment from 'moment'
import {
  searchActivityLogs,
  selectSearchActivityLogsStatus,
  selectActivityLogs,
} from 'modules/activityLog'
import { styled, s } from '@vega/styled'
import { ASYNC_STATUSES } from '@neo/constants'
import * as CONSTANTS from '@vega/constants'

const { ACTIVITY_ACTION } = CONSTANTS
const { PENDING } = ASYNC_STATUSES
const activityActions = values(ACTIVITY_ACTION)

const Container = styled.div(
  s('bg-white border-solid border-1 border-grey-200 rounded-lg p-5', {
    height: 250,
    maxHeight: 600,
    overflowY: 'auto',
  })
)

const EmptyActivitiesContainer = styled.div(
  s('h-full w-full', { display: 'grid', placeItems: 'center' })
)

const ActivityItem = styled.div(
  s(
    'flex justify-start items-center py-2 border-solid border-0 border-b-1 border-grey-100'
  )
)
const Time = styled.span(s('block text-grey-700 mr-24 text-sm'))
const Description = styled.span(s('text-grey-700 font-semibold'))

const replaceVerbWithStyledHtml = (desc) => {
  let styledHtml
  const defaultHtml = `<span>${desc}</span>`

  for (let i = 0; i < activityActions.length; i += 1) {
    const currentAction = activityActions[i]
    const regEx = new RegExp(`${currentAction}`, 'g')

    const isMatched = isNotNilOrEmpty(desc.match(regEx))

    if (isMatched) {
      styledHtml = desc.replace(
        regEx,
        `<span style="color: ${
          s('text-grey-500').color
        }; font-weight: 500">${currentAction}</span>`
      )
      break
    }
  }

  const noStylingNeeded = isNilOrEmpty(styledHtml)
  return { __html: noStylingNeeded ? defaultHtml : styledHtml }
}

const styleDescription = (description) => pipe(replaceVerbWithStyledHtml)(description)

const styleTime = (createdAt) => {
  const formattedTime = moment(createdAt).format('hh:mma DD/MM/YYYY')

  const periodRegex = new RegExp('am|pm', 'gi')
  const matchedPeriod = head(formattedTime.match(periodRegex))
  const styledHtml = formattedTime.replace(
    periodRegex,
    `<span style="color: ${s('text-grey-500').color}">${toLower(matchedPeriod)}</span>`
  )

  return { __html: styledHtml }
}

function ActivitiesPanel({ loanApplicationId }) {
  const dispatch = useDispatch()

  const isSearchingActivityLogs =
    useSelector(selectSearchActivityLogsStatus) === PENDING
  const activityLogs = useSelector(selectActivityLogs)

  React.useEffect(() => {
    dispatch(searchActivityLogs({ refId: loanApplicationId }))
  }, [dispatch, loanApplicationId])

  if (isSearchingActivityLogs)
    return (
      <Container style={s('pt-16', { display: 'grid', placeItems: 'center' })}>
        <LoaderWithLabel labelStyle={s('mt-6 text-base')} label="Fetching activities" />
      </Container>
    )

  if (isNilOrEmpty(activityLogs))
    return (
      <Container>
        <EmptyActivitiesContainer>
          <h2 style={s('my-0 mb-3 text-green-700 font-normal text-2xl')}>
            No Activities yet
          </h2>
        </EmptyActivitiesContainer>
      </Container>
    )

  return (
    <Container>
      {activityLogs.map((item) => (
        <ActivityItem key={item.id}>
          <Time dangerouslySetInnerHTML={styleTime(item.createdAt)} />
          <Description dangerouslySetInnerHTML={styleDescription(item.description)} />
        </ActivityItem>
      ))}
    </Container>
  )
}

export { ActivitiesPanel }
