/* eslint-disable no-throw-literal */
import { isNilOrEmpty, isNotNilOrEmpty, map, all, pluck } from '@solta/ramda-extra'
import { loanApplicationService } from 'apiService'
import { createCustomError } from '@vega/error-standardizer'

// note only new client should be checked against this
// remember to gather to applicant index
const extractEmailFromInvitedApplicants = (newApplicants) =>
  pluck('email', newApplicants)

export const throwIfAnyNewApplicantsAlreadyExists = async (
  newApplicants,
  formIndexesOfNewApplicants
) => {
  if (isNilOrEmpty(newApplicants)) return

  const applicantsEmails = extractEmailFromInvitedApplicants(newApplicants)

  const applicantsSearchResult = await Promise.all(
    map((email) => loanApplicationService.getApplicant({ email }), applicantsEmails)
  )

  const noNewApplicantsAlreadyExist = all(isNilOrEmpty, applicantsSearchResult)
  if (noNewApplicantsAlreadyExist) return

  // build and throw error only if inviting new applicants that actually already exist
  let hasError = false
  const formIndexesOfInvalidInvitation = []
  applicantsSearchResult.forEach((applicantDetails, idx) => {
    const newApplicantEmailHasBeenTaken = isNotNilOrEmpty(applicantDetails)

    if (newApplicantEmailHasBeenTaken) {
      hasError = true
      formIndexesOfInvalidInvitation.push(formIndexesOfNewApplicants, idx)
    }
  })

  if (hasError)
    // throw the error to be handled by the thunk catch block
    throw createCustomError({
      type: 'applicantAlreadyExists',
      description: 'emailAlreadyInUse',
      formIndexesOfInvalidInvitation,
    })
}
