import React, { useCallback } from 'react'
import { Button } from '@vega/components'
import { styled, s } from '@vega/styled'
import CONSTANTS from '@solta/constants'
import { useField } from 'formik'
import { noop, values } from '@solta/ramda-extra'
import { useFileUpload } from 'hooks'

const Container = styled.div(
  s(
    'flex flex-column border-2 border-dashed border-green-600 h-10 py-10 justify-center items-center',
    {
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
    }
  ),
  ({ hasError }) => hasError && s('border-error-400')
)
const Text = styled.div(s('text-grey-500 mt-3'))
const buttonStyling = s('py-2 px-4')
const ErrorMessage = styled.div(s('mb-2 text-error-600 text-sm'))

const UploadInput = ({
  name,
  disabled,
  onRejected = noop,
  onUpload = noop,
  testId,
  acceptFileType = `${values(CONSTANTS.FILE_MIME_TYPES)}`,
}) => {
  const [, { error, touched }] = useField({ name })

  const { getRootProps, getInputProps } = useFileUpload({
    accept: acceptFileType,
    noKeyboard: true,
    disabled,
    onDropRejected: useCallback(() => onRejected(name), [name, onRejected]),
    onDropAccepted: useCallback((files) => onUpload(files), [onUpload]),
  })

  return (
    <div>
      {touched && error && <ErrorMessage>{error}</ErrorMessage>}

      <Container {...getRootProps()} hasError={Boolean(error) && touched}>
        <Button type="button" style={buttonStyling} data-test-id={testId}>
          Select a file
          <input {...getInputProps()} />
        </Button>
        <Text>or drag and drop files here (PDF, PNG, JPG)</Text>
      </Container>
    </div>
  )
}

export { UploadInput }
