import React from 'react'
import { FieldArray, useFormikContext } from 'formik'
import { TextField, RadioButtonField, RadioButton, SelectField } from '@vega/components'
import { Prompt as OriginalPrompt } from 'features/creditAssessor/applications/components/typography'
import { LIABILITY } from '../constants'
import { LOAN_TYPE_OPTS } from '../selectOptions'
import { styled, s } from '@vega/styled'
import { TEST_IDS } from '@neo/constants'

const FieldContainer = styled.div(s('mb-6'))
const Prompt = styled(OriginalPrompt)(s('mb-4'))
const LoanTitle = styled.h2(s('text-xl mb-4'))

function Loans({ parentPath, applicantPathId, applicantName }) {
  const { values: formikValues } = useFormikContext()

  const loans =
    formikValues?.financials?.[applicantPathId]?.liabilities?.personalLoan?.loans

  return (
    <FieldArray
      name={`${parentPath}.liabilities.personalLoan.loans`}
      render={() => (
        <div>
          {loans.map((loan, idx) => {
            const loanPath = `${parentPath}.liabilities.personalLoan.loans[${idx}]`

            return (
              <div key={idx} name={loan[idx]}>
                <LoanTitle>Loan {idx + 1}</LoanTitle>

                <FieldContainer>
                  <Prompt>What is the description for this loan?</Prompt>
                  <SelectField
                    name={`${loanPath}.type`}
                    aria-label="Personal Loan Type"
                    options={LOAN_TYPE_OPTS}
                    testId={`${TEST_IDS.PERSONAL_LOAN_DESC_DROPDOWN}${idx + 1}`}
                    menuId={`${TEST_IDS.PERSONAL_LOAN_DESC_DROPDOWN_MENU}${idx + 1}`}
                    readonly
                  />
                </FieldContainer>

                <FieldContainer>
                  <Prompt>
                    Did {applicantName} apply this loan alone or with their
                    co-applicant?
                  </Prompt>
                  <RadioButtonField
                    name={`${loanPath}.ownership`}
                    aria-label="personal loans"
                    testId={`${TEST_IDS.PERSONAL_LOAN_CO_APPLICANT_RB}${idx + 1}`}
                    isReadOnly
                  >
                    <RadioButton value={LIABILITY.OWNERSHIP.SELF}>
                      Apply alone
                    </RadioButton>
                    <RadioButton value={LIABILITY.OWNERSHIP.SHARED}>
                      With others
                    </RadioButton>
                  </RadioButtonField>
                </FieldContainer>

                <FieldContainer>
                  <Prompt style={s('mb-0')}>
                    What&apos;s the total outstanding balance of the personal loan?
                  </Prompt>
                  <TextField
                    name={`${loanPath}.outstandingBalance`}
                    aria-label="outstanding balance"
                    prefix="$"
                    containerStyle={s('w-1/2')}
                    testId={`${TEST_IDS.PERSONAL_LOAN_TOTAL_INPUT}${idx + 1}`}
                    isReadOnly
                  />
                </FieldContainer>

                <FieldContainer>
                  <Prompt style={s('mb-0')}>
                    How much are the repayments against the personal loan?
                  </Prompt>
                  <TextField
                    name={`${loanPath}.repayments`}
                    aria-label="Repayments of the loan"
                    prefix="$"
                    suffix="p.mth"
                    containerStyle={s('w-1/2')}
                    testId={`${TEST_IDS.PERSONAL_LOAN_REPAYMENT_INPUT}${idx + 1}`}
                    isReadOnly
                  />
                </FieldContainer>
              </div>
            )
          })}
        </div>
      )}
    />
  )
}

export { Loans }
