import React from 'react'
import { Wizard } from '@vega/formik-wizard'
import { routes } from 'routes'
import { useDispatch, useSelector } from 'react-redux'
import { replace } from '@solta/ramda-extra'
import {
  selectGetApplicationStatus,
  selectFormState,
  selectNumOfApplicants,
} from 'modules/application/selectors'
import { useHideNavDrawer } from 'hooks/useHideNavDrawer'

import { s } from '@vega/styled'

import View from './View'
import { useHistory, useParams } from 'react-router-dom'
import { getLoanApplication, updateCreditAssessorCheck } from 'modules/application'
import { ErrorPage, Loading } from '@vega/components'
import { ASYNC_STATUSES } from '@neo/constants'
import { generateSteps, removeIdentifier } from './utils'

const { PENDING, REJECTED } = ASYNC_STATUSES

function CreateApplicationWizard() {
  const { id } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()

  useHideNavDrawer()

  React.useEffect(() => {
    dispatch(getLoanApplication(id))
  }, [dispatch, id])

  const getApplicationStatus = useSelector(selectGetApplicationStatus)

  const {
    numOfCoApplicants,
    details,
    financials,
    creditAssessorCheck,
    intent,
    applicantApproval,
    structure,
    preApproval,
  } = useSelector(selectFormState)

  const numOfApplicants = useSelector(selectNumOfApplicants)
  const orderedSteps = React.useMemo(() => generateSteps(numOfApplicants), [
    numOfApplicants,
  ])

  if (getApplicationStatus === REJECTED) return <ErrorPage />

  const goToApplicationDetailsPage = (applicationId) => {
    const applicationDetailsPage = replace(
      ':id',
      applicationId,
      routes.creditAssessor.applications.overview.personalAndFinance
    )

    history.replace(applicationDetailsPage)
  }

  const submitAndExit = async (values) => {
    dispatch(updateCreditAssessorCheck(values.creditAssessorCheck))
    goToApplicationDetailsPage(id)
  }

  // NOTE: cannot use the Wizard loader as we need to wait for server to send back numOfApplicants before rendering the form.
  if (getApplicationStatus === PENDING)
    return (
      <div style={s('w-full')}>
        <Loading style={s('m-a')} />
      </div>
    )

  return (
    <Wizard
      savedStepId={orderedSteps[0]}
      order={orderedSteps}
      formatStepInUrl={removeIdentifier}
      initialValues={{
        details,
        financials,
        creditAssessorCheck,
        applicantApproval,
        intent,
        structure,
        preApproval,
        numOfCoApplicants,
      }}
      basePath={replace(':id', id, routes.creditAssessor.applications.review)}
      formProps={{
        style: s('flex-1 flex flex-column', { overflow: 'hidden' }),
      }}
      onSubmit={submitAndExit}
    >
      <View />
    </Wizard>
  )
}

export { CreateApplicationWizard }
