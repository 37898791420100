/* eslint-disable no-unused-expressions */
import React from 'react'
import { FieldArray, useFormikContext } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { isNil, isNotNil, range, isNilOrEmpty } from '@solta/ramda-extra'
import { styled, s } from '@vega/styled'
import { ReactComponent as PlusIcon } from '@vega/components/src/assets/images/plus.svg'
import {
  ApplicantSearchField,
  Button,
  CheckboxField,
  TextField,
} from '@vega/components'
import { invitationTemplate } from 'modules/application/initialFormValues'
import { setHasUnexpectedError, selectHasUnexpectedError } from 'modules/application'
import { SectionTitle as SectionTitleBase } from '../../components/typography'
import { TEST_IDS } from '@neo/constants'
import { UnexpectedError, ErrorSummary } from 'features/common/applications'

const NOTES = {
  NEW:
    'This is a new applicant. We will send an invite email to this applicant to create account and complete their part on this application.',
  RETURNING:
    'This applicant is a returning applicant. We will send a notice to this applicant to complete their part on this application.  ',
}

const SectionTitle = styled(SectionTitleBase)(s('text-xl'))
const FieldContainer = styled.div(s('mb-6'))
const Note = styled.p(
  s('text-grey-600 text-sm italic font-normal', { wordSpacing: 1.5 })
)
const AddMorePrompt = styled.h2(s('text-xl mb-4'))

const BaseButton = styled(Button)(
  s('text-base bg-transparent border-b-1 border-solid rounded-none p-0 mt-4')
)
const DeleteApplicant = styled(BaseButton)(s('text-grey-800 border-grey-800'))
const AddApplicant = styled(BaseButton)(s('text-primary border-primary'))

export const RegistrationForm = () => {
  const dispatch = useDispatch()
  const hasUnexpectedError = useSelector(selectHasUnexpectedError)
  const { values, errors, submitCount, isValid } = useFormikContext()

  const parentPath = `invitations`
  const { invitations } = values

  const formHasBeenSubmitted = submitCount > 0

  const renderNote = (isNew) => {
    if (isNil(isNew)) return null
    return isNew ? NOTES.NEW : NOTES.RETURNING
  }

  return (
    <>
      <FieldArray
        name={parentPath}
        render={(arrayHelpers) => {
          return (
            <div>
              {invitations.map((invite, idx) => {
                return (
                  <div key={idx} name={invite[idx]}>
                    <SectionTitle>Applicant {idx + 1}</SectionTitle>
                    <CheckboxField
                      name={`${parentPath}.${idx}.isPrimary`}
                      id={`${parentPath}.${idx}.isPrimary`}
                      size="sm"
                      aria-label="is primary applicant"
                      style={s('mt-4')}
                      containerProps={{ style: s('mb-4') }}
                      testId={`${TEST_IDS.PRIMARY_APPLICANT_CHECKBOX}${idx + 1}`}
                    >
                      This is a primary applicant
                    </CheckboxField>
                    <FieldContainer>
                      <ApplicantSearchField
                        parentPath={`invitations.${idx}`}
                        aria-label="first name"
                        label="Their first name"
                        placeholder="First name"
                        selectedApplicants={invitations}
                        testId={`${TEST_IDS.CLIENT_SEARCH_INPUT}${idx + 1}`}
                        menuId={`${TEST_IDS.CLIENT_SEARCH_MENU}${idx + 1}`}
                      />
                    </FieldContainer>
                    <FieldContainer>
                      <TextField
                        name={`${parentPath}.${idx}.email`}
                        aria-label="email"
                        label="Their email address"
                        placeholder="e.g. example@example.com"
                        isReadOnly={isNotNil(invite.isNew) && !invite.isNew}
                        testId={`${TEST_IDS.CLIENT_EMAIL_INPUT}${idx + 1}`}
                        id={`${parentPath}.${idx}.email`}
                      />
                    </FieldContainer>
                    <FieldContainer>
                      <Note>{renderNote(invite.isNew)}</Note>
                    </FieldContainer>
                    {idx !== 0 && (
                      <DeleteApplicant
                        type="button"
                        onClick={() => arrayHelpers.remove(idx)}
                        style={s('my-6')}
                        data-test-id={`${TEST_IDS.DELETE_APPLICANT_BTN}${idx + 1}`}
                      >
                        Delete co-applicant
                      </DeleteApplicant>
                    )}
                  </div>
                )
              })}
              <AddMorePrompt>Have another co-applicant?</AddMorePrompt>
              <AddApplicant
                StartIcon={<PlusIcon width={13} />}
                type="button"
                onClick={() => arrayHelpers.push(invitationTemplate)}
                style={s('my-6')}
              >
                <span
                  style={s('mt-1 ml-1')}
                  data-test-id={TEST_IDS.ADD_CO_APPLICANT_BTN}
                >
                  Add co-applicant
                </span>
              </AddApplicant>

              <ErrorSummary
                visible={formHasBeenSubmitted && !isValid}
                errors={errors}
                whitelistErrorPaths={range(0, values.invitations.length).map(
                  (idx) => `invitations.${idx}.isPrimary`
                )}
              />
              <UnexpectedError
                visible={isNilOrEmpty(errors) && hasUnexpectedError}
                hideError={() => dispatch(setHasUnexpectedError(false))}
              />
            </div>
          )
        }}
      />
    </>
  )
}
